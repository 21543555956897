@charset "UTF-8";
/**
 * @license
 * MyFonts Webfont Build ID 3806712, 2019-09-12T05:04:23-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Bely-Bold by TypeTogether
 * URL: https://www.myfonts.com/fonts/type-together/bely/bold/
 *
 * Webfont: Bely-Italic by TypeTogether
 * URL: https://www.myfonts.com/fonts/type-together/bely/italic/
 *
 * Webfont: Bely-Regular by TypeTogether
 * URL: https://www.myfonts.com/fonts/type-together/bely/regular/
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3806712
 * Licensed pageviews: 500,000
 * Webfonts copyright: Copyright &#x00A9; 2015 by Roxane Gataud. All rights reserved.
 *
 * © 2019 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3a15f8");
@font-face {
  font-family: "Bely-Bold";
  src: url("../fonts/romanusselling/3A15F8_0_0.eot");
  src: url("../fonts/romanusselling/3A15F8_0_0.eot?#iefix") format("embedded-opentype"), url("../fonts/romanusselling/3A15F8_0_0.woff2") format("woff2"), url("../fonts/romanusselling/3A15F8_0_0.woff") format("woff"), url("../fonts/romanusselling/3A15F8_0_0.ttf") format("truetype");
}
@font-face {
  font-family: "Bely-Italic";
  src: url("../fonts/romanusselling/3A15F8_1_0.eot");
  src: url("../fonts/romanusselling/3A15F8_1_0.eot?#iefix") format("embedded-opentype"), url("../fonts/romanusselling/3A15F8_1_0.woff2") format("woff2"), url("../fonts/romanusselling/3A15F8_1_0.woff") format("woff"), url("../fonts/romanusselling/3A15F8_1_0.ttf") format("truetype");
}
@font-face {
  font-family: "Bely-Regular";
  src: url("../fonts/romanusselling/3A15F8_2_0.eot");
  src: url("../fonts/romanusselling/3A15F8_2_0.eot?#iefix") format("embedded-opentype"), url("../fonts/romanusselling/3A15F8_2_0.woff2") format("woff2"), url("../fonts/romanusselling/3A15F8_2_0.woff") format("woff"), url("../fonts/romanusselling/3A15F8_2_0.ttf") format("truetype");
}
@font-face {
  font-family: "Conv_Aileron-SemiBold";
  src: url("../fonts/Aileron-SemiBold.eot");
  src: local("☺"), url("../fonts/Aileron-SemiBold.woff") format("woff"), url("../fonts/Aileron-SemiBold.ttf") format("truetype"), url("../fonts/Aileron-SemiBold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Conv_Aileron-HeavyItalic";
  src: url("../fonts/Aileron-HeavyItalic.eot");
  src: local("☺"), url("../fonts/Aileron-HeavyItalic.woff") format("woff"), url("../fonts/Aileron-HeavyItalic.ttf") format("truetype"), url("../fonts/Aileron-HeavyItalic.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Conv_Aileron-Heavy";
  src: url("../fonts/Aileron-Heavy.eot");
  src: local("☺"), url("../fonts/Aileron-Heavy.woff") format("woff"), url("../fonts/Aileron-Heavy.ttf") format("truetype"), url("../fonts/Aileron-Heavy.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Conv_Aileron-SemiBoldItalic";
  src: url("../fonts/Aileron-SemiBoldItalic.eot");
  src: local("☺"), url("../fonts/Aileron-SemiBoldItalic.woff") format("woff"), url("../fonts/Aileron-SemiBoldItalic.ttf") format("truetype"), url("../fonts/Aileron-SemiBoldItalic.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Conv_Aileron-Regular";
  src: url("../fonts/Aileron-Regular.eot");
  src: local("☺"), url("../fonts/Aileron-Regular.woff") format("woff"), url("../fonts/Aileron-Regular.ttf") format("truetype"), url("../fonts/Aileron-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Conv_Lora-Regular";
  src: url("../fonts/Lora-Regular.eot");
  src: local("☺"), url("../fonts/Lora-Regular.woff") format("woff"), url("../fonts/Lora-Regular.ttf") format("truetype"), url("../fonts/Lora-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Suisse Works Book";
  src: url("../fonts/SuisseWorks-Book.woff2") format("woff2"), url("../fonts/SuisseWorks-Book.woff") format("woff"), url("../fonts/SuisseWorks-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Suisse Works";
  src: url("../fonts/SuisseWorks-Bold.woff2") format("woff2"), url("../fonts/SuisseWorks-Bold.woff") format("woff"), url("../fonts/SuisseWorks-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Suisse Works";
  src: url("../fonts/SuisseWorks-MediumItalic.woff2") format("woff2"), url("../fonts/SuisseWorks-MediumItalic.woff") format("woff"), url("../fonts/SuisseWorks-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Suisse Works";
  src: url("../fonts/SuisseWorks.woff2") format("woff2"), url("../fonts/SuisseWorks.woff") format("woff"), url("../fonts/SuisseWorks.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Suisse Works";
  src: url("../fonts/SuisseWorks-Medium.woff2") format("woff2"), url("../fonts/SuisseWorks-Medium.woff") format("woff"), url("../fonts/SuisseWorks-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Suisse Works";
  src: url("../fonts/SuisseWorks-BoldItalic.woff2") format("woff2"), url("../fonts/SuisseWorks-BoldItalic.woff") format("woff"), url("../fonts/SuisseWorks-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Suisse Works Book";
  src: url("../fonts/SuisseWorks-BookItalic.woff2") format("woff2"), url("../fonts/SuisseWorks-BookItalic.woff") format("woff"), url("../fonts/SuisseWorks-BookItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Suisse Works";
  src: url("../fonts/SuisseWorks-Italic.woff2") format("woff2"), url("../fonts/SuisseWorks-Italic.woff") format("woff"), url("../fonts/SuisseWorks-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "ABC Monument Grotesk";
  src: url("../fonts/ABCMonumentGrotesk-HeavyItalic.woff2") format("woff2"), url("../fonts/ABCMonumentGrotesk-HeavyItalic.woff") format("woff"), url("../fonts/ABCMonumentGrotesk-HeavyItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "ABC Monument Grotesk";
  src: url("../fonts/ABCMonumentGrotesk-LightItalic.woff2") format("woff2"), url("../fonts/ABCMonumentGrotesk-LightItalic.woff") format("woff"), url("../fonts/ABCMonumentGrotesk-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "ABC Monument Grotesk Ultra";
  src: url("../fonts/ABCMonumentGrotesk-Ultra.woff2") format("woff2"), url("../fonts/ABCMonumentGrotesk-Ultra.woff") format("woff"), url("../fonts/ABCMonumentGrotesk-Ultra.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "ABC Monument Grotesk";
  src: url("../fonts/ABCMonumentGrotesk-Black.woff2") format("woff2"), url("../fonts/ABCMonumentGrotesk-Black.woff") format("woff"), url("../fonts/ABCMonumentGrotesk-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "ABC Monument Grotesk";
  src: url("../fonts/ABCMonumentGrotesk-BlackItalic.woff2") format("woff2"), url("../fonts/ABCMonumentGrotesk-BlackItalic.woff") format("woff"), url("../fonts/ABCMonumentGrotesk-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "ABC Monument Grotesk";
  src: url("../fonts/ABCMonumentGrotesk-ThinItalic.woff2") format("woff2"), url("../fonts/ABCMonumentGrotesk-ThinItalic.woff") format("woff"), url("../fonts/ABCMonumentGrotesk-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "ABC Monument Grotesk";
  src: url("../fonts/ABCMonumentGrotesk-Regular.woff2") format("woff2"), url("../fonts/ABCMonumentGrotesk-Regular.woff") format("woff"), url("../fonts/ABCMonumentGrotesk-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "ABC Monument Grotesk Ultra";
  src: url("../fonts/ABCMonumentGrotesk-UltraItalic.woff2") format("woff2"), url("../fonts/ABCMonumentGrotesk-UltraItalic.woff") format("woff"), url("../fonts/ABCMonumentGrotesk-UltraItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "ABC Monument Grotesk";
  src: url("../fonts/ABCMonumentGrotesk-MediumItalic.woff2") format("woff2"), url("../fonts/ABCMonumentGrotesk-MediumItalic.woff") format("woff"), url("../fonts/ABCMonumentGrotesk-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "ABC Monument Grotesk";
  src: url("../fonts/ABCMonumentGrotesk-Light.woff2") format("woff2"), url("../fonts/ABCMonumentGrotesk-Light.woff") format("woff"), url("../fonts/ABCMonumentGrotesk-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "ABC Monument Grotesk";
  src: url("../fonts/ABCMonumentGrotesk-BoldItalic.woff2") format("woff2"), url("../fonts/ABCMonumentGrotesk-BoldItalic.woff") format("woff"), url("../fonts/ABCMonumentGrotesk-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "ABC Monument Grotesk";
  src: url("../fonts/ABCMonumentGrotesk-RegularItalic.woff2") format("woff2"), url("../fonts/ABCMonumentGrotesk-RegularItalic.woff") format("woff"), url("../fonts/ABCMonumentGrotesk-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "ABC Monument Grotesk";
  src: url("../fonts/ABCMonumentGrotesk-Heavy.woff2") format("woff2"), url("../fonts/ABCMonumentGrotesk-Heavy.woff") format("woff"), url("../fonts/ABCMonumentGrotesk-Heavy.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "ABC Monument Grotesk";
  src: url("../fonts/ABCMonumentGrotesk-Medium.woff2") format("woff2"), url("../fonts/ABCMonumentGrotesk-Medium.woff") format("woff"), url("../fonts/ABCMonumentGrotesk-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "ABC Monument Grotesk";
  src: url("../fonts/ABCMonumentGrotesk-Thin.woff2") format("woff2"), url("../fonts/ABCMonumentGrotesk-Thin.woff") format("woff"), url("../fonts/ABCMonumentGrotesk-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "ABC Monument Grotesk";
  src: url("../fonts/ABCMonumentGrotesk-Bold.woff2") format("woff2"), url("../fonts/ABCMonumentGrotesk-Bold.woff") format("woff"), url("../fonts/ABCMonumentGrotesk-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
}
body * {
  box-sizing: border-box;
}

a:focus {
  outline: 0;
}

a:active,
a:hover {
  outline: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

mark {
  background: #ff0;
  color: #000;
}

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

pre {
  white-space: pre-wrap;
}

q {
  quotes: "“" "”" "‘" "’";
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
  vertical-align: top;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}

button,
input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}

ul {
  margin: 0;
  padding: 0;
}

/**
 * May this be a helping hand in the land of media queries.
 * Nothing is a waste of time if you use the experience wisely.
 */
/**
 * Retina
 */
.wrapper:after,
.row:after {
  content: "";
  display: table;
  clear: both;
}

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.col-center {
  margin: 0 auto;
  float: none;
}

.text-centered {
  text-align: center;
}

.align-center {
  text-align: center;
}

.col-right {
  float: right !important;
  box-sizing: border-box;
}

.col-center {
  display: block;
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.col-1 {
  width: calc(100% * 1/12 - (40px - 40px * 1/12));
}
.col-1:nth-child(n) {
  margin-right: 40px;
}
.col-1:last-child {
  margin-right: 0;
}
.col-1:nth-child(12n) {
  margin-right: 0 !important;
}

.col-2 {
  width: calc(100% * 2/12 - (40px - 40px * 2/12));
}
.col-2:nth-child(n) {
  margin-right: 40px;
}
.col-2:last-child {
  margin-right: 0;
}
.col-2:nth-child(6n) {
  margin-right: 0 !important;
}

.col-3 {
  width: calc(100% * 3/12 - (40px - 40px * 3/12));
}
.col-3:nth-child(n) {
  margin-right: 40px;
}
.col-3:last-child {
  margin-right: 0;
}
.col-3:nth-child(4n) {
  margin-right: 0 !important;
}

.col-4 {
  width: calc(100% * 4/12 - (40px - 40px * 4/12));
}
.col-4:nth-child(n) {
  margin-right: 40px;
}
.col-4:last-child {
  margin-right: 0;
}
.col-4:nth-child(3n) {
  margin-right: 0 !important;
}

.col-5 {
  width: calc(100% * 5/12 - (40px - 40px * 5/12));
}
.col-5:nth-child(n) {
  margin-right: 40px;
}
.col-5:last-child {
  margin-right: 0;
}
.col-5:nth-child(2n) {
  margin-right: 0 !important;
}

.col-6 {
  width: calc(100% * 6/12 - (40px - 40px * 6/12));
}
.col-6:nth-child(n) {
  margin-right: 40px;
}
.col-6:last-child {
  margin-right: 0;
}
.col-6:nth-child(2n) {
  margin-right: 0 !important;
}

.col-7 {
  width: calc(100% * 7/12 - (40px - 40px * 7/12));
}
.col-7:nth-child(n) {
  margin-right: 40px;
}
.col-7:last-child {
  margin-right: 0;
}
.col-7:nth-child(2n) {
  margin-right: 0 !important;
}

.col-8 {
  width: calc(100% * 8/12 - (40px - 40px * 8/12));
}
.col-8:nth-child(n) {
  margin-right: 40px;
}
.col-8:last-child {
  margin-right: 0;
}
.col-8:nth-child(2n) {
  margin-right: 0 !important;
}

.col-9 {
  width: calc(100% * 9/12 - (40px - 40px * 9/12));
}
.col-9:nth-child(n) {
  margin-right: 40px;
}
.col-9:last-child {
  margin-right: 0;
}
.col-9:nth-child(1n) {
  margin-right: 0 !important;
}

.col-10 {
  width: calc(100% * 10/12 - (40px - 40px * 10/12));
}
.col-10:nth-child(n) {
  margin-right: 40px;
}
.col-10:last-child {
  margin-right: 0;
}
.col-10:nth-child(1n) {
  margin-right: 0 !important;
}

.col-11 {
  width: calc(100% * 11/12 - (40px - 40px * 11/12));
}
.col-11:nth-child(n) {
  margin-right: 40px;
}
.col-11:last-child {
  margin-right: 0;
}
.col-11:nth-child(1n) {
  margin-right: 0 !important;
}

.col-12 {
  width: calc(100% * 12/12 - (40px - 40px * 12/12));
}
.col-12:nth-child(n) {
  margin-right: 40px;
}
.col-12:last-child {
  margin-right: 0;
}
.col-12:nth-child(1n) {
  margin-right: 0 !important;
}

@media only screen and (max-width: 800px) {
  .m-col-1 {
    width: calc(100% * 1/12 - (20px - 20px * 1/12));
  }
  .m-col-1:nth-child(n) {
    margin-right: 20px;
  }
  .m-col-1:last-child {
    margin-right: 0;
  }
  .m-col-1:nth-child(12n) {
    margin-right: 0 !important;
  }
  .m-col-2 {
    width: calc(100% * 2/12 - (20px - 20px * 2/12));
  }
  .m-col-2:nth-child(n) {
    margin-right: 20px;
  }
  .m-col-2:last-child {
    margin-right: 0;
  }
  .m-col-2:nth-child(6n) {
    margin-right: 0 !important;
  }
  .m-col-3 {
    width: calc(100% * 3/12 - (20px - 20px * 3/12));
  }
  .m-col-3:nth-child(n) {
    margin-right: 20px;
  }
  .m-col-3:last-child {
    margin-right: 0;
  }
  .m-col-3:nth-child(4n) {
    margin-right: 0 !important;
  }
  .m-col-4 {
    width: calc(100% * 4/12 - (20px - 20px * 4/12));
  }
  .m-col-4:nth-child(n) {
    margin-right: 20px;
  }
  .m-col-4:last-child {
    margin-right: 0;
  }
  .m-col-4:nth-child(3n) {
    margin-right: 0 !important;
  }
  .m-col-5 {
    width: calc(100% * 5/12 - (20px - 20px * 5/12));
  }
  .m-col-5:nth-child(n) {
    margin-right: 20px;
  }
  .m-col-5:last-child {
    margin-right: 0;
  }
  .m-col-5:nth-child(2n) {
    margin-right: 0 !important;
  }
  .m-col-6 {
    width: calc(100% * 6/12 - (20px - 20px * 6/12));
  }
  .m-col-6:nth-child(n) {
    margin-right: 20px;
  }
  .m-col-6:last-child {
    margin-right: 0;
  }
  .m-col-6:nth-child(2n) {
    margin-right: 0 !important;
  }
  .m-col-7 {
    width: calc(100% * 7/12 - (20px - 20px * 7/12));
  }
  .m-col-7:nth-child(n) {
    margin-right: 20px;
  }
  .m-col-7:last-child {
    margin-right: 0;
  }
  .m-col-7:nth-child(2n) {
    margin-right: 0 !important;
  }
  .m-col-8 {
    width: calc(100% * 8/12 - (20px - 20px * 8/12));
  }
  .m-col-8:nth-child(n) {
    margin-right: 20px;
  }
  .m-col-8:last-child {
    margin-right: 0;
  }
  .m-col-8:nth-child(2n) {
    margin-right: 0 !important;
  }
  .m-col-9 {
    width: calc(100% * 9/12 - (20px - 20px * 9/12));
  }
  .m-col-9:nth-child(n) {
    margin-right: 20px;
  }
  .m-col-9:last-child {
    margin-right: 0;
  }
  .m-col-9:nth-child(1n) {
    margin-right: 0 !important;
  }
  .m-col-10 {
    width: calc(100% * 10/12 - (20px - 20px * 10/12));
  }
  .m-col-10:nth-child(n) {
    margin-right: 20px;
  }
  .m-col-10:last-child {
    margin-right: 0;
  }
  .m-col-10:nth-child(1n) {
    margin-right: 0 !important;
  }
  .m-col-11 {
    width: calc(100% * 11/12 - (20px - 20px * 11/12));
  }
  .m-col-11:nth-child(n) {
    margin-right: 20px;
  }
  .m-col-11:last-child {
    margin-right: 0;
  }
  .m-col-11:nth-child(1n) {
    margin-right: 0 !important;
  }
  .m-col-12 {
    width: calc(100% * 12/12 - (40px - 40px * 12/12));
  }
  .m-col-12:nth-child(n) {
    margin-right: 40px;
  }
  .m-col-12:last-child {
    margin-right: 0;
  }
  .m-col-12:nth-child(1n) {
    margin-right: 0 !important;
  }
}
@media only screen and (max-width: 1050px) {
  .l-col-1 {
    width: calc(100% * 1/12 - (40px - 40px * 1/12));
  }
  .l-col-1:nth-child(n) {
    margin-right: 40px;
  }
  .l-col-1:last-child {
    margin-right: 0;
  }
  .l-col-1:nth-child(12n) {
    margin-right: 0 !important;
  }
  .l-col-1.no--gutter {
    width: calc(100% * 1/12 - (0px - 0px * 1/12));
  }
  .l-col-1.no--gutter:nth-child(n) {
    margin-right: 0px;
  }
  .l-col-1.no--gutter:last-child {
    margin-right: 0;
  }
  .l-col-1.no--gutter:nth-child(12n) {
    margin-right: 0 !important;
  }
  .l-col-2 {
    width: calc(100% * 2/12 - (40px - 40px * 2/12));
  }
  .l-col-2:nth-child(n) {
    margin-right: 40px;
  }
  .l-col-2:last-child {
    margin-right: 0;
  }
  .l-col-2:nth-child(6n) {
    margin-right: 0 !important;
  }
  .l-col-2.no--gutter {
    width: calc(100% * 2/12 - (0px - 0px * 2/12));
  }
  .l-col-2.no--gutter:nth-child(n) {
    margin-right: 0px;
  }
  .l-col-2.no--gutter:last-child {
    margin-right: 0;
  }
  .l-col-2.no--gutter:nth-child(6n) {
    margin-right: 0 !important;
  }
  .l-col-3 {
    width: calc(100% * 3/12 - (40px - 40px * 3/12));
  }
  .l-col-3:nth-child(n) {
    margin-right: 40px;
  }
  .l-col-3:last-child {
    margin-right: 0;
  }
  .l-col-3:nth-child(4n) {
    margin-right: 0 !important;
  }
  .l-col-3.no--gutter {
    width: calc(100% * 3/12 - (0px - 0px * 3/12));
  }
  .l-col-3.no--gutter:nth-child(n) {
    margin-right: 0px;
  }
  .l-col-3.no--gutter:last-child {
    margin-right: 0;
  }
  .l-col-3.no--gutter:nth-child(4n) {
    margin-right: 0 !important;
  }
  .l-col-4 {
    width: calc(100% * 4/12 - (40px - 40px * 4/12));
  }
  .l-col-4:nth-child(n) {
    margin-right: 40px;
  }
  .l-col-4:last-child {
    margin-right: 0;
  }
  .l-col-4:nth-child(3n) {
    margin-right: 0 !important;
  }
  .l-col-4.no--gutter {
    width: calc(100% * 4/12 - (0px - 0px * 4/12));
  }
  .l-col-4.no--gutter:nth-child(n) {
    margin-right: 0px;
  }
  .l-col-4.no--gutter:last-child {
    margin-right: 0;
  }
  .l-col-4.no--gutter:nth-child(3n) {
    margin-right: 0 !important;
  }
  .l-col-5 {
    width: calc(100% * 5/12 - (40px - 40px * 5/12));
  }
  .l-col-5:nth-child(n) {
    margin-right: 40px;
  }
  .l-col-5:last-child {
    margin-right: 0;
  }
  .l-col-5:nth-child(2n) {
    margin-right: 0 !important;
  }
  .l-col-5.no--gutter {
    width: calc(100% * 5/12 - (0px - 0px * 5/12));
  }
  .l-col-5.no--gutter:nth-child(n) {
    margin-right: 0px;
  }
  .l-col-5.no--gutter:last-child {
    margin-right: 0;
  }
  .l-col-5.no--gutter:nth-child(2n) {
    margin-right: 0 !important;
  }
  .l-col-6 {
    width: calc(100% * 6/12 - (40px - 40px * 6/12));
  }
  .l-col-6:nth-child(n) {
    margin-right: 40px;
  }
  .l-col-6:last-child {
    margin-right: 0;
  }
  .l-col-6:nth-child(2n) {
    margin-right: 0 !important;
  }
  .l-col-6.no--gutter {
    width: calc(100% * 6/12 - (0px - 0px * 6/12));
  }
  .l-col-6.no--gutter:nth-child(n) {
    margin-right: 0px;
  }
  .l-col-6.no--gutter:last-child {
    margin-right: 0;
  }
  .l-col-6.no--gutter:nth-child(2n) {
    margin-right: 0 !important;
  }
  .l-col-7 {
    width: calc(100% * 7/12 - (40px - 40px * 7/12));
  }
  .l-col-7:nth-child(n) {
    margin-right: 40px;
  }
  .l-col-7:last-child {
    margin-right: 0;
  }
  .l-col-7:nth-child(2n) {
    margin-right: 0 !important;
  }
  .l-col-7.no--gutter {
    width: calc(100% * 7/12 - (0px - 0px * 7/12));
  }
  .l-col-7.no--gutter:nth-child(n) {
    margin-right: 0px;
  }
  .l-col-7.no--gutter:last-child {
    margin-right: 0;
  }
  .l-col-7.no--gutter:nth-child(2n) {
    margin-right: 0 !important;
  }
  .l-col-8 {
    width: calc(100% * 8/12 - (40px - 40px * 8/12));
  }
  .l-col-8:nth-child(n) {
    margin-right: 40px;
  }
  .l-col-8:last-child {
    margin-right: 0;
  }
  .l-col-8:nth-child(2n) {
    margin-right: 0 !important;
  }
  .l-col-8.no--gutter {
    width: calc(100% * 8/12 - (0px - 0px * 8/12));
  }
  .l-col-8.no--gutter:nth-child(n) {
    margin-right: 0px;
  }
  .l-col-8.no--gutter:last-child {
    margin-right: 0;
  }
  .l-col-8.no--gutter:nth-child(2n) {
    margin-right: 0 !important;
  }
  .l-col-9 {
    width: calc(100% * 9/12 - (40px - 40px * 9/12));
  }
  .l-col-9:nth-child(n) {
    margin-right: 40px;
  }
  .l-col-9:last-child {
    margin-right: 0;
  }
  .l-col-9:nth-child(1n) {
    margin-right: 0 !important;
  }
  .l-col-9.no--gutter {
    width: calc(100% * 9/12 - (0px - 0px * 9/12));
  }
  .l-col-9.no--gutter:nth-child(n) {
    margin-right: 0px;
  }
  .l-col-9.no--gutter:last-child {
    margin-right: 0;
  }
  .l-col-9.no--gutter:nth-child(1n) {
    margin-right: 0 !important;
  }
  .l-col-10 {
    width: calc(100% * 10/12 - (40px - 40px * 10/12));
  }
  .l-col-10:nth-child(n) {
    margin-right: 40px;
  }
  .l-col-10:last-child {
    margin-right: 0;
  }
  .l-col-10:nth-child(1n) {
    margin-right: 0 !important;
  }
  .l-col-10.no--gutter {
    width: calc(100% * 10/12 - (0px - 0px * 10/12));
  }
  .l-col-10.no--gutter:nth-child(n) {
    margin-right: 0px;
  }
  .l-col-10.no--gutter:last-child {
    margin-right: 0;
  }
  .l-col-10.no--gutter:nth-child(1n) {
    margin-right: 0 !important;
  }
  .l-col-11 {
    width: calc(100% * 11/12 - (40px - 40px * 11/12));
  }
  .l-col-11:nth-child(n) {
    margin-right: 40px;
  }
  .l-col-11:last-child {
    margin-right: 0;
  }
  .l-col-11:nth-child(1n) {
    margin-right: 0 !important;
  }
  .l-col-11.no--gutter {
    width: calc(100% * 11/12 - (0px - 0px * 11/12));
  }
  .l-col-11.no--gutter:nth-child(n) {
    margin-right: 0px;
  }
  .l-col-11.no--gutter:last-child {
    margin-right: 0;
  }
  .l-col-11.no--gutter:nth-child(1n) {
    margin-right: 0 !important;
  }
  .l-col-12 {
    width: calc(100% * 12/12 - (40px - 40px * 12/12));
  }
  .l-col-12:nth-child(n) {
    margin-right: 40px;
  }
  .l-col-12:last-child {
    margin-right: 0;
  }
  .l-col-12:nth-child(1n) {
    margin-right: 0 !important;
  }
  .l-col-12.no--gutter {
    width: calc(100% * 12/12 - (0px - 0px * 12/12));
  }
  .l-col-12.no--gutter:nth-child(n) {
    margin-right: 0px;
  }
  .l-col-12.no--gutter:last-child {
    margin-right: 0;
  }
  .l-col-12.no--gutter:nth-child(1n) {
    margin-right: 0 !important;
  }
}
@media only screen and (max-width: 500px) {
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .s-col-1 {
    width: calc(100% * 1/12 - (40px - 40px * 1/12));
  }
  .s-col-1:nth-child(n) {
    margin-right: 40px;
  }
  .s-col-1:last-child {
    margin-right: 0;
  }
  .s-col-1:nth-child(12n) {
    margin-right: 0 !important;
  }
  .s-col-1.no--gutter {
    width: calc(100% * 1/12 - (0px - 0px * 1/12));
  }
  .s-col-1.no--gutter:nth-child(n) {
    margin-right: 0px;
  }
  .s-col-1.no--gutter:last-child {
    margin-right: 0;
  }
  .s-col-1.no--gutter:nth-child(12n) {
    margin-right: 0 !important;
  }
  .s-col-2 {
    width: calc(100% * 2/12 - (40px - 40px * 2/12));
  }
  .s-col-2:nth-child(n) {
    margin-right: 40px;
  }
  .s-col-2:last-child {
    margin-right: 0;
  }
  .s-col-2:nth-child(6n) {
    margin-right: 0 !important;
  }
  .s-col-2.no--gutter {
    width: calc(100% * 2/12 - (0px - 0px * 2/12));
  }
  .s-col-2.no--gutter:nth-child(n) {
    margin-right: 0px;
  }
  .s-col-2.no--gutter:last-child {
    margin-right: 0;
  }
  .s-col-2.no--gutter:nth-child(6n) {
    margin-right: 0 !important;
  }
  .s-col-3 {
    width: calc(100% * 3/12 - (40px - 40px * 3/12));
  }
  .s-col-3:nth-child(n) {
    margin-right: 40px;
  }
  .s-col-3:last-child {
    margin-right: 0;
  }
  .s-col-3:nth-child(4n) {
    margin-right: 0 !important;
  }
  .s-col-3.no--gutter {
    width: calc(100% * 3/12 - (0px - 0px * 3/12));
  }
  .s-col-3.no--gutter:nth-child(n) {
    margin-right: 0px;
  }
  .s-col-3.no--gutter:last-child {
    margin-right: 0;
  }
  .s-col-3.no--gutter:nth-child(4n) {
    margin-right: 0 !important;
  }
  .s-col-4 {
    width: calc(100% * 4/12 - (40px - 40px * 4/12));
  }
  .s-col-4:nth-child(n) {
    margin-right: 40px;
  }
  .s-col-4:last-child {
    margin-right: 0;
  }
  .s-col-4:nth-child(3n) {
    margin-right: 0 !important;
  }
  .s-col-4.no--gutter {
    width: calc(100% * 4/12 - (0px - 0px * 4/12));
  }
  .s-col-4.no--gutter:nth-child(n) {
    margin-right: 0px;
  }
  .s-col-4.no--gutter:last-child {
    margin-right: 0;
  }
  .s-col-4.no--gutter:nth-child(3n) {
    margin-right: 0 !important;
  }
  .s-col-5 {
    width: calc(100% * 5/12 - (40px - 40px * 5/12));
  }
  .s-col-5:nth-child(n) {
    margin-right: 40px;
  }
  .s-col-5:last-child {
    margin-right: 0;
  }
  .s-col-5:nth-child(2n) {
    margin-right: 0 !important;
  }
  .s-col-5.no--gutter {
    width: calc(100% * 5/12 - (0px - 0px * 5/12));
  }
  .s-col-5.no--gutter:nth-child(n) {
    margin-right: 0px;
  }
  .s-col-5.no--gutter:last-child {
    margin-right: 0;
  }
  .s-col-5.no--gutter:nth-child(2n) {
    margin-right: 0 !important;
  }
  .s-col-6 {
    width: calc(100% * 6/12 - (40px - 40px * 6/12));
  }
  .s-col-6:nth-child(n) {
    margin-right: 40px;
  }
  .s-col-6:last-child {
    margin-right: 0;
  }
  .s-col-6:nth-child(2n) {
    margin-right: 0 !important;
  }
  .s-col-6.no--gutter {
    width: calc(100% * 6/12 - (0px - 0px * 6/12));
  }
  .s-col-6.no--gutter:nth-child(n) {
    margin-right: 0px;
  }
  .s-col-6.no--gutter:last-child {
    margin-right: 0;
  }
  .s-col-6.no--gutter:nth-child(2n) {
    margin-right: 0 !important;
  }
  .s-col-7 {
    width: calc(100% * 7/12 - (40px - 40px * 7/12));
  }
  .s-col-7:nth-child(n) {
    margin-right: 40px;
  }
  .s-col-7:last-child {
    margin-right: 0;
  }
  .s-col-7:nth-child(2n) {
    margin-right: 0 !important;
  }
  .s-col-7.no--gutter {
    width: calc(100% * 7/12 - (0px - 0px * 7/12));
  }
  .s-col-7.no--gutter:nth-child(n) {
    margin-right: 0px;
  }
  .s-col-7.no--gutter:last-child {
    margin-right: 0;
  }
  .s-col-7.no--gutter:nth-child(2n) {
    margin-right: 0 !important;
  }
  .s-col-8 {
    width: calc(100% * 8/12 - (40px - 40px * 8/12));
  }
  .s-col-8:nth-child(n) {
    margin-right: 40px;
  }
  .s-col-8:last-child {
    margin-right: 0;
  }
  .s-col-8:nth-child(2n) {
    margin-right: 0 !important;
  }
  .s-col-8.no--gutter {
    width: calc(100% * 8/12 - (0px - 0px * 8/12));
  }
  .s-col-8.no--gutter:nth-child(n) {
    margin-right: 0px;
  }
  .s-col-8.no--gutter:last-child {
    margin-right: 0;
  }
  .s-col-8.no--gutter:nth-child(2n) {
    margin-right: 0 !important;
  }
  .s-col-9 {
    width: calc(100% * 9/12 - (40px - 40px * 9/12));
  }
  .s-col-9:nth-child(n) {
    margin-right: 40px;
  }
  .s-col-9:last-child {
    margin-right: 0;
  }
  .s-col-9:nth-child(1n) {
    margin-right: 0 !important;
  }
  .s-col-9.no--gutter {
    width: calc(100% * 9/12 - (0px - 0px * 9/12));
  }
  .s-col-9.no--gutter:nth-child(n) {
    margin-right: 0px;
  }
  .s-col-9.no--gutter:last-child {
    margin-right: 0;
  }
  .s-col-9.no--gutter:nth-child(1n) {
    margin-right: 0 !important;
  }
  .s-col-10 {
    width: calc(100% * 10/12 - (40px - 40px * 10/12));
  }
  .s-col-10:nth-child(n) {
    margin-right: 40px;
  }
  .s-col-10:last-child {
    margin-right: 0;
  }
  .s-col-10:nth-child(1n) {
    margin-right: 0 !important;
  }
  .s-col-10.no--gutter {
    width: calc(100% * 10/12 - (0px - 0px * 10/12));
  }
  .s-col-10.no--gutter:nth-child(n) {
    margin-right: 0px;
  }
  .s-col-10.no--gutter:last-child {
    margin-right: 0;
  }
  .s-col-10.no--gutter:nth-child(1n) {
    margin-right: 0 !important;
  }
  .s-col-11 {
    width: calc(100% * 11/12 - (40px - 40px * 11/12));
  }
  .s-col-11:nth-child(n) {
    margin-right: 40px;
  }
  .s-col-11:last-child {
    margin-right: 0;
  }
  .s-col-11:nth-child(1n) {
    margin-right: 0 !important;
  }
  .s-col-11.no--gutter {
    width: calc(100% * 11/12 - (0px - 0px * 11/12));
  }
  .s-col-11.no--gutter:nth-child(n) {
    margin-right: 0px;
  }
  .s-col-11.no--gutter:last-child {
    margin-right: 0;
  }
  .s-col-11.no--gutter:nth-child(1n) {
    margin-right: 0 !important;
  }
  .s-col-12 {
    width: calc(100% * 12/12 - (40px - 40px * 12/12));
  }
  .s-col-12:nth-child(n) {
    margin-right: 40px;
  }
  .s-col-12:last-child {
    margin-right: 0;
  }
  .s-col-12:nth-child(1n) {
    margin-right: 0 !important;
  }
  .s-col-12.no--gutter {
    width: calc(100% * 12/12 - (0px - 0px * 12/12));
  }
  .s-col-12.no--gutter:nth-child(n) {
    margin-right: 0px;
  }
  .s-col-12.no--gutter:last-child {
    margin-right: 0;
  }
  .s-col-12.no--gutter:nth-child(1n) {
    margin-right: 0 !important;
  }
}
[class*=col-] {
  float: left;
}
[class*=col-]:last-child {
  margin-right: 0;
}

.mt-20 {
  margin-top: 20px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.publisher-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 30;
}
.publisher-bar__bg {
  background-color: #222222;
}
.publisher-bar__bg__transparent {
  background-color: rgba(34, 34, 34, 0.95);
}
.publisher-bar__content {
  padding: 10px;
}
.publisher-bar__content .wrapper {
  display: flex;
}
.publisher-bar__content p {
  font-family: "freight-sans-pro";
  font-weight: bold;
  color: #fff;
  display: flex;
  margin-left: auto;
}
@media only screen and (max-width: 500px) {
  .publisher-bar__content p {
    font-size: 12px;
  }
}
.publisher-bar__content p:hover {
  cursor: pointer;
  text-decoration: underline;
}
.publisher-bar__content p svg {
  align-self: center;
  margin-left: 10px;
}
.publisher-bar__content p span {
  padding-left: 4px;
  font-weight: 500;
}
.publisher-bar__publishers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 40px 0 30px 0;
  margin: 0 -20px;
}
@media only screen and (max-width: 500px) {
  .publisher-bar__publishers {
    height: 100vh;
    overflow: scroll;
  }
}
.publisher-bar__publishers div {
  padding: 0 20px;
  width: 12.5%;
  text-align: center;
  margin-bottom: 20px;
}
@media only screen and (max-width: 800px) {
  .publisher-bar__publishers div {
    width: 33.3%;
  }
}
.publisher-bar__publishers div a {
  color: #fff;
  display: block;
}
.publisher-bar__publishers div a:hover {
  text-decoration: none;
}
.publisher-bar__publishers div p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}
.publisher-bar__publishers div img {
  max-width: 70px;
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.title-text-image {
  background-color: #f2f2ef;
  display: flex;
  padding: 50px 87px;
  margin-bottom: 54px;
  text-decoration: none;
  color: inherit;
  transition: background-color 0.2s ease-in-out;
}
@media only screen and (min-width: 800px) {
  .title-text-image {
    justify-content: space-between;
  }
}
@media only screen and (max-width: 800px) {
  .title-text-image {
    padding: 20px;
    flex-direction: column;
    margin-bottom: 40px;
  }
}
.title-text-image:hover {
  background-color: #e5e4de;
}
.title-text-image:hover .title-text-image__title {
  text-decoration: underline;
}
.title-text-image:hover .title-text-image__image img {
  transform: scale(1.05);
}
.title-text-image--dark {
  background-color: #1c2024;
}
.title-text-image--dark:hover {
  background-color: #333638;
}
.title-text-image--dark .title-text-image__title {
  color: #fff;
}
.title-text-image--dark .title-text-image__content {
  color: #fff;
}
.title-text-image--dark svg path {
  fill: #fff;
}
.title-text-image--dark .title-text-image__quoter {
  color: #fff;
}
.title-text-image .title-text-image__image {
  margin-right: 87px;
  margin-left: 0;
}
@media only screen and (max-width: 800px) {
  .title-text-image .title-text-image__image {
    margin-right: 0;
  }
}
.title-text-image--right {
  flex-direction: row-reverse;
}
@media only screen and (max-width: 800px) {
  .title-text-image--right {
    flex-direction: column;
  }
}
.title-text-image--right .title-text-image__image {
  margin-right: 0;
  margin-left: 126px;
}
@media only screen and (max-width: 800px) {
  .title-text-image--right .title-text-image__image {
    margin-left: 0;
  }
}
.title-text-image__title {
  font-size: 36px;
  line-height: 50px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-weight: bold;
  margin-bottom: 16px;
}
@media only screen and (max-width: 800px) {
  .title-text-image__title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;
  }
}
.title-text-image__content {
  font-size: 17px;
  line-height: 27px;
  font-family: "ABC Monument Grotesk";
  font-weight: 400;
  font-style: normal;
  margin-bottom: 20px;
}
@media only screen and (max-width: 800px) {
  .title-text-image__content {
    font-size: 14px;
    line-height: 24px;
  }
}
.title-text-image__content--quote {
  font-size: 24px;
  line-height: 32px;
  font-family: "Conv_Aileron-Heavy";
  margin-bottom: 13px;
}
.title-text-image__quoter {
  font-size: 17px;
  line-height: 27px;
  font-family: "freight-sans-pro";
  margin-bottom: 20px;
}
@media only screen and (max-width: 800px) {
  .title-text-image__quoter {
    font-size: 14px;
    line-height: 24px;
  }
}
.title-text-image__image {
  width: 100%;
  overflow: hidden;
}
@media only screen and (min-width: 800px) {
  .title-text-image__image {
    max-width: 530px;
  }
}
.title-text-image__image img {
  transition: all 1s ease-in-out;
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 800px) {
  .title-text-image__image img {
    margin-bottom: 30px;
  }
}
.title-text-image__content-container {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
}
@media only screen and (min-width: 800px) {
  .title-text-image__content-container {
    max-width: 527px;
  }
}

.menu-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 101px;
  transition: height 1s, background-color 0.2s ease-in-out;
  z-index: 3;
  max-width: 1440px;
  margin: 0 auto;
}
.with-publisher-bar .menu-bar {
  margin-top: 41px;
}
@media only screen and (max-width: 500px) {
  .with-publisher-bar .menu-bar {
    margin-top: 36px;
  }
}
@media only screen and (max-width: 500px) {
  .menu-bar {
    height: 70px;
  }
}
.menu-bar--bg {
  transition: height 1s, background-color 0.2s ease-in-out;
  background-color: #FFD600;
}
.menu-bar--bg .menu-bar__bg {
  opacity: 1;
}
.menu-bar__bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  background-image: url();
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}
.menu-bar__logotype {
  position: absolute;
  top: 80px;
  left: 80px;
  transition: all 1s;
  max-width: 230px;
  width: 100%;
}
.menu-bar--not-frontpage .menu-bar__logotype, .menu-bar--minimized .menu-bar__logotype {
  display: none;
}
@media only screen and (max-width: 500px) {
  .menu-bar__logotype {
    max-width: 80px;
    left: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
.menu-bar__logotype--minimized {
  display: none;
}
.menu-bar--not-frontpage .menu-bar__logotype--minimized, .menu-bar--minimized .menu-bar__logotype--minimized {
  display: block;
}
.menu-bar .site-menu__hamburger {
  top: 80px;
  right: 80px;
  transition: all 1s;
}
@media only screen and (max-width: 500px) {
  .menu-bar .site-menu__hamburger {
    right: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
.menu-bar .menu-bar__logotype {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
  max-width: 110px;
}
@media only screen and (max-width: 500px) {
  .menu-bar .menu-bar__logotype {
    max-width: 80px;
  }
}
@media only screen and (max-width: 500px) {
  .menu-bar .menu-bar__logotype {
    left: 20px;
  }
}
.menu-bar .site-menu__hamburger {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}
@media only screen and (max-width: 500px) {
  .menu-bar .site-menu__hamburger {
    right: 20px;
  }
}
.menu-bar .site-menu__login {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 113px;
}
@media only screen and (max-width: 500px) {
  .menu-bar .site-menu__login {
    right: 58px;
  }
}
.menu-bar .site-menu__account {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 113px;
}
@media only screen and (max-width: 500px) {
  .menu-bar .site-menu__account {
    right: 58px;
  }
}
.menu-bar .site-menu__cart {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 169px;
}
@media only screen and (max-width: 500px) {
  .menu-bar .site-menu__cart {
    right: 98px;
  }
}
.menu-bar .site-menu__returns {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 230px;
}
@media only screen and (max-width: 500px) {
  .menu-bar .site-menu__returns {
    right: 136px;
  }
}
.menu-bar--minimized, .menu-bar--not-frontpage {
  background-color: #FFD600;
  background-image: url();
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}
.menu-bar--minimized {
  height: 101px;
}
@media only screen and (max-width: 500px) {
  .menu-bar--minimized {
    height: 70px;
  }
}
.menu-bar--minimized .menu-bar__logotype {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 110px;
}
@media only screen and (max-width: 500px) {
  .menu-bar--minimized .menu-bar__logotype {
    max-width: 80px;
  }
}
.menu-bar--minimized .site-menu__hamburger {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.menu-bar--minimized .site-menu__cart {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.menu-bar--minimized .site-menu__returns {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.menu-bar--minimized .site-menu__account {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.menu-bar--minimized .site-menu__login {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.menu-bar--minimized .minibag--active, .menu-bar--minimized .miniaccount--active, .menu-bar--not-frontpage .minibag--active, .menu-bar--not-frontpage .miniaccount--active {
  transform: translateY(121px);
}
@media only screen and (max-width: 500px) {
  .menu-bar--minimized .minibag--active, .menu-bar--minimized .miniaccount--active, .menu-bar--not-frontpage .minibag--active, .menu-bar--not-frontpage .miniaccount--active {
    transform: translateY(70px);
  }
}

.minibag {
  max-width: 305px;
  width: 100%;
  padding: 30px;
  background-color: white;
  position: absolute;
  right: 160px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
  transform: translateY(-100%);
  opacity: 0;
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}
@media only screen and (max-width: 500px) {
  .minibag {
    right: 0px;
    left: 0px;
    max-width: 100%;
  }
}
.minibag--active {
  opacity: 1;
  transform: translateY(160px);
}
@media only screen and (max-width: 500px) {
  .minibag--active {
    transform: translateY(70px);
  }
}
.minibag__empty {
  font-size: 14px;
  line-height: 24px;
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #EBEBEB;
}
.minibag__empty a {
  color: #1C2024;
  transition: opacity 0.2s ease-in-out;
}
.minibag__empty a:hover {
  opacity: 0.6;
}
.minibag .button {
  width: 100%;
  text-align: center;
}
.minibag h2 {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  margin-bottom: 20px;
}
.minibag ul {
  list-style: none;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #EBEBEB;
  height: 155px;
  overflow-y: scroll;
  padding-right: 17px;
}
.minibag__row {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
}
.minibag__row:last-child {
  margin-bottom: 0;
}
.minibag__row__booktitle {
  font-size: 14px;
  line-height: 24px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  position: relative;
}
.minibag__row__count {
  font-size: 14px;
  line-height: 24px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
}
.minibag__row__author {
  font-size: 14px;
  line-height: 24px;
}
.minibag__row__remove {
  position: absolute;
  bottom: 8px;
  right: 0;
  width: 11px;
  height: 11px;
  background-image: url("../img/icon-close.svg");
  background-position: center center;
  background-size: 11px 10px;
  background-repeat: no-repeat;
}
.minibag__row__remove:hover {
  border-bottom: 1px solid black;
}

.miniaccount {
  max-width: 305px;
  width: 100%;
  padding: 30px;
  background-color: white;
  position: absolute;
  right: 89px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
  opacity: 0;
  transform: translateY(-100%);
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}
@media only screen and (max-width: 500px) {
  .miniaccount {
    right: 0px;
    left: 0px;
    max-width: 100%;
  }
}
.miniaccount--active {
  opacity: 1;
  transform: translateY(160px);
}
@media only screen and (max-width: 500px) {
  .miniaccount--active {
    transform: translateY(70px);
  }
}
.miniaccount h2 {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  margin-bottom: 20px;
}
.miniaccount ul {
  list-style: none;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #EBEBEB;
}
.miniaccount li {
  margin-bottom: 10px;
}
.miniaccount li:last-child {
  margin-bottom: 0;
}
.miniaccount li a {
  color: #1C2024;
  transition: opacity 0.2s ease-in-out;
}
.miniaccount li a:hover {
  opacity: 0.6;
}
.miniaccount__info {
  margin-bottom: 15px;
}
.miniaccount__info p {
  margin-bottom: 5px;
  font-size: 15px;
}
.miniaccount .button {
  width: 100%;
  text-align: center;
}

.menu-bar-bf {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  background-image: url();
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #FFD600;
  height: 51px;
  transition: height 1s;
  z-index: 3;
  max-width: 1440px;
  margin: 0 auto;
}
@media only screen and (max-width: 500px) {
  .menu-bar-bf {
    height: 90px;
  }
}
.menu-bar-bf__logotype {
  position: absolute;
  top: 0px;
  left: 80px;
  transition: top 1s;
  max-width: 278px;
  width: 100%;
}
@media only screen and (max-width: 500px) {
  .menu-bar-bf__logotype {
    max-width: 195px;
    top: 36px;
    left: 20px;
  }
}
@media only screen and (max-width: 320px) {
  .menu-bar-bf__logotype {
    max-width: 170px;
  }
}
.menu-bar-bf .site-menu__hamburger {
  top: 0px;
  right: 80px;
  transition: top 1s;
}
@media only screen and (max-width: 500px) {
  .menu-bar-bf .site-menu__hamburger {
    right: 20px;
    top: 20px;
  }
}
.menu-bar-bf--not-frontpage {
  height: 85px;
}
@media only screen and (max-width: 500px) {
  .menu-bar-bf--not-frontpage {
    height: 90px;
  }
}
.menu-bar-bf--not-frontpage .menu-bar__logotype {
  top: 31px;
  left: 40px;
}
@media only screen and (max-width: 500px) {
  .menu-bar-bf--not-frontpage .menu-bar__logotype {
    left: 20px;
    top: 36px;
  }
}
.menu-bar-bf--not-frontpage .site-menu__hamburger {
  top: 15px;
  right: 40px;
}
@media only screen and (max-width: 500px) {
  .menu-bar-bf--not-frontpage .site-menu__hamburger {
    right: 20px;
  }
}
.menu-bar-bf--not-frontpage .site-menu__login {
  top: 25px;
  right: 113px;
}
@media only screen and (max-width: 500px) {
  .menu-bar-bf--not-frontpage .site-menu__login {
    right: 58px;
  }
}
.menu-bar-bf--minimized {
  height: 85px;
}
@media only screen and (max-width: 500px) {
  .menu-bar-bf--minimized {
    height: 90px;
  }
}
.menu-bar-bf--minimized .menu-bar__logotype {
  top: 31px;
}
@media only screen and (max-width: 500px) {
  .menu-bar-bf--minimized .menu-bar__logotype {
    top: 36px;
  }
}
.menu-bar-bf--minimized .site-menu__hamburger {
  top: 15px;
}
@media only screen and (max-width: 500px) {
  .menu-bar-bf--minimized .site-menu__hamburger {
    top: 20px;
  }
}
.menu-bar-bf--minimized .site-menu__login {
  top: 25px;
}

.site-menu {
  max-width: 480px;
  width: 100%;
  background-color: #fff;
  padding: 140px 40px 40px 40px;
  position: fixed;
  right: 0;
  top: 0;
  overflow-y: scroll;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  transform: translateX(100%);
  transition: transform 0.4s cubic-bezier(0.26, 0.44, 0.23, 0.88);
  z-index: 30;
}
.site-menu--open {
  transform: translateX(0);
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.07);
}
.site-menu__hamburger {
  position: absolute;
  top: 40px;
  right: 40px;
  text-align: right;
  text-decoration: none;
  width: 40px;
}
@media only screen and (max-width: 500px) {
  .site-menu__hamburger {
    width: 28px;
  }
}
.site-menu__hamburger.hidden {
  opacity: 0;
  pointer-events: none;
}
.site-menu__hamburger:hover .hamburger span:nth-child(4) {
  transform: rotate(0deg);
}
.site-menu__hamburger .hamburger {
  height: 36px;
  width: 40px;
  margin-bottom: 6px;
  display: block;
  position: relative;
}
@media only screen and (max-width: 500px) {
  .site-menu__hamburger .hamburger {
    margin-bottom: 0;
    width: 28px;
    height: 28px;
  }
}
.site-menu__hamburger .hamburger span {
  height: 36px;
  width: 4px;
  background-color: #FFF;
  position: absolute;
  top: 0;
  transition: all 0.2s ease-in-out;
}
.menu-bar--not-frontpage .site-menu__hamburger .hamburger span, .menu-bar--minimized .site-menu__hamburger .hamburger span {
  background-color: #000;
}
@media only screen and (max-width: 500px) {
  .site-menu__hamburger .hamburger span {
    width: 3px;
    height: 28px;
  }
}
.site-menu__hamburger .hamburger span:nth-child(1) {
  right: 2px;
}
@media only screen and (max-width: 500px) {
  .site-menu__hamburger .hamburger span:nth-child(1) {
    right: 0;
  }
}
.site-menu__hamburger .hamburger span:nth-child(2) {
  right: 11px;
}
@media only screen and (max-width: 500px) {
  .site-menu__hamburger .hamburger span:nth-child(2) {
    right: 7px;
  }
}
.site-menu__hamburger .hamburger span:nth-child(3) {
  right: 20px;
}
@media only screen and (max-width: 500px) {
  .site-menu__hamburger .hamburger span:nth-child(3) {
    right: 14px;
  }
}
.site-menu__hamburger .hamburger span:nth-child(4) {
  right: 29px;
  transform: rotate(10deg);
}
@media only screen and (max-width: 500px) {
  .site-menu__hamburger .hamburger span:nth-child(4) {
    right: 21px;
    transform: rotate(10deg);
  }
}
.site-menu__hamburger .hamburger--small {
  height: 20px;
  width: 20px;
  float: right;
  margin-bottom: 5px;
}
.site-menu__hamburger .hamburger--small span {
  height: 20px;
  width: 3px;
}
.site-menu__hamburger .menu-text {
  text-align: center;
  width: 100%;
  color: #FFF;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 1px;
  display: block;
}
.menu-bar--not-frontpage .site-menu__hamburger .menu-text, .menu-bar--minimized .site-menu__hamburger .menu-text {
  color: #000;
}
@media only screen and (max-width: 500px) {
  .site-menu__hamburger .menu-text {
    display: none;
  }
}
.site-menu__hamburger--open .hamburger span {
  background-color: #1C2024;
}
.site-menu__hamburger--open .hamburger span:nth-child(1) {
  opacity: 0;
}
.site-menu__hamburger--open .hamburger span:nth-child(2) {
  transform: rotate(-45deg);
  right: 7px;
  top: 0;
  transition-delay: 0.4s;
}
.site-menu__hamburger--open .hamburger span:nth-child(3) {
  transform: rotate(45deg);
  top: 0;
  left: 10px;
  transition-delay: 0.2s;
}
.site-menu__hamburger--open .hamburger span:nth-child(4) {
  opacity: 0;
}
.site-menu__hamburger--open .menu-text {
  color: #1C2024;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.86px;
  display: inline-block;
}
.site-menu__login {
  position: absolute;
  right: 0;
  top: 89px;
  right: 153px;
  text-decoration: none;
  text-align: center;
  transition: all 1s;
}
.site-menu__login__icon {
  width: 26px;
  height: 27px;
  margin: 0 auto;
  margin-bottom: 5px;
  background-image: url(../img/icon-login.svg);
}
.site-menu__login__icon.invert {
  background-image: url(../img/icon-login.svg);
}
.menu-bar--not-frontpage .site-menu__login__icon, .menu-bar--minimized .site-menu__login__icon {
  background-image: url(../img/icon-login-small-black.svg);
}
@media only screen and (max-width: 500px) {
  .site-menu__login__icon {
    width: 19px;
    height: 19px;
    background-size: 19px 19px;
  }
}
.site-menu__login .login-text {
  font-size: 10px;
  line-height: 14px;
  color: #FFF;
  display: block;
  text-align: center;
}
.menu-bar--not-frontpage .site-menu__login .login-text, .menu-bar--minimized .site-menu__login .login-text {
  color: #000;
}
@media only screen and (max-width: 500px) {
  .site-menu__login .login-text {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .site-menu__login {
    width: 19px;
    height: 19px;
    right: 58px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .site-menu__login img {
    width: 19px;
    height: 19px;
  }
}
.site-menu__language {
  position: absolute;
  top: 40px;
  font-size: 16px;
  line-height: 24px;
  color: #1C2024;
  transition: opacity 0.2s ease-in-out;
}
.site-menu__language:hover {
  opacity: 0.6;
}
.site-menu__menu {
  list-style: none;
  margin-bottom: 50px;
}
.site-menu__links {
  text-decoration: none;
  color: #1C2024;
  font-size: 24px;
  line-height: 32px;
  position: relative;
  display: block;
  padding-bottom: 15px;
  margin-bottom: 15px;
  transition: color 0.2s ease-in-out;
}
.site-menu__links:after {
  content: "";
  width: 100%;
  height: 4px;
  background-color: #1C2024;
  position: absolute;
  bottom: 0;
  left: 0;
}
.site-menu__links--sub {
  font-size: 18px;
  line-height: 26px;
  margin-left: 20px;
}
.site-menu__links--sub:after {
  background-color: #c7c7c7;
  height: 1px;
}
.site-menu__links--current-item {
  color: rgba(28, 32, 36, 0.6);
}
.site-menu__links--open:after {
  background-color: #c7c7c7;
  height: 1px;
}
.site-menu__links:hover {
  color: rgba(28, 32, 36, 0.45);
}
.site-menu__sub-wrap {
  position: relative;
  margin-bottom: 15px;
}
.site-menu__sub-wrap span {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
}
.site-menu__sub-wrap span.open:after {
  opacity: 0;
}
.site-menu__sub-wrap span:before {
  content: "";
  width: 4px;
  height: 18px;
  background-color: #1C2024;
  position: absolute;
  left: 50%;
  margin-left: -2px;
  transform: rotate(90deg);
  top: 16px;
  margin-top: -8px;
  transition: color 0.2s ease-in-out;
}
.site-menu__sub-wrap span:after {
  content: "";
  width: 4px;
  height: 18px;
  background-color: #1C2024;
  position: absolute;
  margin-top: -8px;
  top: 16px;
  left: 50%;
  margin-left: -2px;
  transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out;
}
.site-menu__sub-wrap span:hover {
  cursor: pointer;
}
.site-menu__sub-wrap span:hover:after, .site-menu__sub-wrap span:hover:before {
  background-color: rgba(28, 32, 36, 0.6);
}
.site-menu__sub-wrap--active-parent .site-menu__links:after {
  background-color: #c7c7c7;
  height: 1px;
}
.site-menu__sub-wrap--active-parent .site-menu__submenu {
  display: block;
  border-bottom: 4px solid #1c2024;
}
.site-menu__sub-wrap--active-parent span:after {
  opacity: 0;
}
.site-menu__submenu {
  list-style: none;
  display: none;
}
.site-menu__submenu li:last-child a {
  margin-bottom: 0;
}
.site-menu__submenu li:last-child a:after {
  display: none;
}
.site-menu__submenu--visible {
  display: block;
  border-bottom: 4px solid #1C2024;
}
.site-menu .cart-holder {
  position: relative;
}
.site-menu__cart {
  position: absolute;
  right: 0;
  top: 89px;
  right: 209px;
  text-decoration: none;
  text-align: center;
  transition: all 1s;
}
.site-menu__cart--added .item-count {
  animation: expand;
  animation-duration: 0.4s;
}
.site-menu__cart__icon {
  width: 23px;
  height: 27px;
  margin: 0 auto;
  margin-bottom: 5px;
  background-image: url(../img/icon-cart.svg);
  background-repeat: no-repeat;
}
.menu-bar--not-frontpage .site-menu__cart__icon, .menu-bar--minimized .site-menu__cart__icon {
  background-image: url(../img/icon-cart-black.svg);
}
@media only screen and (max-width: 500px) {
  .site-menu__cart__icon {
    width: 16px;
    height: 18px;
    background-size: 16px 18px;
  }
}
.site-menu__cart .login-text {
  font-size: 10px;
  line-height: 14px;
  color: #FFF;
  display: block;
  text-align: center;
}
.menu-bar--not-frontpage .site-menu__cart .login-text, .menu-bar--minimized .site-menu__cart .login-text {
  color: #000;
}
@media only screen and (max-width: 500px) {
  .site-menu__cart .login-text {
    display: none;
  }
}
.site-menu__cart .item-count {
  position: absolute;
  right: -4px;
  top: -10px;
  color: #1c2024;
  background-color: #f4f4f4;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  text-align: center;
  font-size: 10px;
  line-height: 20px;
  display: block;
}
@media only screen and (max-width: 500px) {
  .site-menu__cart .item-count {
    width: 14px;
    height: 14px;
    line-height: 14px;
  }
}
@media only screen and (max-width: 500px) {
  .site-menu__cart {
    width: 19px;
    height: 19px;
    right: 98px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .site-menu__cart img {
    width: 19px;
    height: 19px;
  }
}
.site-menu__returns {
  position: absolute;
  top: 89px;
  right: 277px;
  text-decoration: none;
  text-align: center;
  transition: all 1s;
}
.site-menu__returns--added .item-count {
  animation: expand;
  animation-duration: 0.4s;
}
.site-menu__returns__icon {
  width: 22px;
  height: 27px;
  margin: 0 auto;
  margin-bottom: 5px;
  background-image: none;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 500px) {
  .site-menu__returns__icon {
    width: 16px;
    height: 18px;
    background-size: 16px 18px;
  }
}
.site-menu__returns .login-text {
  font-size: 10px;
  line-height: 14px;
  color: #FFF;
  display: block;
  text-align: center;
}
.menu-bar--not-frontpage .site-menu__returns .login-text, .menu-bar--minimized .site-menu__returns .login-text {
  color: #000;
}
@media only screen and (max-width: 500px) {
  .site-menu__returns .login-text {
    display: none;
  }
}
.site-menu__returns .item-count {
  position: absolute;
  right: -4px;
  top: -10px;
  color: #1c2024;
  background-color: #f4f4f4;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  text-align: center;
  font-size: 10px;
  line-height: 20px;
  display: block;
}
@media only screen and (max-width: 500px) {
  .site-menu__returns .item-count {
    width: 14px;
    height: 14px;
    line-height: 14px;
  }
}
@media only screen and (max-width: 500px) {
  .site-menu__returns {
    width: 19px;
    height: 19px;
    right: 138px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .site-menu__returns img {
    width: 19px;
    height: 19px;
  }
}
.site-menu__account {
  position: absolute;
  right: 0;
  top: 89px;
  right: 153px;
  text-decoration: none;
  text-align: center;
  transition: all 1s;
}
.site-menu__account__icon {
  width: 26px;
  height: 27px;
  margin: 0 auto;
  margin-bottom: 5px;
  background-image: url(../img/icon-account.svg);
}
.menu-bar--not-frontpage .site-menu__account__icon, .menu-bar--minimized .site-menu__account__icon {
  background-image: url(../img/icon-account-black.svg);
}
@media only screen and (max-width: 500px) {
  .site-menu__account__icon {
    width: 19px;
    height: 19px;
    background-size: 19px 19px;
  }
}
.site-menu__account .login-text {
  font-size: 10px;
  line-height: 14px;
  color: #FFF;
  display: block;
  text-align: center;
}
.menu-bar--not-frontpage .site-menu__account .login-text, .menu-bar--minimized .site-menu__account .login-text {
  color: #000;
}
@media only screen and (max-width: 500px) {
  .site-menu__account .login-text {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .site-menu__account {
    width: 19px;
    height: 19px;
    right: 58px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .site-menu__account img {
    width: 19px;
    height: 19px;
  }
}

@keyframes expand {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
.top-section {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 60px;
  background-image: url();
  background-repeat: no-repeat;
  background-color: #000;
  background-size: cover;
  background-position: center top;
  height: 420px;
  position: relative;
}
@media only screen and (max-width: 500px) {
  .top-section {
    height: 280px;
  }
}
.top-section--overlap {
  margin-bottom: -100px;
}
@media only screen and (max-width: 800px) {
  .top-section--overlap {
    margin-bottom: -60px;
  }
}
.top-section__wrapper {
  max-width: 1280px;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 135px;
}
@media only screen and (max-width: 1330px) {
  .top-section__wrapper {
    padding: 0 40px;
  }
}
@media only screen and (max-width: 800px) {
  .top-section__wrapper {
    transform: none;
    left: 20px;
    right: 20px;
    bottom: 80px;
    width: auto;
    padding: 0;
  }
  .top-section__wrapper .col-7 {
    width: 100%;
  }
}
.top-section__count {
  background-image: url(../img/icon-magnifier-white-small.svg);
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  margin-bottom: 10px;
  transition: opacity 0.4s ease-in-out;
}
.top-section__count.fade {
  opacity: 0.4;
}
.top-section__title {
  font-size: 40px;
  line-height: 48px;
  color: #FFF;
  font-family: "ABC Monument Grotesk";
  font-weight: 600;
  font-style: normal;
}
@media only screen and (max-width: 930px) {
  .top-section__title {
    font-size: 30px;
    line-height: 40px;
  }
}
@media only screen and (max-width: 1080px) {
  .top-section__title {
    font-size: 30px;
    line-height: 40px;
  }
}
@media only screen and (max-width: 500px) {
  .top-section__title {
    font-size: 20px;
    line-height: 30px;
  }
}
.top-section__title span {
  transition: opacity 0.4s ease-in-out;
}
.top-section__title a {
  color: #FFF;
  transition: opacity 0.4s ease-in-out;
  text-decoration: none;
  position: relative;
  display: inline-block;
}
.top-section__title a:after {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  bottom: 0px;
  left: 0;
  background-color: #FFF;
}
.top-section__title a:before {
  content: "";
  position: absolute;
  height: 3px;
  width: 0%;
  bottom: 0px;
  left: 0;
  background-color: #FFF;
  transition: width 0.4s cubic-bezier(0.405, 0.07, 0.575, 0.995) 0.1s;
}
.top-section__title--fade span {
  opacity: 0.4;
}
.top-section__title--fade a {
  opacity: 0.4;
}
.top-section__title--fade a.hover {
  opacity: 1;
}
.top-section__title--fade a.hover:after {
  display: none;
}
.top-section__title--fade a.hover:before {
  width: 100%;
}
.top-section__title--search {
  color: #FFF;
}
.top-section__bonnier {
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
}
@media only screen and (max-width: 1330px) {
  .top-section__bonnier {
    right: 40px;
  }
}
@media only screen and (max-width: 800px) {
  .top-section__bonnier {
    display: none;
  }
}
.top-section__bonnier span {
  color: #000;
  font-size: 16px;
  line-height: 24px;
}
.top-section__bonnier span a {
  color: #000;
  text-decoration: none;
}
.top-section__bonnier span a:hover {
  text-decoration: underline;
}

.top-section-bf {
  position: relative;
  width: 100%;
  max-width: 1360px;
  margin: 142px auto 0 auto;
  background-image: url("../img/sveavagen-ekport-ny3.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 500px;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 500px) {
  .top-section-bf {
    margin: 106px auto 40px auto;
    min-height: 500px;
  }
}
.top-section-bf:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(28, 32, 36, 0.33);
  z-index: 1;
}
.top-section-bf__content {
  position: relative;
  z-index: 2;
  max-width: 511px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 77px;
  margin-left: 89px;
}
@media only screen and (max-width: 500px) {
  .top-section-bf__content {
    justify-content: center;
    margin: 0;
    height: auto;
    padding: 0 20px;
    height: 100%;
  }
}
.top-section-bf__content span {
  font-family: "freight-sans-pro";
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
}
.top-section-bf__content h1 {
  font-family: "freight-sans-pro";
  font-weight: bold;
  font-size: 46px;
  line-height: 48px;
  color: #fff;
  margin-bottom: 40px;
  margin-top: 10px;
}
@media only screen and (max-width: 500px) {
  .top-section-bf__content h1 {
    font-size: 36px;
    line-height: 45px;
  }
}
.top-section-bf__content .button {
  color: #1C2024;
  background-color: #E5E4DF;
  padding: 8px 35px;
  font-size: 16px;
  line-height: 24px;
  font-family: "Conv_Aileron-SemiBold";
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
}
.top-section-bf__explainer {
  background-color: #E5E4DF;
  margin-top: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 80px;
  max-width: 818px;
  width: 100%;
  z-index: 2;
  padding: 35px 20px 35px 89px;
}
@media only screen and (max-width: 500px) {
  .top-section-bf__explainer {
    display: block;
    padding: 20px;
  }
}
.top-section-bf__explainer div {
  width: 50%;
}
@media only screen and (max-width: 500px) {
  .top-section-bf__explainer div {
    width: 100%;
  }
}
.top-section-bf__explainer p {
  font-size: 15px;
  line-height: 24px;
  font-family: "Conv_Aileron-Regular";
}
@media only screen and (max-width: 500px) {
  .top-section-bf__explainer p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .top-section-bf__explainer p:last-child {
    margin-bottom: 0;
  }
}

.top-section__extended {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 60px;
  background-image: url();
  background-repeat: no-repeat;
  background-color: #000;
  background-size: cover;
  background-position: center top;
  height: 620px;
  position: relative;
  padding: 180px 20px 0 20px;
  color: #000;
}
@media only screen and (max-width: 500px) {
  .top-section__extended {
    padding-top: 130px;
    height: auto;
  }
}
.top-section__extended__intro {
  text-align: center;
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
}
@media only screen and (max-width: 500px) {
  .top-section__extended__intro {
    padding: 0 20px;
  }
}
.top-section__extended__intro h1 {
  font-size: 46px;
  line-height: 59px;
  margin-bottom: 120px;
  color: inherit;
}
@media only screen and (max-width: 500px) {
  .top-section__extended__intro h1 {
    font-size: 44px;
    line-height: 52px;
    margin-bottom: 20px;
  }
}
.top-section__extended__intro h2 {
  text-transform: uppercase;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 50px;
  color: inherit;
}
@media only screen and (max-width: 500px) {
  .top-section__extended__intro h2 {
    margin-bottom: 20px;
  }
}
.top-section__extended__readmore {
  display: block;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}
@media only screen and (max-width: 500px) {
  .top-section__extended__readmore {
    padding: 0 20px;
  }
}
.top-section__extended__readmore p {
  font-size: 16px;
  line-height: 24px;
}
.top-section__extended__readmore a {
  color: inherit;
}
.top-section__extended__bonnier {
  text-align: right;
  position: absolute;
  bottom: 40px;
  right: 40px;
}
@media only screen and (max-width: 1330px) {
  .top-section__extended__bonnier {
    right: 40px;
  }
}
@media only screen and (max-width: 800px) {
  .top-section__extended__bonnier {
    display: none;
  }
}
.top-section__extended__bonnier span {
  color: #000;
  font-size: 16px;
  line-height: 24px;
}
.top-section__extended__bonnier span strong {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
}
.top-section__extended__bonnier span a {
  color: #000;
  text-decoration: none;
}
.top-section__extended__bonnier span a:hover {
  text-decoration: underline;
}

.breadcrumbs {
  width: 100%;
  display: inline-block;
  margin-bottom: 20px;
}
.breadcrumbs a {
  font-size: 12px;
  line-height: 24px;
  color: #1C2024;
}
.breadcrumbs span {
  font-size: 12px;
  line-height: 24px;
}

.rooms {
  padding: 40px;
  background: #e6e4df;
}
@media only screen and (max-width: 500px) {
  .rooms {
    padding: 20px;
  }
}
.rooms--full {
  margin-bottom: 40px;
}
@supports (display: grid) {
  .rooms--full .row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 40px;
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    .rooms--full .row {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media only screen and (max-width: 500px) {
    .rooms--full .row {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
@media only screen and (max-width: 500px) {
  .rooms--full {
    margin-bottom: 20px;
  }
}
.rooms--full .rooms__description {
  float: right;
}
.rooms--full .rooms__logotype {
  margin-bottom: 60px;
}
@media only screen and (max-width: 500px) {
  .rooms--full .rooms__logotype {
    margin-bottom: 20px;
  }
}
.rooms--full .rooms__column {
  margin-bottom: 0;
}
@media only screen and (max-width: 500px) {
  .rooms--full .rooms__column {
    margin-bottom: 40px;
  }
}
.rooms__logotype {
  margin-bottom: 30px;
  max-width: 150px;
}
.rooms__description {
  display: block;
  font-family: "freight-sans-pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 1024px) {
  .rooms__description {
    max-width: 500px;
  }
}
@media only screen and (max-width: 800px) {
  .rooms__description {
    max-width: 300px;
  }
}
.rooms__description a {
  color: #1C2024;
  text-decoration: underline;
  transition: opacity 0.2s ease-in-out;
}
.rooms__description a:hover {
  opacity: 0.6;
}
.rooms__description strong {
  font-family: "freight-sans-pro";
  font-weight: 700;
}
.rooms__column {
  text-align: center;
  margin-bottom: 40px;
  text-decoration: none;
  color: #1C2024;
}
@supports not (display: grid) {
  .rooms__column {
    margin-bottom: 40px;
  }
}
@supports (display: grid) {
  .rooms__column {
    width: 100%;
    margin-right: 0 !important;
  }
}
.rooms__column:last-child {
  margin-bottom: 0;
}
.rooms__column:hover .rooms__column__link {
  opacity: 0.6;
}
.rooms__column__image {
  max-width: 94px;
  width: 100%;
  display: inline-block;
  margin-bottom: 20px;
  transition: transform 1s ease-in-out;
}
.rooms__column__link {
  font-family: "freight-sans-pro";
  font-weight: 600;
  display: block;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
  text-decoration: underline;
  transition: opacity 0.2s ease-in-out;
}
.rooms__column__text {
  font-family: "freight-sans-pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.news {
  padding: 40px;
  background-color: #E4D7C3;
  margin-bottom: 60px;
  z-index: 2;
  position: relative;
  max-width: 1360px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 60px;
}
.news.title-text-image .news__item {
  margin-bottom: 0;
}
@media only screen and (max-width: 500px) {
  .news {
    padding: 20px;
    margin-bottom: 40px;
  }
}
.news__section-title {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 40px;
  display: inline-block;
  text-decoration: none;
  color: #202020;
  text-decoration: underline;
}
@media only screen and (max-width: 800px) {
  .news__section-title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
.news__section-title:hover {
  text-decoration: none;
}
.news__grid {
  display: grid;
  width: 100%;
  margin-bottom: 40px;
}
@supports (display: grid) {
  .news__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    width: 100%;
  }
  @media only screen and (max-width: 800px) {
    .news__grid {
      grid-column-gap: 20px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media only screen and (max-width: 500px) {
    .news__grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
@supports not (display: grid) {
  .news__grid .news__item {
    margin-bottom: 40px;
  }
}
@supports (display: grid) {
  .news__grid .news__item {
    width: 100%;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
  }
}
.news__item {
  margin-bottom: 40px;
}
@supports (display: grid) {
  .news__item--featured .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    width: 100%;
  }
  @media only screen and (max-width: 800px) {
    .news__item--featured .grid {
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 20px;
    }
  }
}
.news__item--featured .news__title {
  font-size: 38px;
  line-height: 48px;
  margin-bottom: 20px;
  color: #202020;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
}
@media only screen and (max-width: 800px) {
  .news__item--featured .news__title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 0;
  }
}
.news__item--featured .news__image {
  margin-bottom: 0;
}
.news__item:hover .news__title {
  text-decoration: underline;
}
.news__item:hover .news__arrow {
  transform: translateX(10px);
}
.news__item:hover .news__image img {
  transform: scale(1.05);
}
.news__excerpt {
  font-size: 16px;
  line-height: 26px;
}
@media only screen and (max-width: 800px) {
  .news__excerpt {
    display: none;
  }
}
.news__item a {
  color: inherit;
  text-decoration: none;
  display: inline-block;
  width: 100%;
}
.news__image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  overflow: hidden;
}
.news__image img {
  transition: transform 1s ease-in-out;
}
.news__date {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1px;
  display: block;
  margin-bottom: 10px;
  color: #202020;
}
.news__publisher {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1px;
  display: block;
}
.news__title {
  font-size: 22px;
  line-height: 28px;
  color: #202020;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
}
@media only screen and (max-width: 800px) {
  .news__title {
    font-size: 24px;
    line-height: 32px;
  }
}
.news__arrow {
  margin-top: 10px;
  transition: transform 0.2s ease-in-out;
  width: 19px;
  height: 21px;
  display: block;
  background-image: url(../img/icon-arrow-right.svg);
  background-repeat: no-repeat;
  background-position: center center;
}
.news__all-news-link {
  display: flex;
}
.news__all-news-link a {
  margin-left: auto;
  color: #202020;
}
@media only screen and (max-width: 800px) {
  .news__all-news-link a {
    margin-left: 0;
  }
}
.news__all-news-link a:hover {
  text-decoration: none;
}

.book-columns {
  width: 100%;
}
@supports (display: grid) {
  .book-columns {
    display: grid;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    align-items: end;
  }
  .book-columns--two {
    grid-template-columns: repeat(2, 1fr);
  }
  .book-columns--three {
    grid-template-columns: repeat(3, 1fr);
  }
  .book-columns--four {
    grid-template-columns: repeat(4, 1fr);
  }
  .book-columns--five {
    grid-template-columns: repeat(5, 1fr);
  }
  .book-columns--six {
    grid-template-columns: repeat(6, 1fr);
  }
  @media only screen and (max-width: 1024px) {
    .book-columns {
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 10px;
    }
  }
  @media only screen and (max-width: 500px) {
    .book-columns {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media only screen and (max-width: 320px) {
    .book-columns {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
.book-columns__item {
  overflow: hidden;
  position: relative;
}
.book-columns__item--year {
  padding-bottom: 21px;
}
.book-columns__book-link {
  color: inherit;
}
.book-columns__book-link:hover .book-columns__title {
  opacity: 0.6;
}
.book-columns__cover {
  margin-bottom: 20px;
}
.book-columns__cover img {
  width: 100%;
  height: auto;
}
.book-columns__title {
  text-decoration: underline;
  font-size: 16px;
  line-height: 24px;
  transition: opacity 0.2s ease-in-out;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out;
}
.book-columns__author-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.book-columns__book-authors {
  font-size: 16px;
  line-height: 24px;
  color: #a9a9a9;
  transition: opacity 0.2s ease-in-out;
  white-space: nowrap;
}
.book-columns__book-authors:hover {
  opacity: 0.6;
}
.book-columns__book-authors--no-link:hover {
  opacity: 1;
}
.book-columns__book-price {
  font-size: 0.8em;
}
.book-columns__year {
  position: absolute;
}

.current-books {
  background-color: #fff;
  margin-bottom: 40px;
}
.current-books--padding {
  padding: 40px;
}
@media only screen and (max-width: 500px) {
  .current-books--padding {
    padding: 20px;
  }
}
@supports (display: grid) {
  .current-books__holder {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    width: 100%;
    align-items: end;
  }
  @media only screen and (max-width: 1024px) {
    .current-books__holder {
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 10px;
    }
  }
  @media only screen and (max-width: 500px) {
    .current-books__holder {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media only screen and (max-width: 320px) {
    .current-books__holder {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
.current-books__title a {
  color: inherit;
  text-decoration: none;
}
.current-books__title {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 40px;
  color: #202020;
  text-decoration: underline;
}
@media only screen and (max-width: 500px) {
  .current-books__title {
    font-size: 18px;
    line-height: 24px;
  }
}
.current-books__title:hover {
  text-decoration: none;
}
.current-books__book {
  color: inherit;
  text-decoration: none;
  overflow: hidden;
}
@supports not (display: grid) {
  .current-books__book {
    margin-bottom: 40px;
  }
}
@supports (display: grid) {
  .current-books__book {
    margin-right: 0 !important;
  }
}
.current-books__book-title {
  text-decoration: underline;
  font-size: 16px;
  line-height: 24px;
  transition: opacity 0.2s ease-in-out;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media only screen and (max-width: 500px) {
  .current-books__book-title {
    font-size: 14px;
    line-height: 22px;
  }
}
.current-books__book-cover-wrap {
  margin-bottom: 20px;
}
.current-books__book-cover {
  width: 100%;
  height: auto;
}
.current-books__author-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.current-books__book-authors {
  font-size: 16px;
  line-height: 24px;
  color: #A9A9A9;
  transition: opacity 0.2s ease-in-out;
  white-space: nowrap;
}
.current-books__book-authors:hover {
  opacity: 0.6;
}
@media only screen and (max-width: 500px) {
  .current-books__book-authors {
    font-size: 14px;
    line-height: 22px;
  }
}
.current-books__book-link {
  color: initial;
}
.current-books__book-link:hover h3 {
  opacity: 0.6;
}

.current-authors {
  background-color: #1C2024;
  padding: 40px 40px 0px 40px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 500px) {
  .current-authors {
    margin-bottom: 20px;
    padding: 20px;
  }
}
.current-authors__title a {
  color: inherit;
  text-decoration: none;
}
.current-authors__title {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 40px;
  display: inline-block;
  color: #FFFFFF;
  text-decoration: underline;
}
@media only screen and (max-width: 500px) {
  .current-authors__title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}
.current-authors__title:hover {
  text-decoration: none;
}
@supports (display: grid) {
  .current-authors .row {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    .current-authors .row {
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 10px;
    }
  }
  @media only screen and (max-width: 500px) {
    .current-authors .row {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media only screen and (max-width: 320px) {
    .current-authors .row {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
.current-authors__author {
  margin-bottom: 40px;
}
@supports not (display: grid) {
  .current-authors__author {
    margin-bottom: 40px;
  }
}
@supports (display: grid) {
  .current-authors__author {
    width: 100%;
    margin-right: 0 !important;
  }
}
.current-authors__author:hover .current-authors__author-name {
  opacity: 0.6;
}
.current-authors__author-image {
  width: 100%;
  height: auto;
  margin-bottom: 24px;
  filter: grayscale(100%);
}
.current-authors__no-image {
  padding-bottom: 150%;
  margin-bottom: 24px;
  position: relative;
}
.current-authors__no-image span {
  color: #000;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 60px;
  line-height: 60px;
  text-transform: uppercase;
}
.current-authors__author-name {
  color: #fff;
  text-decoration: underline;
  font-size: 18px;
  line-height: 20px;
  transition: opacity 0.2s ease-in-out;
}

.current-themes {
  background-color: #1C2024;
  padding: 40px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 500px) {
  .current-themes {
    padding: 20px;
    margin-bottom: 20px;
  }
}
.current-themes .row {
  width: 100%;
}
@supports (display: grid) {
  .current-themes .row {
    display: grid;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }
  .current-themes .row:after {
    display: none;
  }
  .current-themes .row.grid-4_2 {
    grid-template-columns: repeat(4, 4fr);
  }
  @media only screen and (max-width: 1024px) {
    .current-themes .row.grid-4_2 {
      grid-template-columns: repeat(3, 3fr);
      grid-column-gap: 40px;
    }
  }
  @media only screen and (max-width: 800px) {
    .current-themes .row.grid-4_2 {
      grid-template-columns: repeat(2, 2fr);
      grid-column-gap: 40px;
    }
  }
  @media only screen and (max-width: 500px) {
    .current-themes .row.grid-4_2 {
      grid-template-columns: repeat(2, 2fr);
      grid-column-gap: 20px;
    }
  }
  .current-themes .row.grid-3_1 {
    grid-template-columns: repeat(3, 3fr);
  }
  @media only screen and (max-width: 1024px) {
    .current-themes .row.grid-3_1 {
      grid-template-columns: repeat(3, 3fr);
      grid-column-gap: 40px;
    }
  }
  @media only screen and (max-width: 800px) {
    .current-themes .row.grid-3_1 {
      grid-template-columns: repeat(2, 2fr);
      grid-column-gap: 40px;
    }
  }
  @media only screen and (max-width: 500px) {
    .current-themes .row.grid-3_1 {
      grid-template-columns: repeat(1, 100%);
    }
  }
  .current-themes .row.grid-2_1 {
    grid-template-columns: repeat(2, 2fr);
  }
  @media only screen and (max-width: 500px) {
    .current-themes .row.grid-2_1 {
      grid-template-columns: repeat(1, 100%);
    }
  }
}
@supports not (display: grid) {
  .current-themes__genre {
    margin-bottom: 40px;
  }
}
@supports (display: grid) {
  .current-themes__genre {
    width: 100%;
    margin: 0 !important;
  }
}
.current-themes__genre a {
  text-decoration: none;
}
.current-themes__genre a:hover .current-themes__genre-title {
  text-decoration: underline;
}
.current-themes__title a {
  color: inherit;
  text-decoration: none;
}
.current-themes__title {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 40px;
  display: inline-block;
  color: #fff;
  text-decoration: underline;
}
@media only screen and (max-width: 500px) {
  .current-themes__title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 28px;
  }
}
.current-themes__title:hover {
  text-decoration: none;
}
.current-themes__genre-image {
  width: 100%;
  height: auto;
  vertical-align: top;
  margin-bottom: 30px;
}
@media only screen and (max-width: 500px) {
  .current-themes__genre-image {
    margin-bottom: 20px;
  }
}
.current-themes__genre-title {
  color: #fff;
  font-size: 34px;
  line-height: 42px;
  word-break: break-word;
  margin-bottom: 20px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
}
@media only screen and (max-width: 500px) {
  .current-themes__genre-title {
    font-size: 26px;
    line-height: 32px;
  }
}
.current-themes p {
  color: #fff;
  font-size: 18px;
  line-height: 24px;
}
@media only screen and (max-width: 500px) {
  .current-themes p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 40px;
  }
  @supports not (display: grid) {
    .current-themes p {
      margin-bottom: 40px;
    }
  }
  @supports (display: grid) {
    .current-themes p {
      width: 100%;
      margin-bottom: 0;
    }
  }
}
.current-themes__tag {
  font-size: 12px;
  line-height: 12px;
  text-decoration: none;
  color: #205F58;
  background-color: #fff;
  padding: 7px 10px;
  float: left;
  margin-right: 20px;
  position: relative;
}
.current-themes__tag:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  right: -8px;
  border-style: solid;
  border-width: 13px 0 13px 8px;
  border-color: transparent transparent transparent #fff;
}
.current-themes__tag:before {
  content: "";
  width: 4px;
  height: 4px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background-color: #205F58;
  border-radius: 2px;
}

.contact-splash {
  padding: 40px;
  background-color: #FFD600;
  margin-bottom: 40px;
}
@media only screen and (max-width: 500px) {
  .contact-splash {
    padding: 20px;
  }
}
.contact-splash .icon,
.contact-splash img {
  display: inline-block;
  margin-right: 40px;
  margin-top: 5px;
}
@media only screen and (max-width: 500px) {
  .contact-splash .icon,
  .contact-splash img {
    margin-top: 0;
    margin-bottom: 20px;
  }
}
.contact-splash p {
  font-size: 32px;
  line-height: 32px;
  color: #000;
  display: inline-block;
}
@media only screen and (max-width: 500px) {
  .contact-splash p {
    font-size: 24px;
    line-height: 36px;
  }
}
.contact-splash a {
  text-decoration: none;
  color: #000;
  text-decoration: underline;
}
.contact-splash a:hover {
  text-decoration: none;
}

.common-questions {
  margin-bottom: 40px;
  background-color: #fff;
}
.common-questions__category {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 40px;
}
.common-questions li {
  margin-bottom: 20px;
}
.common-questions__header a {
  color: inherit;
  text-decoration: none;
}
.common-questions__header {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 40px;
  display: inline-block;
  color: #1C2024;
  text-decoration: underline;
}
.common-questions__header:hover {
  text-decoration: none;
}
.common-questions__collapsible {
  list-style: none;
}
.common-questions__collapsible-header {
  border-bottom: 4px solid #1C2024;
  padding-bottom: 20px;
  position: relative;
}
.common-questions__collapsible-header h3 {
  font-size: 18px;
  line-height: 24px;
  padding-right: 28px;
  cursor: pointer;
  color: #1C2024;
}
.common-questions__collapsible-header h3:hover {
  opacity: 0.6;
}
.common-questions__collapsible-header h3:hover:after, .common-questions__collapsible-header h3:hover:before {
  opacity: 0.6;
}
.common-questions__collapsible-header h3:after {
  content: "";
  width: 18px;
  height: 18px;
  background-image: url("../img/icon-expand.svg");
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: center center;
  position: absolute;
  right: 0;
  top: 3px;
  transition: opacity 0.2s ease-in-out;
}
.common-questions__collapsible-header h3:before {
  content: "";
  background-image: url("../img/icon-minimize.svg");
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: center center;
  position: absolute;
  right: 0;
  top: 3px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.common-questions__collapsible-header--visible h3:after {
  opacity: 0 !important;
}
.common-questions__collapsible-header--visible h3:before {
  opacity: 1;
}
.common-questions__collapsible-header--visible .common-questions__collapsible-body {
  max-height: 500px;
}
.common-questions__collapsible-body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
}
.common-questions__collapsible-body__inner {
  padding: 20px 40px 0px;
}
@media only screen and (max-width: 500px) {
  .common-questions__collapsible-body__inner {
    padding: 20px 0;
  }
}
.common-questions__collapsible-body p {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 20px;
  color: #1C2024;
}
.common-questions__collapsible-body p:last-child {
  margin-bottom: 0;
}
.common-questions__collapsible-body a {
  color: #1C2024;
}

.filterbox-holder {
  border: 1px solid #EBEBEB;
  padding: 20px;
}
@media only screen and (max-width: 800px) {
  .filterbox-holder {
    display: none;
  }
}
@media only screen and (min-width: 800px) {
  .filterbox-holder--mobile {
    display: none;
  }
}
@media only screen and (max-width: 800px) {
  .filterbox-holder--mobile {
    margin-bottom: 30px;
  }
  .filterbox-holder--mobile.filterbox-holder--visible {
    display: block;
  }
}

.filterbox {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 2px solid #EBEBEB;
}
.filterbox:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
@media only screen and (max-width: 800px) {
  .filterbox__button {
    width: 25%;
    text-align: center;
  }
}
@media only screen and (max-width: 500px) {
  .filterbox__button {
    width: 100%;
  }
}
.filterbox__title {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1px;
  margin-bottom: 15px;
}
.filterbox__reset {
  float: right;
}
.filterbox__row {
  display: block;
  font-size: 12px;
  line-height: 18px;
  user-select: none;
  cursor: pointer;
  position: relative;
  padding-left: 28px;
  margin-bottom: 15px;
  padding-right: 40px;
}
.filterbox__row:hover input ~ .filterbox__checkmark {
  background-image: url("../img/icon-checkmark.svg");
  background-size: 12px 10px;
  background-repeat: no-repeat;
  background-position: center center;
}
.filterbox__row input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.filterbox__row input:checked ~ .filterbox__checkmark:after {
  display: block;
}
.filterbox__row .filterbox__checkmark:after {
  width: 12px;
  height: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-image: url("../img/icon-checkmark.svg");
  background-size: 12px 10px;
  background-repeat: no-repeat;
  background-position: center center;
}
.filterbox__no-match {
  display: block;
  font-size: 12px;
  line-height: 18px;
  user-select: none;
  cursor: pointer;
  position: relative;
}
.filterbox__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #1C2024;
}
.filterbox__checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.filterbox__more {
  display: none;
}
.filterbox__more--visible {
  display: block;
}
.filterbox__count {
  color: #908684;
  background: #e6e4df;
  font-family: "ABC Monument Grotesk";
  font-weight: 600;
  font-style: normal;
  font-size: 10px;
  line-height: 18px;
  padding: 0px 8px;
  border-radius: 11px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.filterbox__input {
  margin-right: 10px;
}
.filterbox__show-more {
  font-size: 12px;
  line-height: 28px;
  color: #1C2024;
  text-decoration: none;
  position: relative;
  padding-left: 30px;
}
.filterbox__show-more:hover {
  text-decoration: underline;
}
.filterbox__show-more:before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background-image: url("../img/icon-expand-filter.svg");
  background-repeat: no-repeat;
}
.filterbox__freetext {
  width: 100%;
  margin-bottom: 15px;
  font-size: 90%;
  padding: 2px;
  border: 1px solid #EBEBEB;
}

.search {
  margin-bottom: 20px;
  position: relative;
}
.search__input {
  width: 100%;
  background-color: #F4F4F4;
  border: none;
  padding: 21px 66px 21px 24px;
  outline: none;
  font-size: 18px;
  line-height: 24px;
  border-bottom: 4px solid rgba(28, 32, 36, 0.2);
  transition: border-bottom 0.2s ease-in-out;
  box-sizing: border-box !important;
}
.search__input:focus {
  border-bottom: 4px solid rgb(28, 32, 36);
}
.search__input::placeholder {
  color: #C7C7C7;
  font-size: 18px;
  line-height: 24px;
}
.search__input-label {
  position: relative;
  display: inline-block;
  width: 100%;
}
.search__submit {
  width: 26px;
  height: 26px;
  background-image: url("../img/icon-magnifier.svg");
  background-repeat: no-repeat;
  position: absolute;
  top: 20px;
  right: 20px;
  appearance: none;
  border: none;
  background-color: transparent;
  outline: none;
}
@media only screen and (max-width: 1380px) {
  .search .col-6 {
    width: 100%;
  }
}

.sort-results {
  margin-top: 33px;
}
.sort-results--nmt {
  margin-top: 0;
}
.sort-results__hits {
  font-size: 14px;
  line-height: 36px;
  margin-bottom: 20px;
  display: block;
}
.sort-results__search-all-publishers {
  font-size: 14px;
  line-height: 14px;
  display: block;
  margin-bottom: 20px;
}
.sort-results__search-all-publishers a {
  color: #1C2024;
  transition: opacity 0.2s ease-in-out;
}
.sort-results__search-all-publishers a:hover {
  opacity: 0.6;
}
.sort-results__sorting {
  float: right;
  margin-right: 40px;
  line-height: 36px;
  font-size: 14px;
}
.sort-results__sorting select {
  margin-left: 10px;
  border: 1px solid #1C2024;
  border-radius: 0;
  background-color: #fff;
  appearance: none;
  font-size: 14px;
  line-height: 28px;
  padding: 3px 75px 3px 12px;
  background-image: url("../img/icon-chevron-down.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
  outline: none;
}
@media only screen and (max-width: 410px) {
  .sort-results__sorting select {
    margin-left: 0;
  }
}
@media only screen and (max-width: 1380px) {
  .sort-results__sorting {
    float: left;
  }
}
.sort-results__views {
  float: right;
}
@media only screen and (max-width: 500px) {
  .sort-results__views {
    display: none;
  }
}
.sort-results__views__view {
  width: 39px;
  height: 36px;
  float: left;
  margin-left: 10px;
  background-size: 39px 36px;
  background-repeat: no-repeat;
  opacity: 0.3;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
}
.sort-results__views__view:hover {
  opacity: 1;
}
.sort-results__views__view--active {
  opacity: 1;
}
.sort-results__views__view--normal {
  background-image: url("../img/icon-normal-view.svg");
}
.sort-results__views__view--listing {
  background-image: url("../img/icon-listing-view.svg");
}
.sort-results__views__view:first-child {
  margin-left: 0;
}
.sort-results__filter-button {
  margin-bottom: 30px;
  display: none;
}
@media only screen and (max-width: 800px) {
  .sort-results__filter-button {
    display: block;
  }
}

.search-results-wrapper {
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}
.search-results-wrapper--loading {
  opacity: 0;
}
.search-results-wrapper.mt40 {
  margin-top: 40px;
}
.search-results-wrapper.mb40 {
  margin-bottom: 40px;
}

@supports (display: grid) {
  .search-results {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 40px;
  }
  @media only screen and (max-width: 1024px) {
    .search-results {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
  @media only screen and (max-width: 500px) {
    .search-results {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-column-gap: 10px;
    }
  }
  @media only screen and (max-width: 320px) {
    .search-results {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}
@supports (display: grid) {
  .search-results--authors {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
.search-results__item {
  color: inherit;
  text-decoration: none;
}
@supports not (display: grid) {
  .search-results__item {
    margin-bottom: 40px;
  }
}
@supports (display: grid) {
  .search-results__item {
    width: 100%;
    margin-right: 0 !important;
  }
}
.search-results__link {
  color: initial;
}
.search-results__author_link {
  color: initial;
}
.search-results__author_links_popup {
  display: none;
}
.search-results__author_links_popup.active {
  display: block;
}
.search-results__title {
  text-decoration: underline;
  font-size: 16px;
  line-height: 24px;
}
@media only screen and (max-width: 500px) {
  .search-results__title {
    font-size: 14px;
    line-height: 22px;
  }
}
.search-results__book-cover-wrap {
  margin-bottom: 20px;
  position: relative;
  height: 285px;
  overflow: hidden;
}
@media only screen and (max-width: 500px) {
  .search-results__book-cover-wrap {
    height: 145px;
  }
}
@media only screen and (max-width: 320px) {
  .search-results__book-cover-wrap {
    height: 200px;
  }
}
.search-results__cover {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
}
.search-results__author-name {
  font-size: 16px;
  line-height: 24px;
  color: #A9A9A9;
}
@media only screen and (max-width: 500px) {
  .search-results__author-name {
    font-size: 14px;
    line-height: 22px;
  }
}
.search-results__initials {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 60px;
  line-height: 60px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
}

.search-active-filters {
  list-style-type: none;
  display: block;
}
.search-active-filters li {
  font-size: 14px;
  display: inline-block;
  margin: 0 10px 10px 0;
  padding: 6px 8px;
  background-color: #F4F4F4;
  border-radius: 5px;
}
.search-active-filters a {
  text-decoration: none;
  color: #1C2024;
}
.search-active-filters a img {
  height: 10px;
  vertical-align: baseline;
  margin-left: 2px;
}

.book {
  max-width: 1360px;
  width: 100%;
  margin: 0 auto;
  background-color: #F4F4F4;
  padding: 40px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 500px) {
  .book {
    padding: 20px;
  }
}
@supports (display: grid) {
  .book--is-desktop {
    display: grid;
    grid-template-columns: 29.6875% 65.625%;
    grid-column-gap: 60px;
  }
  @media only screen and (max-width: 500px) {
    .book--is-desktop {
      grid-template-columns: 100%;
    }
  }
}
@media only screen and (max-width: 800px) {
  .book--is-desktop {
    display: none;
  }
}
.book--is-mobile {
  display: none;
}
@media only screen and (max-width: 800px) {
  @supports (display: grid) {
    .book--is-mobile {
      display: grid;
      grid-template-columns: 1;
      grid-column-gap: 30px;
    }
    @media only screen and (max-width: 800px) and (max-width: 500px) {
      .book--is-mobile {
        grid-template-columns: 100%;
      }
    }
  }
}
.book__cover {
  text-align: center;
  padding: 40px;
  margin-bottom: 40px;
  background-color: #fff;
}
@media only screen and (max-width: 500px) {
  .book__cover {
    padding: 20px;
    margin-bottom: 30px;
  }
}
.book__cover .caption {
  display: none;
}
.book__cover img {
  max-width: 100%;
  height: auto;
}
.book__cover a {
  position: relative;
}
.book__cover a:after {
  content: "";
  width: 33px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #fff;
  background-image: url("../img/icon-magnifier.svg");
  background-size: 21px 21px;
  background-position: center center;
  background-repeat: no-repeat;
}
.book__sidebar-title {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 500px) {
  .book__sidebar-title {
    font-size: 16px;
    line-height: 28px;
  }
}
.book__sidebar-section {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #c7c7c7;
}
@media only screen and (max-width: 500px) {
  .book__sidebar-section {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}
.book__use-photos {
  font-size: 16px;
  line-height: 24px;
  color: #1C2024;
  margin-bottom: 40px;
  display: inline-block;
}
@media only screen and (max-width: 500px) {
  .book__use-photos {
    margin-bottom: 20px;
  }
}
.book__download-covers ul {
  padding-left: 20px;
}
.book__download-covers a {
  color: #1C2024;
  line-height: 24px;
  transition: opacity 0.2s ease-in-out;
}
.book__download-covers a:hover {
  opacity: 0.6;
}
@supports (display: grid) {
  .book__download-images .grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
  @media only screen and (max-width: 500px) {
    .book__download-images .grid {
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }
  }
}
.book__download-images .caption {
  display: none;
}
.book__download-images__image {
  position: relative;
}
.book__download-images__image img {
  width: 100%;
  height: auto;
}
.book__download-images__image:after {
  content: "";
  width: 22px;
  height: 22px;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #fff;
  background-image: url("../img/icon-magnifier.svg");
  background-size: 14px 14px;
  background-position: center center;
  background-repeat: no-repeat;
}
.book__content__intro {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #c7c7c7;
}
@media only screen and (max-width: 500px) {
  .book__content__intro {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}
.book__content__intro h1 {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 60px;
  line-height: 66px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 500px) {
  .book__content__intro h1 {
    font-size: 34px;
    line-height: 42px;
  }
}
.book__content__intro .subtitle {
  display: block;
  margin-top: -5px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
}
.book__content__intro__author {
  font-size: 24px;
  line-height: 28px;
  color: #1C2024;
  margin-top: 10px;
}
.book__content__intro__author a, .book__content__intro__author p {
  color: inherit;
  display: inline-block;
}
.book__content__intro__author .author-label {
  font-weight: normal;
}
@media only screen and (max-width: 500px) {
  .book__content__intro__author {
    margin-bottom: 0;
  }
}
.book__content__intro .button {
  background-image: url("../img/icon-sound.svg");
  background-repeat: no-repeat;
  background-position: center left 30px;
  padding: 12px 31px 12px 67px;
}
.book__content__intro .button--buy {
  padding: 12px 31px;
  background: #FFD600;
  margin-top: 30px;
  display: inline-block;
}
.book__content__intro .button--buy:hover {
  text-decoration: none;
}
.book__content__example {
  padding-bottom: 40px;
  margin-bottom: 20px;
  border-bottom: 1px solid #c7c7c7;
}
.book__content__example .button .site-menu__login__icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}
@media only screen and (max-width: 500px) {
  .book__content__example {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}
.book__content__example h2 {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 26px;
}
@media only screen and (max-width: 500px) {
  .book__content__example h2 {
    font-size: 16px;
    line-height: 28px;
  }
}
.book__content__example p {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 40px;
}
.book__content__example select, .book__content__example input[type=number] {
  appearance: none;
  border-radius: 0;
  font-size: 16px;
  line-height: 28px;
  border: 1px solid #1C2024;
  margin-right: 20px;
  outline: none;
  background-color: #fff;
}
@media only screen and (max-width: 500px) {
  .book__content__example select, .book__content__example input[type=number] {
    margin-bottom: 20px;
  }
}
.book__content__example select {
  max-width: 395px;
  width: 100%;
  background-image: url("../img/icon-select.svg");
  background-repeat: no-repeat;
  background-position: right 20px center;
  padding: 10px 20px;
}
.book__content__example input[type=number] {
  max-width: 80px;
  width: 100%;
  padding: 10px;
}
.book__content__audio_sample {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #c7c7c7;
}
.book__content__minprice {
  margin-top: 25px;
}
.book__order__error {
  margin-top: 10px;
}
.book__content__facts {
  margin-bottom: 20px;
}
@media only screen and (max-width: 500px) {
  .book__content__facts {
    margin-bottom: 0;
  }
}
@supports (display: grid) {
  .book__content__facts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;
  }
}
@media only screen and (max-width: 800px) {
  @supports (display: grid) {
    .book__content__facts {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 40px;
    }
  }
}
.book__content__facts__facts-col {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #c7c7c7;
  font-style: normal;
}
.book__content__facts__facts-col p {
  font-size: 20px;
  line-height: 26px;
}
@media only screen and (max-width: 500px) {
  .book__content__facts__facts-col p {
    font-size: 16px;
    line-height: 28px;
  }
}
.book__content__facts__facts-col strong {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
}
.book__content__facts__facts-col a {
  color: #1C2024;
}
.book__content__excerpt {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #c7c7c7;
}
@media only screen and (max-width: 500px) {
  .book__content__excerpt {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}
.book__content__excerpt p {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 500px) {
  .book__content__excerpt p {
    margin-bottom: 20px;
  }
}
.book__content__excerpt p:last-child {
  margin-bottom: 0;
}
.book__content__recension {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #c7c7c7;
}
.book__content__recension p {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 20px;
}
.book__content__recension a {
  color: #1C2024;
}
.book__content__quote {
  height: auto;
  transform: scaleY(1);
}
.book__content__quote.more-quotes {
  height: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.4s ease;
}
.open .book__content__quote {
  height: 0;
  transform: scaleY(0);
}
.open .book__content__quote.more-quotes {
  height: auto;
  transform: scaleY(1);
}
.book__content__details {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #c7c7c7;
  font-style: normal;
}
.book__content__details h2 {
  font-size: 20px;
  line-height: 28px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  margin-bottom: 20px;
}
@media only screen and (max-width: 500px) {
  .book__content__details h2 {
    font-size: 16px;
  }
}
.book__content__details p {
  font-size: 14px;
  line-height: 24px;
}
.book__content__details strong {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
}
.book__content__details a {
  color: #1C2024;
}
.book__content__books {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #c7c7c7;
}
.book__content__books h2 {
  font-size: 20px;
  line-height: 28px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  margin-bottom: 20px;
}
@media only screen and (max-width: 500px) {
  .book__content__books h2 {
    font-size: 16px;
  }
}
.book__content__books__book img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.book__content__books__book a {
  color: #1C2024;
  font-size: 16px;
  line-height: 24px;
}
.book__content__books__book p {
  font-size: 16px;
  line-height: 24px;
}
@supports (display: grid) {
  .book__content__books .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 40px;
    row-gap: 40px;
    align-items: end;
  }
  @media only screen and (max-width: 500px) {
    .book__content__books .grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
.book__content__news__section-title {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 500px) {
  .book__content__news__section-title {
    font-size: 16px;
    margin-bottom: 20px;
  }
}
.book__content__news__col span {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1px;
  margin-bottom: 10px;
  display: block;
}
.book__content__news__col h2 {
  color: #1C2024;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
}
.book__content__news__col a {
  text-decoration: none;
  color: #1C2024;
}
.book__content__news__col a:hover {
  text-decoration: underline;
}
@supports (display: grid) {
  .book__content__news .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;
    row-gap: 40px;
    align-items: end;
  }
  @media only screen and (max-width: 500px) {
    .book__content__news .grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.author {
  max-width: 1360px;
  width: 100%;
  margin: 0 auto;
  background-color: #F4F4F4;
  padding: 40px;
  margin-bottom: 40px;
  padding-bottom: 100px;
}
@media only screen and (max-width: 500px) {
  .author {
    padding: 20px;
  }
}
@supports (display: grid) {
  .author--is-desktop {
    display: grid;
    grid-template-columns: 29.6875% 65.625%;
    grid-column-gap: 60px;
  }
  @media only screen and (max-width: 500px) {
    .author--is-desktop {
      grid-template-columns: 100%;
    }
  }
}
@media only screen and (max-width: 800px) {
  .author--is-desktop {
    display: none;
  }
}
.author--is-mobile {
  display: none;
}
@media only screen and (max-width: 800px) {
  .author--is-mobile {
    display: block;
  }
}
.author--is-mobile .author__cover__photographer {
  margin-top: 10px;
}
.author__cover {
  padding: 40px;
  margin-bottom: 10px;
  background-color: #fff;
}
.author__cover .caption {
  display: none;
}
@media only screen and (max-width: 500px) {
  .author__cover {
    padding: 20px;
    margin-bottom: 20px;
  }
}
.author__cover img {
  width: 100%;
  height: auto;
}
.author__cover a {
  position: relative;
}
.author__cover a:after {
  content: "";
  width: 33px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #fff;
  background-image: url("../img/icon-magnifier.svg");
  background-size: 21px 21px;
  background-position: center center;
  background-repeat: no-repeat;
}
.author__cover__photographer {
  font-size: 12px;
  line-height: 12px;
  display: inline-block;
}
.author__sidebar-title {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
}
.author__download-images {
  width: 100%;
  margin-top: 30px;
}
@media only screen and (max-width: 800px) {
  .author__download-images {
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid #c7c7c7;
  }
}
.author__download-images .caption {
  display: none;
}
@supports (display: grid) {
  .author__download-images .grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
  @media only screen and (max-width: 500px) {
    .author__download-images .grid {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 10px;
    }
  }
}
.author__download-images__image {
  position: relative;
}
.author__download-images__image:after {
  content: "";
  width: 22px;
  height: 22px;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #fff;
  background-image: url("../img/icon-magnifier.svg");
  background-size: 14px 14px;
  background-position: center center;
  background-repeat: no-repeat;
}
.author__download-images__image img {
  width: 100%;
  height: auto;
}
.author__content__intro {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #c7c7c7;
}
.author__content__intro h1 {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 60px;
  line-height: 70px;
  margin-bottom: 24px;
}
.author__content__intro .sub-header {
  margin-top: -18px;
  font-weight: 700;
  font-style: italic;
  margin-bottom: 20px;
}
.author__content__intro p {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 15px;
}
.author__content__intro p:last-child {
  margin-bottom: 0;
}
.author__content__books {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #c7c7c7;
}
.author__content__books h2 {
  font-size: 20px;
  line-height: 28px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  margin-bottom: 20px;
}
.author__content__books__book img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.author__content__books__book a {
  color: #1C2024;
  font-size: 16px;
  line-height: 24px;
}
.author__content__books__book p {
  font-size: 16px;
  line-height: 24px;
}
@supports (display: grid) {
  .author__content__books .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 40px;
    row-gap: 40px;
    align-items: end;
  }
  @media only screen and (max-width: 500px) {
    .author__content__books .grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
.author__content__news__section-title {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 40px;
}
.author__content__news__col span {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1px;
  margin-bottom: 10px;
  display: block;
}
.author__content__news__col h2 {
  color: #1C2024;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
}
.author__content__news__col a {
  text-decoration: none;
  color: #1C2024;
}
.author__content__news__col a:hover {
  text-decoration: underline;
}
@supports (display: grid) {
  .author__content__news .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;
    row-gap: 40px;
    align-items: end;
  }
  @media only screen and (max-width: 500px) {
    .author__content__news .grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

@supports (display: grid) {
  .theme-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 40px;
    width: 100%;
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 800px) {
    .theme-cards {
      grid-column-gap: 20px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media only screen and (max-width: 500px) {
    .theme-cards {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.theme-card {
  text-decoration: none;
  color: inherit;
}
@supports not (display: grid) {
  .theme-card {
    margin-bottom: 60px;
  }
}
@supports (display: grid) {
  .theme-card {
    width: 100%;
  }
}
.theme-card__image {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}
.theme-card__title {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
  line-height: 42px;
  color: #1C2024;
  margin-bottom: 20px;
}
.theme-card p {
  font-size: 16px;
  line-height: 24px;
}
.theme-card:hover .theme-card__title {
  text-decoration: underline;
}

.theme-header {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #EBEBEB;
}
.theme-header--hidden {
  display: none;
}
.theme-header--mb {
  margin-bottom: 40px;
}
.theme-header h1, .theme-header h2 {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 34px;
  line-height: 42px;
}
@media only screen and (max-width: 500px) {
  .theme-header h1, .theme-header h2 {
    font-size: 24px;
    line-height: 28px;
  }
}

.pagination {
  text-align: center;
  margin: 100px 0 80px;
}
@media only screen and (max-width: 500px) {
  .pagination {
    margin: 60px 0 40px;
  }
}
.pagination__wrap {
  display: inline-block;
}
.pagination .inactive {
  opacity: 0.2;
  pointer-events: none;
}
.pagination__first {
  width: 30px;
  height: 21px;
  background-image: url("../img/pagination-icon-first.svg");
  background-size: 30px 21px;
  float: left;
}
.pagination__prev {
  width: 19px;
  height: 21px;
  background-image: url("../img/pagination-icon-prev.svg");
  background-size: 19px 21px;
  float: left;
  margin-left: 30px;
}
.pagination__next {
  width: 19px;
  height: 21px;
  background-image: url("../img/pagination-icon-next.svg");
  background-size: 19px 21px;
  float: left;
  margin-right: 30px;
}
.pagination__last {
  width: 30px;
  height: 21px;
  background-image: url("../img/pagination-icon-last.svg");
  background-size: 30px 21px;
  float: left;
}
.pagination__count {
  font-size: 16px;
  line-height: 21px;
  margin-left: 30px;
  margin-right: 30px;
  float: left;
}

.contact h1 {
  font-size: 34px;
  line-height: 42px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  margin-bottom: 20px;
}
.contact__intro {
  max-width: 830px;
  width: 100%;
  padding-bottom: 40px;
}
.contact__intro p {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 15px;
}
@media only screen and (max-width: 500px) {
  .contact__intro p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
  }
}
.contact__intro ul {
  margin-bottom: 30px;
  list-style: none;
}
.contact__intro ul li {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 15px;
  position: relative;
  padding-left: 20px;
}
@media only screen and (max-width: 500px) {
  .contact__intro ul li {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px;
    padding-left: 30px;
  }
}
.contact__intro ul li:before {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  top: 11px;
  left: 0;
  background-color: #000;
  border-radius: 12px;
}
.contact__intro a {
  color: #1C2024;
  font-size: 16px;
  line-height: 28px;
}
.contact__intro a:hover {
  text-decoration: none;
}
.contact__box {
  width: 100%;
  display: inline-block;
  background-color: #FFD600;
  padding: 70px;
  text-align: center;
  margin-bottom: 60px;
}
@media only screen and (max-width: 500px) {
  .contact__box {
    padding: 40px 20px;
  }
}
@supports not (display: grid) {
  .contact__box {
    padding: 70px 40px 30px;
  }
}
@supports (display: grid) {
  .contact__box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 40px;
    width: 100%;
    grid-row-end: 6;
  }
  @media only screen and (max-width: 1024px) {
    .contact__box {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media only screen and (max-width: 500px) {
    .contact__box {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
@supports not (display: grid) {
  .contact__box__col {
    margin-bottom: 40px;
  }
}
@supports (display: grid) {
  .contact__box__col {
    width: 100%;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
  }
}
.contact__box__col h2 {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  color: black;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 10px;
}
.contact__box__col p {
  color: black;
  font-size: 18px;
  line-height: 30px;
}
.contact__box__col a {
  color: black;
  font-size: 18px;
  line-height: 30px;
}
.contact__box__col ul {
  list-style: none;
}
.contact__box__col ul li {
  display: inline-block;
  margin-right: 10px;
}
.contact__box__col--some {
  grid-column-end: -1;
}
.contact__section {
  margin-bottom: 60px;
  padding-bottom: 40px;
  border-bottom: 1px solid #EBEBEB;
}
@supports (display: grid) {
  .contact__section .row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 80px;
    grid-row-gap: 40px;
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    .contact__section .row {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media only screen and (max-width: 500px) {
    .contact__section .row {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
.contact__section--last {
  border-bottom: none;
  padding-bottom: 0;
}
.contact__section__title {
  text-align: center;
  font-size: 24px;
  line-height: 28px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  margin-bottom: 60px;
}
.contact__section__col {
  text-align: center;
}
@supports not (display: grid) {
  .contact__section__col {
    margin-bottom: 40px;
  }
}
@supports (display: grid) {
  .contact__section__col {
    width: 100%;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
  }
}
.contact__section__col img {
  max-width: 192px;
  width: 100%;
  border-radius: 192px;
  margin-bottom: 30px;
  filter: grayscale(100%);
}
.contact__section__col img.no-filter {
  filter: none;
}
.contact__section__col h2 {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
}
.contact__section__col h2 a {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
  transition: opacity 0.2s ease-in-out;
}
.contact__section__col h2 a:hover {
  opacity: 0.6;
}
.contact__section__col p {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 20px;
}
.contact__section__col a {
  font-size: 16px;
  line-height: 26px;
  color: #1C2024;
}

.button {
  color: black;
  text-decoration: none;
  background-color: #FFD600;
  padding: 12px 31px;
  border-radius: 8px;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  border: none;
  appearance: none;
  transition: opacity 0.2s ease-in-out;
  outline: none;
}
.button:hover {
  text-decoration: underline;
}
.button--black {
  background-color: #1C2024;
  color: #fff;
}
.button--white {
  background-color: #fff;
  border: 1px solid #1C2024;
  color: #1C2024;
}
.button--green {
  color: #000000;
  background-color: #bcd200;
}
.button--round {
  border-radius: 100px;
}
.button--full-width {
  width: 100%;
  text-align: center;
}
.button--inactive {
  opacity: 0.6;
  pointer-events: none;
}
.button--add-to-cart {
  position: relative;
  background-image: url("../img/icon-bag.svg");
  background-repeat: no-repeat;
  background-position: center left 30px;
  padding: 14px 31px 10px 67px;
  outline: none;
  transition: background-color 0.2s ease-in-out;
  overflow: hidden;
}
.button--add-to-cart em {
  font-style: normal;
  transition: color 0.2s ease-in-out;
  line-height: 26px;
}
.button--add-to-cart svg {
  /* this is the check icon */
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  /* move the icon on the right - outside the button */
  transform: translateX(50%) translateY(-50%);
  transition: stroke-dashoffset 0.2s ease-in-out;
  stroke-dashoffset: 19.79;
}
.button--add-to-cart.adding {
  background-image: none;
}
.button--add-to-cart.adding em {
  color: transparent;
  transform: translateX(-100%);
}
.button--add-to-cart.adding svg {
  transform: translateX(-50%) translateY(-50%);
  stroke-dashoffset: 0;
}
.button--spinner {
  position: relative;
  overflow: hidden;
}
.button--spinner em {
  font-style: normal;
  transition: color 0.2s ease-in-out;
  line-height: 26px;
}
.button--spinner svg {
  /* this is the check icon */
  width: 64px;
  height: 64px;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  /* move the icon on the right - outside the button */
  transform: translateX(50%) translateY(-50%);
}
.button--spinner.spin {
  pointer-events: none;
}
.button--spinner.spin em {
  color: transparent;
  transform: translateX(-100%);
}
.button--spinner.spin svg {
  transform: translateX(-50%) translateY(-50%);
  stroke-dashoffset: 0;
}

.article {
  max-width: 830px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.article__print__title {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1100px) {
  .article__print__title {
    margin-bottom: 10px;
  }
}
.article__print ul {
  list-style: none;
}
.article__print__icon {
  display: inline-block;
  width: 46px;
  height: 46px;
  background-size: 36px 36px;
  background-position: center center;
  border: 2px solid black;
  border-radius: 23px;
  background-image: url("../img/icon-print-black.svg");
}
@media only screen and (max-width: 500px) {
  .article__print__icon {
    width: 32px;
    height: 32px;
    background-size: 32px 32px;
  }
}
.article__share {
  position: absolute;
  right: -117px;
  top: 80px;
  z-index: 2;
}
@media only screen and (max-width: 1100px) {
  .article__share {
    position: static;
    max-width: 750px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}
.article__share ul {
  list-style: none;
}
.article__share__title {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1100px) {
  .article__share__title {
    margin-bottom: 10px;
  }
}
.article__share li {
  margin-bottom: 10px;
  height: 46px;
  width: 46px;
}
@media only screen and (max-width: 1100px) {
  .article__share li {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 10px;
  }
}
@media only screen and (max-width: 500px) {
  .article__share li {
    width: 32px;
    height: 32px;
  }
}
.article__share__share-btn {
  display: inline-block;
  width: 46px;
  height: 46px;
  background-size: 46px 46px;
  background-position: center center;
}
@media only screen and (max-width: 500px) {
  .article__share__share-btn {
    width: 32px;
    height: 32px;
    background-size: 32px 32px;
  }
}
.article__share__facebook {
  background-image: url("../img/icon-facebook-black.svg");
}
.article__share__facebook:hover {
  background-image: url("../img/icon-facebook-white.svg");
}
.article__share__twitter {
  background-image: url("../img/icon-twitter-black.svg");
}
.article__share__twitter:hover {
  background-image: url("../img/icon-twitter-white.svg");
}
.article__share__linkedin {
  background-image: url("../img/icon-linkedin-black.svg");
}
.article__share__linkedin:hover {
  background-image: url("../img/icon-linkedin-white.svg");
}
.article__breadcrumbs {
  border-bottom: 1px solid #EBEBEB;
  margin-bottom: 20px;
}
.article__description {
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
}
.article__description p {
  font-size: 16px;
  line-height: 30px;
}
.article__description strong {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
}
.article__description span {
  font-size: 12px;
  line-height: 24px;
}
.article .photo-cred {
  position: absolute;
  top: 0px;
  left: 0px;
  color: #fff;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 5px 10px;
}
.article__image {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 40px;
  position: relative;
}
@media only screen and (max-width: 500px) {
  .article__image {
    margin-bottom: 20px;
  }
}
.article__image img {
  width: 100%;
  height: auto;
}
.article__image p {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: auto;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  font-size: 16px;
  line-height: 26px;
}
@media only screen and (max-width: 500px) {
  .article__image p {
    bottom: 20px;
    left: 20px;
    right: 20px;
    font-size: 12px;
    line-height: 16px;
    padding: 10px;
  }
}
.article__content {
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 20px;
  border-bottom: 1px solid #EBEBEB;
  margin-bottom: 60px;
}
.article__content .wp-caption-text {
  font-size: 12px;
  margin-bottom: 0;
}
.article__content .alignleft {
  float: left;
  margin: 9px 30px 0px 0;
}
@media only screen and (max-width: 500px) {
  .article__content .alignleft {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
  }
}
.article__content .alignright {
  float: right;
  margin: 9px 0 0 30px;
}
@media only screen and (max-width: 500px) {
  .article__content .alignright {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
  }
}
.article__content img.alignright {
  float: right;
  margin: 9px 0 30px 30px;
}
@media only screen and (max-width: 500px) {
  .article__content img.alignright {
    width: 100%;
    height: auto;
  }
}
.article__content img.alignleft {
  float: left;
  margin: 9px 30px 30px 0;
}
@media only screen and (max-width: 500px) {
  .article__content img.alignleft {
    width: 100%;
    height: auto;
  }
}
.article__content img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 500px) {
  .article__content img.aligncenter {
    width: 100%;
    height: auto;
  }
}
.article__content a img.alignright {
  float: right;
  margin: 9px 0 30px 30px;
}
@media only screen and (max-width: 500px) {
  .article__content a img.alignright {
    width: 100%;
    height: auto;
  }
}
.article__content a img.alignleft {
  float: left;
  margin: 9px 30px 30px 0;
}
@media only screen and (max-width: 500px) {
  .article__content a img.alignleft {
    width: 100%;
    height: auto;
  }
}
.article__content a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 500px) {
  .article__content a img.aligncenter {
    width: 100%;
    height: auto;
  }
}
.article__content h1 {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 50px;
  line-height: 60px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 500px) {
  .article__content h1 {
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 20px;
  }
}
.article__content h2 {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 38px;
  margin-bottom: 20px;
}
.article__content h3 {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 15px;
}
.article__content h4, .article__content h5, .article__content h6 {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
}
.article__content p {
  font-size: 20px;
  line-height: 34px;
  margin-bottom: 15px;
}
@media only screen and (max-width: 500px) {
  .article__content p {
    font-size: 16px;
    line-height: 26px;
  }
}
.article__content blockquote p {
  font-size: 34px;
  line-height: 46px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: italic;
}
.article__content strong {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
}
.article__content a {
  color: #1C2024;
}
.article__content ul {
  margin-bottom: 30px;
  list-style: none;
}
.article__content ul li {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 15px;
  position: relative;
  padding-left: 20px;
}
@media only screen and (max-width: 500px) {
  .article__content ul li {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px;
    padding-left: 30px;
  }
}
.article__content ul li:before {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  top: 11px;
  left: 0;
  background-color: #000;
  border-radius: 12px;
}
.article__content i, .article__content em {
  font-family: "ABC Monument Grotesk";
  font-weight: 400;
  font-style: italic;
}
.article__content ol {
  margin-bottom: 30px;
  padding-left: 20px;
}
@media only screen and (max-width: 500px) {
  .article__content ol {
    padding-left: 15px;
  }
}
.article__content ol li {
  font-size: 20px;
  line-height: 34px;
  margin-bottom: 30px;
  position: relative;
}
@media only screen and (max-width: 500px) {
  .article__content ol li {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px;
  }
}
.article__tags {
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 80px;
}
.article__tags .book-content__tags {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.article__tags h3 {
  font-size: 20px;
  line-height: 34px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  margin-bottom: 20px;
}
.article__tags__tag {
  font-size: 12px;
  line-height: 12px;
  color: #fff;
  background-color: #1C2024;
  padding: 4px 10px;
  text-decoration: none;
  position: relative;
  margin-right: 18px;
  margin-bottom: 10px;
}
.article__tags__tag:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 8px;
  border-color: transparent transparent transparent #1C2024;
  position: absolute;
  top: 0;
  right: -8px;
}
.article__tags__tag:before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: #fff;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 10;
}
.article__tags__tag:hover {
  text-decoration: underline;
}
.article__files {
  max-width: 750px;
  width: 100%;
  margin: 0 auto 60px;
}
.article__files h3 {
  font-family: "freight-sans-pro";
  font-weight: 700;
  font-style: normal;
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 30px;
}
.article__files__file {
  margin-top: 20px;
}
.article__files__file img {
  margin-right: 20px;
}
.article__files__file a {
  text-decoration: none;
  color: #1C2024;
}
.article__files__file .description {
  display: inline-block;
}
.article__files__file .description h4 {
  font-family: "freight-sans-pro";
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  margin-bottom: 3px;
}
.single-news .article__image {
  padding-bottom: 28px;
}
.single-news .article__image .photo-cred {
  top: auto;
  bottom: 0;
  width: 100%;
  background-color: black;
  min-height: 28px;
}

.account-information {
  background-color: #fff;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 40px;
  display: inline-block;
}
@media only screen and (max-width: 500px) {
  .account-information {
    padding: 20px;
  }
}
.account-information a {
  color: #1C2024;
  transition: opacity 0.2s ease-in-out;
}
.account-information a:hover {
  opacity: 0.6;
}
.account-information__bg {
  background-color: #F4F4F4;
  padding: 40px;
  margin-bottom: 80px;
}
@media only screen and (max-width: 500px) {
  .account-information__bg {
    padding: 20px;
    margin-bottom: 40px;
  }
}
.account-information__title {
  font-size: 36px;
  line-height: 42px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  text-align: center;
  margin-bottom: 40px;
}
@media only screen and (max-width: 500px) {
  .account-information__title {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 20px;
  }
}
.account-information section {
  margin-bottom: 40px;
  border-bottom: 1px solid #EBEBEB;
}
.account-information section.first {
  border-top: 1px solid #EBEBEB;
  padding-top: 40px;
}
@media only screen and (max-width: 500px) {
  .account-information section.first {
    padding-top: 20px;
  }
}
@media only screen and (max-width: 500px) {
  .account-information section {
    margin-bottom: 20px;
  }
}
.account-information__section-title {
  font-size: 24px;
  line-height: 28px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  margin-bottom: 40px;
}
@media only screen and (max-width: 500px) {
  .account-information__section-title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
.account-information__section-title--large {
  font-size: 36px;
  line-height: 42px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  margin-bottom: 40px;
}
@media only screen and (max-width: 500px) {
  .account-information__section-title--large {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 20px;
  }
}
.account-information__form-errors, .account-information__form-errors__mirror {
  color: #cc0000;
  padding: 20px 0 0 0;
  display: inline-block;
  width: 100%;
}
.account-information__form-errors__mirror, .account-information__form-errors__mirror__mirror {
  padding: 0 0 20px 0;
}
.account-information__input {
  display: block;
  margin-bottom: 40px;
}
.account-information__input ul {
  list-style: none;
}
.account-information__input li {
  color: #cc0000;
  margin-bottom: 20px;
  width: 100%;
}
.account-information__input--textarea {
  margin-top: 66px;
}
.account-information__input p {
  font-size: 16px;
  line-height: 20px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  margin-bottom: 5px;
}
.account-information__input span {
  font-size: 12px;
  line-height: 20px;
  font-family: "ABC Monument Grotesk";
  font-weight: 600;
  font-style: normal;
  display: block;
  margin-bottom: 10px;
}
.account-information__select {
  display: block;
  margin-bottom: 40px;
}
.account-information__select span {
  font-size: 12px;
  line-height: 20px;
  font-family: "ABC Monument Grotesk";
  font-weight: 600;
  font-style: normal;
  display: block;
  margin-bottom: 10px;
}
.account-information__select p {
  font-size: 16px;
  line-height: 20px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  margin-bottom: 5px;
}
.account-information__select ul {
  list-style: none;
}
.account-information__select li {
  color: #cc0000;
  margin-bottom: 20px;
  width: 100%;
}
.account-information__textarea {
  display: block;
  margin-bottom: 40px;
}
.account-information__textarea ul {
  list-style: none;
}
.account-information__textarea li {
  color: #cc0000;
  margin-bottom: 20px;
  width: 100%;
}
.account-information__textarea span {
  font-size: 12px;
  line-height: 20px;
  font-family: "ABC Monument Grotesk";
  font-weight: 600;
  font-style: normal;
  display: block;
  margin-bottom: 10px;
}
.account-information__textarea p {
  font-size: 16px;
  line-height: 20px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  margin-bottom: 5px;
}
.account-information__textarea textarea {
  width: 100%;
  height: 100px;
  display: block;
  resize: none;
  border: 1px solid #c7c7c7;
  padding: 10px 20px;
  outline: none;
}
.account-information__checkbox {
  display: block;
  margin-bottom: 40px;
  position: relative;
  padding-left: 38px;
}
@media only screen and (max-width: 500px) {
  .account-information__checkbox {
    margin-bottom: 20px;
  }
}
.account-information__checkbox.error {
  color: #cc0000;
}
.account-information__checkbox.error span {
  border: 1px solid #cc0000;
}
.account-information__checkbox a {
  color: #1C2024;
  transition: opacity 0.2s ease-in-out;
}
.account-information__checkbox a:hover {
  opacity: 0.6;
}
.account-information__checkbox--policy-text {
  margin-bottom: 17px;
}
.account-information__checkbox:hover {
  cursor: pointer;
}
.account-information__checkbox span {
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #1C2024;
}
.account-information__checkbox span:after {
  content: "";
  position: absolute;
  display: none;
}
.account-information__checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.account-information__checkbox input:checked ~ span:after {
  display: block;
}
.account-information__checkbox span:after {
  width: 12px;
  height: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-image: url("../img/icon-checkmark.svg");
  background-size: 12px 10px;
  background-repeat: no-repeat;
  background-position: center center;
}
.account-information__policy-text {
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 40px;
  display: block;
}
.account-information__policy-text--nmb {
  margin-bottom: 0;
}
.account-information input[type=text], .account-information input[type=password] {
  width: 100%;
  border: 1px solid #c7c7c7;
  outline: none;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 28px;
}
.account-information select {
  appearance: none;
  width: 100%;
  text-align: center;
  border: none;
  border: 1px solid #1C2024;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 0;
  font-size: 16px;
  line-height: 28px;
  background-image: url("../img/icon-select.svg");
  background-repeat: no-repeat;
  background-position: center right 30px;
}
.account-information .button {
  margin-right: 20px;
}

.login-modal {
  z-index: 5;
  position: fixed;
  transform: translateY(100%);
  padding: 65px 0;
  left: 0;
  top: 0;
  width: 100%;
  overflow-y: auto;
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  pointer-events: none;
  height: 100%;
  box-sizing: border-box;
}
.login-modal__content {
  position: relative;
  padding: 40px 100px;
  max-width: 670px;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
}
@media only screen and (max-width: 500px) {
  .login-modal__content {
    padding: 40px 20px;
  }
}
@media only screen and (max-width: 500px) {
  .login-modal {
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0;
    left: 0;
    top: 0px;
    padding: 0;
    -webkit-overflow-scrolling: touch;
    position: fixed;
  }
}
.login-modal--visible {
  transform: translateY(0%);
  opacity: 1;
  pointer-events: auto;
}
.login-modal__error {
  color: #cc0000;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}
.login-modal__close {
  text-decoration: none;
  position: absolute;
  right: 20px;
  top: 20px;
}
.login-modal__close img {
  float: right;
}
.login-modal__close span {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.86px;
  display: block;
  color: #1C2024;
}
.login-modal__title {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  line-height: 42px;
  font-size: 36px;
  text-align: center;
  margin-bottom: 40px;
}
.login-modal__title img {
  margin-right: 16px;
}
.login-modal__title-text {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 35px;
}
.login-modal__label {
  margin-bottom: 22px;
  display: block;
}
.login-modal__label span {
  display: block;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 10px;
  display: block;
}
.login-modal__label input[type=text], .login-modal__label input[type=password] {
  width: 100%;
  border: none;
  background-color: #F4F4F4;
  height: 44px;
  padding: 0 40px 0 20px;
  outline: none;
  border-bottom: 2px solid rgba(28, 32, 36, 0.2);
  transition: border-bottom 0.2s ease-in-out;
}
.login-modal__label input[type=text]:focus, .login-modal__label input[type=password]:focus {
  border-bottom: 2px solid rgb(28, 32, 36);
}
.login-modal__form {
  margin-bottom: 40px;
}
.login-modal button {
  border: none;
  appearance: none;
  width: 100%;
  font-size: 16px;
  line-height: 26px;
  padding: 12px 0;
  text-align: center;
  background-color: #FFD600;
  color: black;
  outline: none;
  border-radius: 8px;
  transition: opacity 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  text-decoration: none;
}
.login-modal button em {
  font-style: normal;
  transition: color 0.2s ease-in-out;
  line-height: 26px;
}
.login-modal button svg {
  /* this is the check icon */
  width: 64px;
  height: 64px;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  /* move the icon on the right - outside the button */
  transform: translateX(50%) translateY(-50%);
}
.login-modal button.animate {
  pointer-events: none;
}
.login-modal button.animate em {
  color: transparent;
  transform: translateX(-100%);
}
.login-modal button.animate svg {
  transform: translateX(-50%) translateY(-50%);
  stroke-dashoffset: 0;
}
.login-modal button:hover {
  text-decoration: underline;
}
.login-modal__checkbox {
  display: block;
  margin-bottom: 20px;
}
.login-modal__forgotten {
  text-align: center;
  color: #1C2024;
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 20px;
  display: inline-block;
  transition: opacity 0.2s ease-in-out;
}
.login-modal__forgotten:hover {
  opacity: 0.6;
}
.login-modal__account {
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-top: 1px solid #EBEBEB;
  border-bottom: 1px solid #EBEBEB;
}
.login-modal__account h2 {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 20px;
  text-align: center;
}
.login-modal__account a {
  color: #1C2024;
  font-size: 12px;
  line-height: 24px;
  margin-right: 20px;
  transition: opacity 0.2s ease-in-out;
}
.login-modal__account a:hover {
  opacity: 0.6;
}
.login-modal__create-account {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 40px;
  border-top: 1px solid #EBEBEB;
}
.login-modal__create-account h2 {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 20px;
  text-align: center;
}
.login-modal__create-account p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 40px;
}
.login-modal__create-account__button {
  width: 100%;
  display: block;
  font-size: 16px;
  line-height: 26px;
  padding: 12px 0;
  text-align: center;
  outline: none;
  border-radius: 8px;
  border: 1px solid #1C2024;
  color: #1C2024;
  text-decoration: none;
  margin-bottom: 20px;
}
.login-modal__create-account__button:hover {
  text-decoration: underline;
}
.login-modal__create-account__readmore {
  color: #1C2024;
  font-size: 12px;
  line-height: 24px;
  transition: opacity 0.2s ease-in-out;
}
.login-modal__create-account__readmore:hover {
  opacity: 0.6;
}
.login-modal__create-account__already-customer {
  margin-top: 40px;
}
.login-modal .warning-text {
  color: #cc0000;
  margin-bottom: 10px;
}

.modal-shadow {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}
.modal-shadow--visible {
  opacity: 1;
  pointer-events: auto;
}

.my-orders {
  margin-bottom: 40px;
  padding: 40px;
  background-color: #F4F4F4;
}
@media only screen and (max-width: 500px) {
  .my-orders {
    padding: 20px;
  }
}
.my-orders__holder {
  padding: 40px;
  background-color: #fff;
}
@media only screen and (max-width: 500px) {
  .my-orders__holder {
    padding: 20px;
  }
}
.my-orders__title {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  text-align: center;
  font-size: 36px;
  line-height: 42px;
  padding-bottom: 40px;
  margin-bottom: 23px;
  border-bottom: 1px solid #EBEBEB;
}
@media only screen and (max-width: 500px) {
  .my-orders__title {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}
.my-orders__table-header {
  border-bottom: 1px solid #1C2024;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.my-orders__table-header div {
  width: 25%;
}
.my-orders__table-header .align-right {
  text-align: right;
}
.my-orders__table-header p {
  font-family: "ABC Monument Grotesk";
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
}
.my-orders__table-body .align-right {
  text-align: right;
}
.my-orders__table-body p {
  font-family: "ABC Monument Grotesk";
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 28px;
}
.my-orders__table-row {
  padding: 20px 0 15px;
  border-bottom: 1px solid #EBEBEB;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.my-orders__table-row div {
  width: 25%;
}
.my-orders section {
  width: 100%;
  overflow-y: scroll;
}
@media only screen and (max-width: 800px) {
  .my-orders .table-wrap {
    width: 550px;
  }
}

.cart {
  background-color: #F4F4F4;
  padding: 40px;
}
.cart__books {
  background-color: #fff;
  padding: 30px;
}
.cart__books__title {
  font-size: 24px;
  line-height: 28px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  margin-bottom: 30px;
}
.cart__books table {
  width: 100%;
  box-sizing: content-box;
}
.cart__books table thead:after {
  content: "";
  display: block;
  padding-bottom: 10px;
}
.cart__books table th {
  text-align: left;
  font-size: 16px;
  line-height: 24px;
}
.cart__books table td h2 {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
}
.cart__books table td span {
  display: block;
  font-size: 12px;
  line-height: 24px;
}
.cart__total {
  margin-bottom: 40px;
  padding: 30px;
  background-color: #fff;
}
.cart__total h2 {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 30px;
}
.cart__total__row {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #EBEBEB;
}
.cart__total__row p {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
}
.cart__total__row span {
  font-size: 16px;
  line-height: 24px;
  margin-right: 25px;
}
.cart__total .button {
  margin-top: 20px;
  display: block;
}
.cart__records {
  padding: 30px;
  background-color: #fff;
}
.cart__records h2 {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 30px;
}
.cart__records section {
  border-bottom: 1px solid #EBEBEB;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.cart__records span {
  font-family: "ABC Monument Grotesk";
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 24px;
}
.cart__records p {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 10px;
}
.cart__records__options {
  margin-top: 20px;
}
.cart__records__options a {
  color: #1C2024;
  font-size: 12px;
  line-height: 24px;
  margin-right: 20px;
}
.cart__records .button {
  margin-top: 10px;
}

.plain-text {
  background-color: #F4F4F4;
  padding: 40px;
  margin-bottom: 40px;
}
.plain-text__holder {
  background-color: #fff;
  padding: 40px;
  max-width: 673px;
  width: 100%;
  margin: 0 auto;
}
.plain-text h1 {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  margin-bottom: 44px;
  position: relative;
}
.plain-text h1:after {
  content: "";
  width: 30px;
  height: 4px;
  background-color: #000;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -24px;
}
.plain-text p {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 40px;
}
.plain-text a {
  color: #1C2024;
}
.plain-text__button-holder {
  border-top: 1px solid #EBEBEB;
  padding-top: 40px;
}
.plain-text .button {
  width: 100%;
  text-align: center;
}

.checkout {
  background-color: #F4F4F4;
  padding: 40px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 600px) {
  .checkout {
    padding: 20px;
  }
}
@supports (display: grid) {
  .checkout {
    display: grid;
    grid-template-columns: 67.96875% 28.90625%;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    width: 100%;
  }
  @media only screen and (max-width: 1100px) {
    .checkout {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  @media only screen and (max-width: 600px) {
    .checkout {
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 20px;
    }
  }
}
.checkout__no-items a {
  color: #1C2024;
  transition: opacity 0.2s ease-in-out;
}
.checkout__no-items a:hover {
  opacity: 0.6;
}
.checkout__title {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 30px;
}
.checkout__total {
  background-color: #fff;
  padding: 40px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 600px) {
  .checkout__total {
    padding: 20px;
    margin-bottom: 20px;
  }
}
.checkout__total ul {
  list-style: none;
  margin-bottom: 30px;
}
.checkout__total li {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #EBEBEB;
}
.checkout__total span {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  margin-right: 30px;
}
.checkout__total p {
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
}
.checkout__total .button {
  width: 100%;
  text-align: center;
}
.checkout__total table {
  margin-bottom: 20px;
}
.checkout__total table td {
  vertical-align: top;
  padding-right: 10px;
  padding-bottom: 5px;
}
.checkout__total table td:last-child {
  padding-right: 0;
}
.checkout__total__price {
  white-space: nowrap;
}
.checkout__total__sum {
  white-space: nowrap;
  margin-bottom: 20px;
}
.checkout__total__info {
  margin-top: 20px;
  font-size: 14px;
  line-height: 1.4em;
}
.checkout__total__info a {
  color: #1C2024;
  transition: opacity 0.2s ease-in-out;
}
.checkout__total__info a:hover {
  opacity: 0.6;
}
.checkout__contact {
  background-color: #fff;
  padding: 40px;
}
@media only screen and (max-width: 600px) {
  .checkout__contact {
    padding: 20px;
  }
}
.checkout__contact__section {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #EBEBEB;
}
.checkout__contact__section ul {
  list-style: none;
}
.checkout__contact__section span {
  font-size: 12px;
  line-height: 24px;
}
.checkout__contact__section p {
  font-size: 16px;
  line-height: 28px;
}
.checkout__items {
  background-color: #fff;
  padding: 40px;
}
@media only screen and (max-width: 600px) {
  .checkout__items {
    padding: 20px;
  }
}
.checkout__table {
  width: 100%;
}
@media only screen and (max-width: 800px) {
  .checkout__table .cover {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .checkout__table .cover {
    display: block;
    width: 100%;
    height: auto;
    margin-top: 20px;
  }
  .checkout__table .cover img {
    width: 100%;
    height: auto;
  }
}
.checkout__table .cover img {
  max-width: 66px;
  max-height: 104px;
}
.checkout__table th {
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  padding-bottom: 10px;
  border-bottom: 1px solid #1C2024;
}
.checkout__table th:last-child {
  text-align: right;
}
@media only screen and (max-width: 600px) {
  .checkout__table thead {
    display: none;
  }
}
.checkout__table tbody tr {
  border-bottom: 1px solid #EBEBEB;
  margin-bottom: 20px;
}
.checkout__table tbody tr td {
  padding-bottom: 20px;
  padding-top: 20px;
}
@media only screen and (max-width: 600px) {
  .checkout__table tbody tr td {
    display: block;
    width: 100%;
    padding-top: 0;
  }
}
.checkout__table tr td {
  vertical-align: bottom;
}
.checkout__table tr td a h2 {
  color: #1C2024;
  transition: opacity 0.2s ease-in-out;
}
.checkout__table tr td a h2:hover {
  opacity: 0.6;
}
.checkout__table tr td h2 {
  font-family: "ABC Monument Grotesk";
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
}
.checkout__table tr td p {
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 5px;
}
.checkout__table tr td span {
  font-size: 12px;
  line-height: 24px;
  display: block;
}
.checkout__table tr td .remove-line {
  font-size: 12px;
  color: #1C2024;
  transition: opacity 0.2s ease-in-out;
}
.checkout__table tr td .remove-line:hover {
  opacity: 0.6;
}
.checkout__table tr td:last-child {
  text-align: right;
}
@media only screen and (max-width: 600px) {
  .checkout__table tr td:last-child {
    text-align: left;
  }
}
.checkout__table input {
  padding: 2px;
  width: 100px;
}
.checkout__edit .button {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}
.checkout__edit ul {
  list-style: none;
  text-align: center;
}
.checkout__edit li {
  display: inline-block;
  margin: 0 10px;
}
.checkout__edit li a {
  font-size: 12px;
  line-height: 24px;
  color: #1C2024;
  transition: opacity 0.2s ease-in-out;
}
.checkout__edit li a:hover {
  opacity: 0.6;
}
.checkout__extra-fields {
  margin-top: 30px;
  margin-bottom: 30px;
}
.checkout__extra-fields__field {
  margin-bottom: 20px;
}
.checkout__extra-fields__field label {
  display: block;
  margin-bottom: 2px;
}
.checkout__extra-fields__field label span {
  margin-right: 0;
  font-weight: 600;
}
.checkout__extra-fields__field input {
  width: 100%;
  padding: 2px;
}
.checkout__error {
  color: #cc0000;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}

.order-completed {
  background-color: #F4F4F4;
  padding: 40px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 500px) {
  .order-completed {
    padding: 20px;
    margin-bottom: 20px;
  }
}
.order-completed__holder {
  max-width: 673px;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  padding: 60px;
  text-align: center;
}
@media only screen and (max-width: 500px) {
  .order-completed__holder {
    padding: 40px;
  }
}
.order-completed h1 {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  margin-bottom: 44px;
  position: relative;
}
.order-completed h1:after {
  content: "";
  width: 30px;
  height: 4px;
  background-color: #000;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -24px;
}
@media only screen and (max-width: 500px) {
  .order-completed h1 {
    font-size: 24px;
    line-height: 31px;
  }
}
.order-completed p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 40px;
}
.order-completed__button-holder {
  padding-top: 60px;
  border-top: 1px solid #EBEBEB;
}
.order-completed__button-holder .button:first-child {
  margin-right: 40px;
}
@media only screen and (max-width: 500px) {
  .order-completed__button-holder {
    padding-top: 20px;
  }
  .order-completed__button-holder .button {
    width: 100%;
    text-align: center;
  }
  .order-completed__button-holder .button:first-child {
    margin-bottom: 20px;
  }
}

.textblock-image {
  margin-bottom: 80px;
}
@media only screen and (max-width: 500px) {
  .textblock-image {
    margin-bottom: 60px;
  }
}
.textblock-image .row {
  max-width: 1040px;
  width: 100%;
  margin: 0 auto;
}
@supports (display: grid) {
  .textblock-image .row--small {
    display: grid;
    grid-template-columns: 50% 35.1923076923%;
    grid-column-gap: 14.8076923077%;
    align-items: center;
  }
  @media only screen and (max-width: 500px) {
    .textblock-image .row--small {
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 20px;
    }
  }
}
@supports (display: grid) {
  .textblock-image .row--large {
    display: grid;
    grid-template-columns: 58.6538461538% 30.2884615385%;
    grid-column-gap: 11.0576923077%;
    align-items: center;
  }
  @media only screen and (max-width: 500px) {
    .textblock-image .row--large {
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 20px;
    }
  }
}
@supports (display: grid) {
  .textblock-image .row--full-width {
    display: grid;
    grid-template-columns: 80%;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 500px) {
  .textblock-image .row--full-width {
    grid-template-columns: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .textblock-image__text {
    order: 1;
  }
}
.textblock-image__text span {
  position: relative;
  font-size: 12px;
  line-height: 30px;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #000;
  padding-left: 50px;
  margin-bottom: 18px;
  display: block;
}
.textblock-image__text span:before {
  content: "";
  width: 30px;
  height: 4px;
  background-color: #000;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.textblock-image__text h1, .textblock-image__text h2 {
  font-size: 36px;
  line-height: 41px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  margin-bottom: 15px;
}
.textblock-image__text h3 {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 15px;
}
.textblock-image__text h4 {
  font-family: "ABC Monument Grotesk";
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 15px;
}
.textblock-image__text p {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 15px;
}
@media only screen and (max-width: 500px) {
  .textblock-image__text p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
  }
}
.textblock-image__text blockquote p {
  font-size: 24px;
  line-height: 38px;
  font-family: "ABC Monument Grotesk";
  font-weight: 400;
  font-style: normal;
  margin-bottom: 15px;
}
.textblock-image__text ul {
  margin-bottom: 30px;
  list-style: none;
}
.textblock-image__text ul li {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 15px;
  position: relative;
  padding-left: 20px;
}
@media only screen and (max-width: 500px) {
  .textblock-image__text ul li {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px;
    padding-left: 30px;
  }
}
.textblock-image__text ul li:before {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  top: 11px;
  left: 0;
  background-color: #000;
  border-radius: 12px;
}
.textblock-image__text a {
  color: #1C2024;
  font-size: 16px;
  line-height: 28px;
}
.textblock-image__text a:hover {
  text-decoration: none;
}
.textblock-image__text strong {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
}
.textblock-image__text--large p {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 500px) {
  .textblock-image__text--large p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
  }
}
.textblock-image__text--large a {
  color: #1C2024;
  font-size: 20px;
  line-height: 30px;
}
.textblock-image__image {
  position: relative;
}
.textblock-image__image--small:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 20px;
  top: 20px;
  background-color: #000;
}
@media only screen and (max-width: 1024px) {
  .textblock-image__image--small:after {
    left: 20px;
    top: 20px;
  }
}
@media only screen and (max-width: 500px) {
  .textblock-image__image--small:after {
    left: 10px;
    top: 10px;
  }
}
.textblock-image__image--small img {
  max-width: 366px;
  width: 100%;
  height: auto;
  z-index: 2;
  position: relative;
}
@media only screen and (max-width: 500px) {
  .textblock-image__image--small img {
    max-width: 100%;
  }
}
.textblock-image__image--small .photo-cred {
  z-index: 3;
}
.textblock-image__image--large:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 20px;
  top: 20px;
  background-color: #000;
}
@media only screen and (max-width: 1024px) {
  .textblock-image__image--large:after {
    left: 20px;
    top: 20px;
  }
}
@media only screen and (max-width: 500px) {
  .textblock-image__image--large:after {
    left: 10px;
    top: 10px;
  }
}
.textblock-image__image--large img {
  max-width: 610px;
  width: 100%;
  height: auto;
  z-index: 2;
  position: relative;
}
@media only screen and (max-width: 500px) {
  .textblock-image__image--large img {
    max-width: 100%;
  }
}
body.with-spotin .textblock-image__image--small:after, body.with-spotin .textblock-image__image--large:after {
  display: none;
}

.full-width-image {
  margin-bottom: 50px;
  position: relative;
}
@media only screen and (max-width: 500px) {
  .full-width-image {
    margin-bottom: 60px;
  }
}
.full-width-image img {
  width: 100%;
  height: auto;
}
.full-width-image span {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #fff;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 5px 10px;
}
.full-width-image p {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  font-size: 16px;
  line-height: 26px;
}
@media only screen and (max-width: 500px) {
  .full-width-image p {
    bottom: 20px;
    left: 20px;
    right: 20px;
    font-size: 12px;
    line-height: 16px;
    padding: 10px;
  }
}

.manus-box {
  padding: 80px;
  background-color: #F4F4F4;
  text-align: center;
  margin-bottom: 100px;
}
@media only screen and (max-width: 500px) {
  .manus-box {
    padding: 40px 20px;
    margin-bottom: 60px;
  }
}
.manus-box span {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 30px;
  letter-spacing: 4px;
  color: #000;
  margin-bottom: 18px;
  padding-bottom: 20px;
  position: relative;
  display: block;
}
.manus-box span:after {
  content: "";
  width: 30px;
  height: 4px;
  background-color: #000;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}
.manus-box h2 {
  max-width: 636px;
  width: 100%;
  margin: 0 auto;
  font-size: 28px;
  line-height: 38px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  margin-bottom: 20px;
}
@media only screen and (max-width: 500px) {
  .manus-box h2 {
    font-size: 24px;
    line-height: 28px;
  }
}
.manus-box p {
  max-width: 714px;
  width: 100%;
  margin: 0 auto;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 40px;
}

@font-face {
  font-family: "lg";
  src: url("../fonts/lg.ttf?22t19m") format("truetype"), url("../fonts/lg.woff?22t19m") format("woff"), url("../fonts/lg.svg?22t19m#lg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.lg-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "lg" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lg-actions .lg-next, .lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080;
  outline: none;
  border: none;
  background-color: transparent;
}

.lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
  color: #FFF;
}

.lg-actions .lg-next {
  right: 20px;
}

.lg-actions .lg-next:before {
  content: "\e095";
}

.lg-actions .lg-prev {
  left: 20px;
}

.lg-actions .lg-prev:after {
  content: "\e094";
}

@-webkit-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative;
}

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative;
}

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}

.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: medium none;
  background: none;
  border: none;
  box-shadow: none;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.lg-toolbar .lg-icon:hover {
  color: #FFF;
}

.lg-toolbar .lg-close:after {
  content: "\e070";
}

.lg-toolbar .lg-download:after {
  content: "\e0f2";
}

.lg-sub-html {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  color: #EEE;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080;
}

.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
}

.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0;
}

#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle;
}

.lg-toolbar, .lg-prev, .lg-next {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
}

.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
}

.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
}

.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-outer .lg-thumb-outer {
  background-color: #0D0A0A;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}

.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0);
}

.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px;
}

.lg-outer .lg-thumb-item {
  border-radius: 5px;
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #FFF;
  border-radius: 4px;
  margin-bottom: 5px;
}

@media (min-width: 1025px) {
  .lg-outer .lg-thumb-item {
    -webkit-transition: border-color 0.25s ease;
    -o-transition: border-color 0.25s ease;
    transition: border-color 0.25s ease;
  }
}
.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: #a90707;
}

.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px;
}

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}

.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom 0.25s ease;
  -o-transition: bottom 0.25s ease;
  transition: bottom 0.25s ease;
}

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px;
}

.lg-outer .lg-toggle-thumb {
  background-color: #0D0A0A;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px;
  outline: medium none;
  border: none;
}

.lg-outer .lg-toggle-thumb:after {
  content: "\e1ff";
}

.lg-outer .lg-toggle-thumb:hover {
  color: #FFF;
}

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px;
}

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
}

.lg-outer .lg-video .lg-object {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.lg-outer .lg-video .lg-video-play {
  width: 84px;
  height: 59px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -42px;
  margin-top: -30px;
  z-index: 1080;
  cursor: pointer;
}

.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("../img/vimeo-play.png") no-repeat scroll 0 0 transparent;
}

.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("../img/vimeo-play.png") no-repeat scroll 0 -58px transparent;
}

.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("../img/video-play.png") no-repeat scroll 0 0;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8;
}

.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1;
}

.lg-outer .lg-has-youtube .lg-video-play {
  background: url("../img/youtube-play.png") no-repeat scroll 0 0 transparent;
}

.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("../img/youtube-play.png") no-repeat scroll 0 -60px transparent;
}

.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden;
}

.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none;
}

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible;
}

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 0.08s ease 0s;
  -moz-transition: opacity 0.08s ease 0s;
  -o-transition: opacity 0.08s ease 0s;
  transition: opacity 0.08s ease 0s;
}

.lg-progress-bar .lg-progress {
  background-color: #a90707;
  height: 5px;
  width: 0;
}

.lg-progress-bar.lg-start .lg-progress {
  width: 100%;
}

.lg-show-autoplay .lg-progress-bar {
  opacity: 1;
}

.lg-autoplay-button:after {
  content: "\e01d";
}

.lg-show-autoplay .lg-autoplay-button:after {
  content: "\e01a";
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

#lg-zoom-in:after {
  content: "\e311";
}

#lg-actual-size {
  font-size: 20px;
}

#lg-actual-size:after {
  content: "\e033";
}

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none;
}

#lg-zoom-out:after {
  content: "\e312";
}

.lg-zoomed #lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 10px;
}

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible;
}

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px;
}

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  box-shadow: 0 0 0 2px white inset;
}

.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #FFF;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  -moz-transition: opacity 0.15s ease 0s, -moz-transform 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s, -o-transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
}

.lg-outer .lg-pager-thumb-cont img {
  width: 100%;
  height: 100%;
}

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow 0.3s ease 0s;
  -o-transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s;
  width: 12px;
}

.lg-outer .lg-pager:hover, .lg-outer .lg-pager:focus {
  box-shadow: 0 0 0 8px white inset;
}

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0;
}

.lg-fullscreen:after {
  content: "\e20c";
}

.lg-fullscreen-on .lg-fullscreen:after {
  content: "\e20d";
}

.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  -o-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
}

.lg-outer.lg-dropdown-active .lg-dropdown, .lg-outer.lg-dropdown-active #lg-dropdown-overlay {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  opacity: 1;
  visibility: visible;
}

.lg-outer.lg-dropdown-active #lg-share {
  color: #FFF;
}

.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  -webkit-transition: -webkit-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -moz-transition: -moz-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -o-transition: -o-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
}

.lg-outer .lg-dropdown:after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  border: 8px solid transparent;
  border-bottom-color: #FFF;
  right: 16px;
  top: -16px;
}

.lg-outer .lg-dropdown > li:last-child {
  margin-bottom: 0px;
}

.lg-outer .lg-dropdown > li:hover a, .lg-outer .lg-dropdown > li:hover .lg-icon {
  color: #333;
}

.lg-outer .lg-dropdown a {
  color: #333;
  display: block;
  white-space: pre;
  padding: 4px 12px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
}

.lg-outer .lg-dropdown a:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.lg-outer .lg-dropdown .lg-dropdown-text {
  display: inline-block;
  line-height: 1;
  margin-top: -3px;
  vertical-align: middle;
}

.lg-outer .lg-dropdown .lg-icon {
  color: #333;
  display: inline-block;
  float: none;
  font-size: 20px;
  height: auto;
  line-height: 1;
  margin-right: 8px;
  padding: 0;
  vertical-align: middle;
  width: auto;
}

.lg-outer #lg-share {
  position: relative;
}

.lg-outer #lg-share:after {
  content: "\e80d";
}

.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998;
}

.lg-outer #lg-share-facebook .lg-icon:after {
  content: "\e904";
}

.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced;
}

.lg-outer #lg-share-twitter .lg-icon:after {
  content: "\e907";
}

.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39;
}

.lg-outer #lg-share-googleplus .lg-icon:after {
  content: "\e905";
}

.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027;
}

.lg-outer #lg-share-pinterest .lg-icon:after {
  content: "\e906";
}

.lg-outer .lg-img-rotate {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  transition: transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
}

.lg-rotate-left:after {
  content: "\e900";
}

.lg-rotate-right:after {
  content: "\e901";
}

.lg-icon.lg-flip-hor, .lg-icon.lg-flip-ver {
  font-size: 26px;
}

.lg-flip-hor:after {
  content: "\e902";
}

.lg-flip-ver:after {
  content: "\e903";
}

.lg-group {
  *zoom: 1;
}

.lg-group:before, .lg-group:after {
  display: table;
  content: "";
  line-height: 0;
}

.lg-group:after {
  clear: both;
}

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  outline: none;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}

.lg-outer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.lg-outer.lg-visible {
  opacity: 1;
}

.lg-outer.lg-css3 .lg-item.lg-prev-slide, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-current {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1;
}

.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%;
}

.lg-outer .lg-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
}

.lg-outer .lg-item {
  background: url("../img/loading.gif") no-repeat scroll center center transparent;
  display: none !important;
}

.lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide {
  display: inline-block !important;
}

.lg-outer.lg-css .lg-current {
  display: inline-block !important;
}

.lg-outer .lg-item, .lg-outer .lg-img-wrap {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}

.lg-outer .lg-img-wrap {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.lg-outer .lg-item.lg-complete {
  background-image: none;
}

.lg-outer .lg-item.lg-current {
  z-index: 1060;
}

.lg-outer .lg-image {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
  height: auto !important;
}

.lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
  opacity: 1;
}

.lg-outer .lg-empty-html {
  display: none;
}

.lg-outer.lg-hide-download #lg-download {
  display: none;
}

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}

.lg-backdrop.in {
  opacity: 1;
}

.lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important;
}

.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-css3.lg-fade .lg-item {
  opacity: 0;
}

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}

.lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
  -webkit-transition: opacity 0.1s ease 0s;
  -moz-transition: opacity 0.1s ease 0s;
  -o-transition: opacity 0.1s ease 0s;
  transition: opacity 0.1s ease 0s;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  left: -100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
  left: 100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  left: 0;
  opacity: 1;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

body:has(.is-fixed-size.lg-outer.lg-visible) {
  overflow: hidden;
}

.is-fixed-size.lg-outer {
  --lg-padding-x: 30px;
  --lg-padding-y: 16px;
  --lg-icon-offset: 24px;
  top: 0px;
  padding-top: var(--wp-admin--admin-bar--height, 0px);
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 32px;
  padding-right: 32px;
  min-height: 0;
}
.is-fixed-size.lg-outer.lg-has-thumb .lg-item {
  padding: 0;
  margin-bottom: 0;
}
.is-fixed-size.lg-outer > .lg-dialog {
  max-width: 1024px;
  margin: 32px;
  margin-right: auto;
  margin-left: auto;
  pointer-events: none;
  display: flex;
  align-items: center;
  min-height: calc(100% - 32px);
}
.is-fixed-size.lg-outer .lg-content {
  position: relative;
  pointer-events: none;
  overflow: initial;
  width: 100% !important;
  height: auto !important;
  display: flex;
  flex-direction: column;
  padding: var(--lg-padding-y, 16px) var(--lg-padding-x, 16px);
  padding-bottom: calc(var(--lg-padding-y, 16px) + 40px);
  background: #fff;
  border-radius: 8px;
  min-height: 0;
  pointer-events: all;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1), 0 6px 32px rgba(0, 0, 0, 0.1);
}
.is-fixed-size.lg-outer .lg-inner {
  position: relative;
  height: auto;
  flex-shrink: 0;
  margin-bottom: 16px;
}
.is-fixed-size.lg-outer .lg-item {
  display: block !important;
  top: 0;
  text-align: left;
  width: 100%;
  height: auto;
  overflow-x: hidden;
  margin-bottom: 0;
}
.is-fixed-size.lg-outer .lg-item::before {
  display: none;
  height: auto;
  margin: 0;
  width: 100%;
}
.is-fixed-size.lg-outer .lg-item.lg-current {
  position: relative;
}
.is-fixed-size.lg-outer .lg-img-wrap {
  background: #f1f1f1;
  position: relative;
  height: auto;
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
}
.is-fixed-size.lg-outer .lg-img-wrap::before {
  display: none;
  padding-top: 56.25%;
}
.is-fixed-size.lg-outer .lg-sub-html {
  position: relative;
  text-align: left;
  padding: 32px 0px;
  background: transparent;
  bottom: auto !important;
  color: #444;
  padding: 0;
  margin: 0;
}
.is-fixed-size.lg-outer .lg-thumb-outer {
  margin-top: 32px;
  position: relative;
  bottom: auto;
  background: transparent;
  max-height: none;
  padding: 0;
}
.is-fixed-size.lg-outer .lg-thumb-outer .lg-thumb.group {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 0;
  margin: 0 !important;
}
.is-fixed-size.lg-outer .lg-thumb-outer .lg-thumb-item {
  width: 120px;
  height: 67.5px;
  margin: 0 !important;
}
.is-fixed-size.lg-outer .lg-object.lg-image {
  --zero-px: 0px;
  display: block;
  vertical-align: initial;
  width: 100% !important;
  object-fit: contain;
  object-position: center;
  border-radius: 4px;
  height: auto;
  max-height: 640px;
  max-height: min(640px, 100vh - var(--lg-padding-y) * 2 - 32px - var(--wp-admin--admin-bar--height, var(--zero-px, 0px)));
}
.is-fixed-size.lg-outer .lg-actions {
  --btn-size: 36px;
}
.is-fixed-size.lg-outer .lg-actions .lg-prev,
.is-fixed-size.lg-outer .lg-actions .lg-next {
  padding: 7px;
  background: rgba(50, 50, 52, 0.5);
  border-radius: 999px;
  width: var(--btn-size, 36px);
  height: var(--btn-size, 36px);
  margin-top: calc(var(--btn-size, 36px) / -2);
}
.is-fixed-size.lg-outer .lg-actions .lg-prev:hover,
.is-fixed-size.lg-outer .lg-actions .lg-next:hover {
  background: rgba(50, 50, 52, 0.9);
}
.is-fixed-size.lg-outer .lg-actions .lg-prev:disabled,
.is-fixed-size.lg-outer .lg-actions .lg-next:disabled {
  opacity: 0;
}
.is-fixed-size.lg-outer .lg-actions .lg-prev {
  left: calc(var(--btn-size, 36px) / -2);
}
.is-fixed-size.lg-outer .lg-actions .lg-next {
  right: calc(var(--btn-size, 36px) / -2);
}
.is-fixed-size.lg-outer .lg-toolbar {
  opacity: 1;
  position: static;
  background: transparent;
  margin: 0;
  height: 0px;
}
.is-fixed-size.lg-outer .lg-close,
.is-fixed-size.lg-outer .lg-download {
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  background: rgba(50, 50, 52, 0.5);
  right: var(--lg-icon-offset, 24px);
  top: var(--lg-icon-offset, 24px);
}
.is-fixed-size.lg-outer .lg-close:hover,
.is-fixed-size.lg-outer .lg-download:hover {
  background: rgba(50, 50, 52, 0.9);
}
.is-fixed-size.lg-outer .lg-close {
  top: -20px;
  right: -20px;
  background: rgba(50, 50, 52, 0.9);
}
.is-fixed-size.lg-outer .lg-download {
  position: relative;
  right: 12px;
  top: -73px;
}
.is-fixed-size.lg-outer #lg-counter {
  position: absolute;
  left: var(--lg-padding-x);
  top: var(--lg-padding-y);
  color: #222;
  padding: 8px 12px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0 0 6px 0;
  font-weight: bold;
  display: none;
}
.is-fixed-size.lg-outer.lg-has-thumb #lg-counter {
  display: block;
}
@media screen and (min-width: 768px) {
  .is-fixed-size.lg-outer {
    --lg-padding-x: 36px;
    --lg-padding-y: 24px;
    --lg-icon-offset: 16px;
  }
}
@media screen and (min-width: 1024px) {
  .is-fixed-size.lg-outer {
    --lg-padding-x: 40px;
    --lg-padding-y: 40px;
  }
}

.table-wrapper {
  margin-bottom: 60px;
}
@media only screen and (max-width: 1024px) {
  .table-wrapper {
    overflow-x: scroll;
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .table {
    width: 1024px;
  }
}
.table--five-columns .table__head {
  grid-template-columns: repeat(5, 1fr);
}
.table--five-columns .table__body__row {
  grid-template-columns: repeat(5, 1fr);
}
.table--six-columns .table__head {
  grid-template-columns: repeat(6, 1fr);
}
.table--six-columns .table__body__row {
  grid-template-columns: repeat(6, 1fr);
}
.table__head {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 40px;
  padding-bottom: 10px;
  border-bottom: 2px solid #1C2024;
}
@media only screen and (max-width: 800px) {
  .table__head {
    grid-column-gap: 20px;
  }
}
.table__head__column p {
  font-size: 18px;
  line-height: 24px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
}
.table__head__column:last-child {
  text-align: right;
}
.table__head__column--no-alignment:last-child {
  text-align: left;
}
.table__body__row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 40px;
  padding: 10px 0;
  border-bottom: 1px solid #C7C7C7;
}
@media only screen and (max-width: 800px) {
  .table__body__row {
    grid-column-gap: 20px;
  }
}
.table__body__row__column a {
  display: inline-block;
  color: inherit;
  transition: opacity 0.2s ease-in-out;
  font-size: 14px;
  line-height: 24px;
}
.table__body__row__column a:hover {
  opacity: 0.6;
}
.table__body__row__column p {
  font-size: 14px;
  line-height: 24px;
}
.table__body__row__column:last-child {
  text-align: right;
}
.table__body__row__column--no-alignment:last-child {
  text-align: left;
}

.bookdatabase {
  min-height: 415px;
  display: flex;
  margin-bottom: 54px;
  position: relative;
  color: inherit;
  text-decoration: none;
  overflow: hidden;
}
@media only screen and (max-width: 800px) {
  .bookdatabase {
    flex-direction: column;
    margin-bottom: 40px;
  }
}
.bookdatabase:hover .bookdatabase__image {
  transform: scale(1.05);
}
.bookdatabase:hover .button {
  text-decoration: underline;
}
.bookdatabase__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  transition: all 1s ease-in-out;
}
.bookdatabase__image--left {
  right: 50%;
}
.bookdatabase__image--right {
  left: 50%;
}
@media only screen and (max-width: 800px) {
  .bookdatabase__image {
    position: static;
    width: 100%;
    height: 194px;
  }
}
.bookdatabase__content {
  position: relative;
  width: 50%;
  padding: 50px 87px;
  display: flex;
}
@media only screen and (max-width: 800px) {
  .bookdatabase__content {
    padding: 20px;
    width: 100%;
  }
}
.bookdatabase__content--left {
  margin-left: auto;
}
.bookdatabase__content--gray {
  background-color: #f2f2f2;
}
.bookdatabase__content--black {
  background-color: #1C2024;
}
.bookdatabase__content--black .bookdatabase__content-inner h2 {
  color: #fff;
}
.bookdatabase__content--black .bookdatabase__content-inner p {
  color: #fff;
}
.bookdatabase__content--black .bookdatabase__content-inner .button {
  background-color: #E5E4DF;
  color: #1C2024;
}
.bookdatabase__content--black .bookdatabase__content-inner .button svg path {
  fill: #1C2024;
}
.bookdatabase__content--white {
  background-color: #fff;
}
.bookdatabase__content-inner {
  align-self: center;
}
.bookdatabase__content-inner h2 {
  font-size: 20px;
  line-height: 27px;
  font-family: "freight-sans-pro";
  font-weight: 500;
  text-decoration: underline;
  margin-bottom: 14px;
}
.bookdatabase__content-inner p {
  font-size: 17px;
  line-height: 27px;
  font-family: "freight-sans-pro";
  margin-bottom: 40px;
}
@media only screen and (max-width: 500px) {
  .bookdatabase__content-inner p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
  }
}
.bookdatabase__content-inner .button {
  color: #E5E4DF;
  background-color: #1C2024;
  padding: 8px 35px;
  font-size: 16px;
  line-height: 24px;
  font-family: "Conv_Aileron-SemiBold";
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  display: inline-block;
}
.bookdatabase__content-inner .button svg path {
  fill: #E5E4DF;
}

.newsletter {
  background-color: #1C2024;
  padding: 40px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 500px) {
  .newsletter {
    padding: 20px;
  }
}
@supports (display: grid) {
  .newsletter {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    grid-column-gap: 100px;
  }
  @media only screen and (max-width: 1110px) {
    .newsletter {
      grid-template-columns: 100%;
      grid-column-gap: 50px;
    }
  }
  @media only screen and (max-width: 500px) {
    .newsletter {
      grid-template-columns: 100%;
      grid-column-gap: 0;
    }
  }
}
.newsletter__title-small {
  font-size: 24px;
  line-height: 34px;
  color: #fff;
  margin-bottom: 40px;
}
@media only screen and (max-width: 500px) {
  .newsletter__title-small {
    font-size: 18px;
    line-height: 34px;
    margin-bottom: 20px;
  }
}
.newsletter__title-small img {
  float: right;
  margin-top: -5px;
  max-width: 100%;
}
@media only screen and (max-width: 500px) {
  .newsletter__title-small img {
    display: none;
  }
}
.newsletter__title-large {
  color: #fff;
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 40px;
  text-align: center;
}
@media only screen and (max-width: 500px) {
  .newsletter__title-large {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
  }
}
.newsletter__col {
  padding: 40px;
}
@media only screen and (max-width: 1110px) {
  .newsletter__col {
    display: flex;
    display: block;
  }
  .newsletter__col:nth-child(1) {
    order: 2;
  }
  .newsletter__col:nth-child(2) {
    order: 1;
  }
}
@media only screen and (max-width: 600px) {
  .newsletter__col {
    padding: 20px;
  }
}
.newsletter__col--border {
  border: 1px solid #474A4D;
}
.newsletter label {
  width: 100%;
  color: #fff;
  display: block;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 500px) {
  .newsletter label {
    font-size: 14px;
    line-height: 24px;
  }
}
.newsletter input {
  max-width: 560px;
  width: 100%;
  display: block;
  padding: 14px 20px;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 40px;
  outline: none;
}
@media only screen and (max-width: 500px) {
  .newsletter input {
    padding: 8px 10px;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
.newsletter__error-message {
  color: red;
}
.newsletter__success-message {
  color: white;
}
.newsletter__link {
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  transition: opacity 0.2s ease-in-out;
  position: relative;
  padding-right: 36px;
}
.newsletter__link:after {
  content: "";
  width: 19px;
  height: 21px;
  background-image: url("../img/icon-arrow-right-white.svg");
  position: absolute;
  right: 0;
  top: 0;
}
.newsletter__link:hover {
  opacity: 0.6;
}
@media only screen and (max-width: 500px) {
  .newsletter__link {
    font-size: 14px;
    line-height: 21px;
  }
}
.newsletter__integrity_policy_link {
  font-size: 16px;
  display: block;
  float: right;
  color: #fff;
}
.newsletter__people {
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@media only screen and (max-width: 500px) {
  .newsletter__people {
    margin-bottom: 20px;
  }
}
.newsletter__person {
  text-align: center;
  padding: 0 10px;
  width: 20%;
}
.newsletter__person img {
  max-width: 64px;
  width: 100%;
  border-radius: 32px;
  margin-bottom: 14px;
}
@media only screen and (max-width: 1110px) {
  .newsletter__person img {
    max-width: 100%;
    border-radius: 50%;
  }
}
@media only screen and (max-width: 500px) {
  .newsletter__person img {
    margin-bottom: 0;
  }
}
.newsletter__person p {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
}
@media only screen and (max-width: 500px) {
  .newsletter__person p {
    display: none;
  }
}
.newsletter__content {
  text-align: center;
}
@media only screen and (max-width: 500px) {
  .newsletter__content {
    margin-bottom: 20px;
  }
}
.newsletter__content strong {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
}
.newsletter__content p {
  color: #fff;
  font-size: 14px;
  line-height: 22px;
}

.media-account {
  background-color: #1C2024;
  padding: 40px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 500px) {
  .media-account {
    padding: 20px;
  }
}
@supports (display: grid) {
  .media-account {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    grid-column-gap: 100px;
  }
  @media only screen and (max-width: 1100px) {
    .media-account {
      grid-template-columns: 100%;
    }
  }
}
.media-account__title-large {
  font-size: 42px;
  line-height: 34px;
  color: #fff;
  margin-bottom: 30px;
}
@media only screen and (max-width: 500px) {
  .media-account__title-large {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
  }
}
.media-account__title-small {
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 10px;
  color: #fff;
}
.media-account__title-small img {
  float: right;
}
@media only screen and (max-width: 500px) {
  .media-account__title-small {
    font-size: 18px;
    line-height: 34px;
  }
}
.media-account__paragraph-large {
  color: #fff;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 30px;
}
.media-account__paragraph-large strong {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
}
@media only screen and (max-width: 500px) {
  .media-account__paragraph-large {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
.media-account__paragraph-small {
  color: #fff;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 500px) {
  .media-account__paragraph-small {
    display: block;
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 21px;
  }
}
.media-account__paragraph-small strong {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
}
.media-account__paragraph-small a {
  color: #fff;
  transition: opacity 0.2s ease-in-out;
}
.media-account__paragraph-small a:hover {
  opacity: 0.6;
}
.media-account__col {
  padding: 40px;
  align-self: center;
}
@media only screen and (max-width: 500px) {
  .media-account__col {
    padding: 20px;
  }
}
.media-account__col--text-center {
  text-align: center;
}
@media only screen and (max-width: 500px) {
  .media-account__col--text-center {
    padding: 0;
  }
}
.media-account__col--bordered {
  border: 1px solid #474A4D;
}
.media-account label {
  width: 100%;
  color: #fff;
  display: block;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 500px) {
  .media-account label {
    font-size: 14px;
    line-height: 24px;
  }
}
.media-account input {
  max-width: 560px;
  width: 100%;
  display: block;
  padding: 14px 20px;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 40px;
  outline: none;
}
@media only screen and (max-width: 500px) {
  .media-account input {
    padding: 8px 10px;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
.media-account__link {
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  transition: opacity 0.2s ease-in-out;
  position: relative;
  padding-right: 36px;
}
@media only screen and (max-width: 500px) {
  .media-account__link {
    font-size: 14px;
    line-height: 21px;
  }
}
.media-account__link:after {
  content: "";
  width: 19px;
  height: 21px;
  background-image: url("../img/icon-arrow-right-white.svg");
  position: absolute;
  right: 0;
  top: 0;
}
.media-account__link:hover {
  opacity: 0.6;
}
.media-account__login_error {
  color: #fff;
  margin-bottom: 10px;
}

.current-trailers {
  margin-bottom: 80px;
}
@media only screen and (max-width: 500px) {
  .current-trailers {
    margin-bottom: 40px;
  }
}
@supports (display: grid) {
  .current-trailers__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }
  @media only screen and (max-width: 500px) {
    .current-trailers__grid {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
    }
  }
}
.current-trailers__title {
  font-size: 24px;
  line-height: 34px;
  color: #1C2024;
  margin-bottom: 40px;
  display: inline-block;
  transition: opacity 0.2s ease-in-out;
}
@media only screen and (max-width: 500px) {
  .current-trailers__title {
    font-size: 18px;
    line-height: 24px;
  }
}
.current-trailers__col a {
  color: #1C2024;
  text-decoration: none;
}
.current-trailers__col a:hover h3 {
  opacity: 0.6;
}
.current-trailers__col a:hover .current-trailers__video:after {
  opacity: 0.6;
}
.current-trailers__col h3 {
  font-size: 18px;
  line-height: 26px;
  transition: opacity 0.2s ease-in-out;
  text-decoration: underline;
}
@media only screen and (max-width: 500px) {
  .current-trailers__col h3 {
    font-size: 14px;
    line-height: 24px;
  }
}
.current-trailers__col p {
  text-decoration: none;
  font-size: 18px;
  line-height: 26px;
}
@media only screen and (max-width: 500px) {
  .current-trailers__col p {
    font-size: 14px;
    line-height: 24px;
  }
}
.current-trailers__video {
  position: relative;
  margin-bottom: 19px;
}
.current-trailers__video img {
  width: 100%;
  max-width: 427px;
}
.current-trailers__video:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(28, 32, 36, 0.5);
}
.current-trailers__video:after {
  content: "";
  width: 51px;
  height: 36px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-image: url("../img/icon-play.svg");
  background-repeat: no-repeat;
  transition: opacity 0.2s ease-in-out;
}

.lib-instagram {
  margin-bottom: 80px;
}
@media only screen and (max-width: 500px) {
  .lib-instagram {
    margin-bottom: 40px;
  }
}
.lib-instagram h2 {
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 60px;
}
@media only screen and (max-width: 500px) {
  .lib-instagram h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 40px;
  }
}
@supports (display: grid) {
  .lib-instagram__grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    width: 100%;
    grid-column-gap: 6.6176470588%;
    grid-row-gap: 40px;
  }
  @media only screen and (max-width: 980px) {
    .lib-instagram__grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  @media only screen and (max-width: 600px) {
    .lib-instagram__grid {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }
  }
  @media only screen and (max-width: 500px) {
    .lib-instagram__grid {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }
  }
}
.lib-instagram__col {
  text-align: center;
}
.lib-instagram__col img {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 25px;
}
.lib-instagram__col a {
  color: #1C2024;
  margin-bottom: 25px;
  display: block;
  font-size: 18px;
  line-height: 24px;
}
@media only screen and (max-width: 500px) {
  .lib-instagram__col a {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
  }
}
.lib-instagram__col p {
  font-size: 18px;
  line-height: 24px;
}
@media only screen and (max-width: 500px) {
  .lib-instagram__col p {
    font-size: 14px;
    line-height: 24px;
  }
}

.press-contacts__title {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 40px;
}
@supports (display: grid) {
  .press-contacts .row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 40px;
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    .press-contacts .row {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media only screen and (max-width: 760px) {
    .press-contacts .row {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media only screen and (max-width: 500px) {
    .press-contacts .row {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
.press-contacts__col {
  text-align: center;
}
.press-contacts__col img {
  max-width: 192px;
  width: 100%;
  border-radius: 192px;
  margin-bottom: 30px;
  filter: grayscale(100%);
}
.press-contacts__col h2 {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
}
.press-contacts__col p {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 20px;
}
.press-contacts__col a {
  font-size: 16px;
  line-height: 26px;
  color: #1C2024;
}

.our-writers {
  margin-bottom: 80px;
}
@media only screen and (max-width: 500px) {
  .our-writers {
    margin-bottom: 40px;
  }
}
.our-writers__title {
  color: #1C2024;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 40px;
  display: inline-block;
}
@media only screen and (max-width: 500px) {
  .our-writers__title {
    font-size: 18px;
    line-height: 24px;
  }
}
@supports (display: grid) {
  .our-writers__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }
  @media only screen and (max-width: 800px) {
    .our-writers__grid {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 20px;
    }
  }
  @media only screen and (max-width: 500px) {
    .our-writers__grid {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
    }
  }
}
.our-writers__col {
  color: inherit;
  text-decoration: none;
  font-size: 18px;
  line-height: 26px;
}
.our-writers__col img {
  max-width: 426px;
  width: 100%;
  margin-bottom: 20px;
}
.our-writers__col p {
  margin-bottom: 20px;
}
@media only screen and (max-width: 500px) {
  .our-writers__col p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
  }
}
.our-writers__col span {
  text-decoration: underline;
  transition: opacity 0.2s ease-in-out;
}
@media only screen and (max-width: 500px) {
  .our-writers__col span {
    font-size: 14px;
    line-height: 24px;
  }
}
.our-writers__col:hover span {
  opacity: 0.6;
}

.release-message {
  text-align: center;
  margin-bottom: 80px;
  max-width: 840px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 80px;
}
.release-message__header {
  text-decoration: underline;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 80px;
}
.release-message__content {
  color: inherit;
  text-decoration: none;
  max-width: 630px;
  width: 100%;
  margin: 0 auto;
  display: block;
}
.release-message__image {
  max-width: 840px;
  width: 100%;
  margin-bottom: 40px;
}
.release-message__date {
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1px;
  display: block;
  margin-bottom: 10px;
}
.release-message__title {
  font-size: 48px;
  line-height: 60px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  margin-bottom: 20px;
}
.release-message p {
  font-size: 16px;
  line-height: 26px;
}
.release-message a {
  position: relative;
  color: inherit;
  text-decoration: none;
  display: block;
  padding-bottom: 51px;
}
.release-message a:hover .release-message__title {
  text-decoration: underline;
}
.release-message a:hover:after {
  transform: translateX(10px);
}
.release-message a:after {
  content: "";
  width: 19px;
  height: 21px;
  background-image: url("../img/icon-arrow-right.svg");
  background-size: 19px 21px;
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0px;
  transition: transform 0.2s ease-in-out;
}

.room-intro__full-image.top, .room-intro__pattern.top {
  margin-top: -60px;
}
@media only screen and (max-width: 500px) {
  .room-intro__full-image.top, .room-intro__pattern.top {
    margin-top: -20px;
  }
}

.room-intro {
  padding: 60px 0 0;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}
@media only screen and (max-width: 500px) {
  .room-intro {
    padding: 30px 0 0;
  }
}
.room-intro__logotype {
  text-align: center;
  margin-bottom: 20px;
}
@media only screen and (max-width: 500px) {
  .room-intro__logotype {
    margin-bottom: 10px;
  }
}
.room-intro__logotype img {
  max-width: 113px;
  width: 100%;
  margin-bottom: 20px;
}
@media only screen and (max-width: 500px) {
  .room-intro__logotype img {
    max-width: 64px;
  }
}
.room-intro__logotype h1 {
  font-size: 60px;
  font-weight: 700;
}
@media only screen and (max-width: 500px) {
  .room-intro__logotype h1 {
    font-size: 36px;
  }
}
@media only screen and (max-width: 500px) {
  .room-intro__logotype {
    margin-bottom: 20px;
  }
}
.room-intro__content {
  max-width: 860px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 60px;
  padding: 0 20px;
}
@media only screen and (max-width: 500px) {
  .room-intro__content {
    margin-bottom: 20px;
  }
}
.room-intro__content p {
  font-size: 18px;
  line-height: 28px;
}
@media only screen and (max-width: 500px) {
  .room-intro__content p {
    font-size: 14px;
    line-height: 21px;
  }
}
.room-intro__content a {
  color: #1C2024;
  transition: opacity 0.2s ease-in-out;
}
.room-intro__content a:hover {
  opacity: 0.6;
}
.room-intro__news {
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 56px;
  text-align: center;
}
@media only screen and (max-width: 500px) {
  .room-intro__news {
    margin-bottom: 10px;
  }
}
.room-intro__news p {
  font-size: 14px;
  line-height: 24px;
}
@media only screen and (max-width: 500px) {
  .room-intro__news p {
    font-size: 12px;
    line-height: 18px;
    padding: 0 20px;
  }
}
.room-intro__news a {
  color: #1C2024;
  transition: opacity 0.2s ease-in-out;
}
.room-intro__news a:hover {
  opacity: 0.6;
}
.room-intro__news strong {
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
}
.room-intro__pattern {
  height: 0;
  max-height: 342px;
  padding-bottom: 26%;
  margin-bottom: 80px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
}
@media only screen and (max-width: 500px) {
  .room-intro__pattern {
    padding-bottom: 50%;
    margin-bottom: 40px;
  }
}
.room-intro__full-image {
  text-align: center;
  margin-bottom: 80px;
}
@media only screen and (max-width: 500px) {
  .room-intro__full-image {
    margin-bottom: 20px;
  }
}
.room-intro__full-image img {
  max-width: 100%;
  vertical-align: top;
}
.rooms-teaser {
  background-color: #E6E4DF;
  margin-bottom: 40px;
}
.rooms-teaser__title {
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 80px;
}
@media only screen and (max-width: 500px) {
  .rooms-teaser__title {
    font-size: 18px;
    line-height: 24px;
  }
}
.rooms-teaser__padding {
  padding: 40px;
}
@media only screen and (max-width: 500px) {
  .rooms-teaser__padding {
    padding: 20px;
  }
}
.rooms-teaser__rooms {
  margin-bottom: 40px;
}
@supports (display: grid) {
  .rooms-teaser__rooms {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    grid-column-gap: 40px;
  }
  @media only screen and (max-width: 800px) {
    .rooms-teaser__rooms {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 40px;
    }
  }
  @media only screen and (max-width: 500px) {
    .rooms-teaser__rooms {
      grid-template-columns: 100%;
    }
  }
}
.rooms-teaser__room {
  text-align: center;
}
.rooms-teaser a {
  color: inherit;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
}
.rooms-teaser a:hover {
  opacity: 0.6;
}
.rooms-teaser h3 {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.rooms-teaser p {
  font-family: "ABC Monument Grotesk";
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.rooms-teaser img {
  max-width: 94px;
  width: 100%;
  margin-bottom: 20px;
}
.rooms-teaser__pattern {
  height: 0;
  padding-bottom: 50.148%;
  background-size: cover;
  background-position: center center;
}

.employer-branding {
  background-color: #1C2024;
  margin-bottom: 54px;
}
@media only screen and (max-width: 500px) {
  .employer-branding {
    margin-bottom: 40px;
  }
}
.employer-branding--light {
  background-color: #E5E4DE;
}
.employer-branding--light .employer-branding__intro {
  color: #000;
}
.employer-branding--light .employer-branding__intro a {
  color: #000;
}
.employer-branding--light .employer-branding__articles__column {
  color: #000;
}
.employer-branding--light .employer-branding__icons {
  background-color: #F2F2EF;
}
.employer-branding--light .employer-branding__icons-column span {
  color: #000;
}
.employer-branding--light .employer-branding__icons-column:hover {
  background-color: #E5E4DE;
}
.employer-branding--light .employer-branding__icons-column__links {
  background-color: #E5E4DE;
}
.employer-branding--light .employer-branding__icons-column__links a {
  color: #000;
}
.employer-branding--light .employer-branding__intro a svg path, .employer-branding--light .employer-branding__icons-column span svg path {
  fill: #000;
}
.employer-branding__intro {
  color: #fff;
  display: flex;
  flex-direction: row;
  padding: 60px 70px;
  margin: 0 -20px;
}
@media only screen and (max-width: 500px) {
  .employer-branding__intro {
    padding: 20px;
    flex-direction: column;
  }
}
.employer-branding__intro p {
  font-size: 20px;
  line-height: 30px;
}
.employer-branding__intro a {
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  display: flex;
  align-items: center;
  margin-top: auto;
}
.employer-branding__intro a:hover {
  text-decoration: none;
}
.employer-branding__intro a svg {
  margin-right: 10px;
}
@media only screen and (max-width: 500px) {
  .employer-branding__intro a {
    margin-top: 10px;
  }
}
.employer-branding__intro div {
  width: 100%;
  max-width: 700px;
  padding: 0 20px;
}
.employer-branding__intro div a {
  margin-top: 10px;
}
.employer-branding__articles {
  margin: 0 -20px 40px -20px;
  padding: 0 70px;
  display: flex;
  flex-direction: row;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  align-content: center;
}
@media only screen and (max-width: 500px) {
  .employer-branding__articles {
    padding: 20px;
    flex-direction: column;
    margin: 0;
  }
}
.employer-branding .employer-branding__articles__image {
  overflow: hidden;
  margin-bottom: 10px;
}
.employer-branding__articles__column {
  width: 33.333%;
  padding: 0 20px;
  text-decoration: none;
  color: #fff;
  overflow: hidden;
}
@media only screen and (max-width: 500px) {
  .employer-branding__articles__column {
    padding: 0;
    width: 100%;
    margin-bottom: 40px;
  }
  .employer-branding__articles__column:last-child {
    margin-bottom: 0;
  }
}
.employer-branding__articles__column:hover {
  text-decoration: underline;
}
.employer-branding__articles__column:hover img {
  transform: scale(1.05);
}
.employer-branding__articles__column img {
  width: 100%;
  height: auto;
  transition: transform 1s ease-in-out;
}
.employer-branding__articles__column span {
  font-size: 20px;
  line-height: 27px;
  font-weight: 500;
}
.employer-branding__icons {
  display: flex;
  flex-direction: row;
  background-color: #333638;
}
@media only screen and (max-width: 500px) {
  .employer-branding__icons {
    flex-wrap: wrap;
    padding: 20px 0;
  }
}
.employer-branding__icons-column {
  width: 20%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}
@media only screen and (max-width: 500px) {
  .employer-branding__icons-column {
    width: 50%;
  }
}
.employer-branding__icons-column:hover {
  cursor: pointer;
  background-color: #1C2024;
}
.employer-branding__icons-column:hover .employer-branding__icons-column__links {
  display: flex;
}
.employer-branding__icons-column:hover span {
  text-decoration: underline;
}
.employer-branding__icons-column img {
  height: 68px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 500px) {
  .employer-branding__icons-column img {
    height: 50px;
  }
}
.employer-branding__icons-column span {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-family: "Conv_Aileron-SemiBold";
  display: flex;
  align-items: center;
  align-self: center;
}
.employer-branding__icons-column span svg {
  margin-right: 10px;
}
@media only screen and (max-width: 500px) {
  .employer-branding__icons-column span svg {
    width: 10px;
    height: 10px;
  }
}
@media only screen and (max-width: 500px) {
  .employer-branding__icons-column span {
    font-size: 12px;
    line-height: 20px;
  }
}
.employer-branding__icons-column__links {
  position: absolute;
  left: 0;
  right: 0;
  display: none;
  top: 100%;
  background-color: #1C2024;
  flex-direction: column;
  padding: 20px;
  z-index: 3;
}
.employer-branding__icons-column__links a {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 6px;
}
.employer-branding__icons-column__links a:hover {
  text-decoration: none;
}

.rentauthor {
  margin-bottom: 80px;
}
@media only screen and (max-width: 500px) {
  .rentauthor {
    margin-bottom: 20px;
  }
}
.rentauthor__title {
  font-size: 24px;
  line-height: 28px;
  text-decoration: underline;
  margin-bottom: 40px;
}
@media only screen and (max-width: 500px) {
  .rentauthor__title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 40px;
  }
}
.rentauthor__intro {
  margin-top: -20px;
  margin-bottom: 60px;
  max-width: 620px;
}
@media only screen and (max-width: 500px) {
  .rentauthor__intro {
    margin-bottom: 40px;
  }
  .rentauthor__intro p {
    font-size: 14px;
    line-height: 24px;
  }
}
.rentauthor__intro a {
  color: #1C2024;
  transition: opacity 0.2s ease-in-out;
}
.rentauthor__intro a:hover {
  opacity: 0.6;
}
@supports (display: grid) {
  .rentauthor__grid {
    display: grid;
    grid-template-columns: 35.294% 63.236%;
    grid-column-gap: 20px;
    align-items: center;
  }
  @media only screen and (max-width: 800px) {
    .rentauthor__grid {
      grid-template-columns: 100%;
      grid-row-gap: 20px;
    }
  }
}
.rentauthor__logotype {
  text-align: center;
}
.rentauthor__logotype a {
  display: inline-block;
}
.rentauthor__logotype img {
  max-width: 230px;
  width: 100%;
  height: auto;
}
.rentauthor__content p {
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 500px) {
  .rentauthor__content p {
    font-size: 18px;
    line-height: 27px;
  }
}
.rentauthor__content p a {
  color: #1C2024;
  transition: opacity 0.2s ease-in-out;
}
.rentauthor__content p a:hover {
  opacity: 0.6;
}
.rentauthor__links {
  display: inline-block;
  width: 100%;
}
.rentauthor__links a {
  background-color: #1C2024;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  padding: 10px 20px;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 500px) {
  .rentauthor__links a {
    font-size: 14px;
    line-height: 22px;
  }
}
.rentauthor__links a:hover {
  text-decoration: underline;
}

.sales-materials__section-title {
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 60px;
}
@media only screen and (max-width: 500px) {
  .sales-materials__section-title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 40px;
  }
}
.sales-materials__section-title a {
  color: #1C2024;
  transition: opacity 0.2s ease-in-out;
}
.sales-materials__section-title a:hover {
  opacity: 0.6;
}
.sales-materials__columns {
  padding-bottom: 80px;
  margin-bottom: 80px;
  border-bottom: 1px solid #EBEBEB;
}
@media only screen and (max-width: 500px) {
  .sales-materials__columns {
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
}
@supports (display: grid) {
  .sales-materials__columns {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 40px;
    width: 100%;
  }
  @media only screen and (max-width: 500px) {
    .sales-materials__columns {
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 40px;
    }
  }
}
.sales-materials__column {
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
}
.sales-materials__column img {
  max-width: 128px;
  width: 100%;
  border-radius: 64px;
  margin-bottom: 24px;
}
@media only screen and (max-width: 500px) {
  .sales-materials__column img {
    max-width: 100%;
    margin-bottom: 10px;
  }
}
.sales-materials__column h2 {
  font-size: 18px;
  line-height: 24px;
  color: #1C2024;
}
@media only screen and (max-width: 500px) {
  .sales-materials__column h2 {
    font-size: 14px;
    line-height: 24px;
  }
}
.sales-materials__column:hover {
  opacity: 0.6;
}

.article-section {
  margin-bottom: 80px;
}
@media only screen and (max-width: 500px) {
  .article-section {
    margin-bottom: 40px;
  }
}
.article-section__title {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 70px;
}
@media only screen and (max-width: 500px) {
  .article-section__title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 40px;
  }
}
.article-section .grid {
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
  display: grid;
}
@supports (display: grid) {
  .article-section .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 120px;
    grid-row-gap: 120px;
  }
  .article-section .grid.col3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .article-section .grid.col4 {
    grid-template-columns: repeat(4, 1fr);
  }
  @media only screen and (max-width: 800px) {
    .article-section .grid {
      grid-template-columns: 100%;
      grid-column-gap: 20px;
      grid-row-gap: 40px;
    }
  }
  @media only screen and (max-width: 800px) {
    .article-section .grid.m-col1 {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  @media only screen and (max-width: 800px) {
    .article-section .grid.m-col2 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media only screen and (max-width: 800px) {
    .article-section .grid.m-col3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media only screen and (max-width: 800px) {
    .article-section .grid.m-col4 {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  @media only screen and (max-width: 500px) {
    .article-section .grid.s-col1 {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  @media only screen and (max-width: 500px) {
    .article-section .grid.s-col2 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media only screen and (max-width: 500px) {
    .article-section .grid.s-col3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media only screen and (max-width: 500px) {
    .article-section .grid.s-col4 {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
.article-section__article {
  color: inherit;
  text-decoration: none;
}
.article-section__article img {
  width: 100%;
  height: auto;
  transition: transform 1s ease-in-out;
}
.article-section__article h2 {
  font-size: 21px;
  line-height: 24px;
  color: #1C2024;
  text-decoration: underline;
  margin-bottom: 10px;
  transition: opacity 0.2s ease-in-out;
}
@media only screen and (max-width: 500px) {
  .article-section__article h2 {
    font-size: 18px;
    line-height: 24px;
  }
}
.article-section__article p {
  font-size: 18px;
  line-height: 28px;
  transition: opacity 0.2s ease-in-out;
}
@media only screen and (max-width: 500px) {
  .article-section__article p {
    font-size: 14px;
    line-height: 24px;
  }
}
.article-section__article:hover h2 {
  opacity: 0.6;
}
.article-section__article:hover img {
  transform: scale(1.05);
}
.article-section__article-image {
  overflow: hidden;
  margin-bottom: 30px;
}

.footer {
  background-color: #1C2024;
  color: #fff;
  padding: 40px 0;
}
.footer .flex {
  display: flex;
}
.footer .align-center {
  align-items: center;
}
.footer .flex-row {
  flex-direction: row;
  flex-wrap: wrap;
}
@media only screen and (max-width: 500px) {
  .footer .flex-row {
    flex-direction: column;
  }
}
.footer .flex-col {
  flex-direction: column;
  flex-wrap: wrap;
}
.footer .w-1-2 {
  width: 50%;
}
@media only screen and (max-width: 800px) {
  .footer .w-1-2 {
    width: 100%;
  }
}
.footer .w-full {
  width: 100%;
}
.footer .w-4-5 {
  width: 80%;
}
@media only screen and (max-width: 800px) {
  .footer .w-4-5 {
    width: 100%;
  }
}
.footer .w-8-12 {
  width: 66.666667%;
}
@media only screen and (max-width: 800px) {
  .footer .w-8-12 {
    width: 100%;
  }
}
.footer .bold {
  font-weight: bold;
}
.footer .text-17 {
  font-size: 17px;
  line-height: 27px;
}
.footer .text-white {
  color: #fff;
}
.footer .text-inherit {
  color: inherit;
}
.footer .hover-underline {
  text-decoration: none;
}
.footer .hover-underline:hover {
  text-decoration: underline;
}
.footer .hover-no-underline:hover {
  text-decoration: none;
}
.footer .ml-auto {
  margin-left: auto;
}
@media only screen and (max-width: 800px) {
  .footer .ml-auto {
    margin-left: 0;
  }
}
.footer .mb-20 {
  margin-bottom: 20px;
}
@media only screen and (max-width: 800px) {
  .footer .m-mb-40 {
    margin-bottom: 40px;
  }
}
.footer .mb-40 {
  margin-bottom: 40px;
}
.footer .mr-20 {
  margin-right: 20px;
}
.footer .-mx-20 {
  margin: 0 -40px;
}
.footer .pr-20 {
  padding-right: 20px;
}
.footer .pr-40 {
  padding-right: 40px;
}
@media only screen and (max-width: 800px) {
  .footer .pr-40 {
    padding-right: 0;
  }
}
.footer .pb-20 {
  padding-bottom: 20px;
}
.footer .px-20 {
  padding-left: 40px;
  padding-right: 40px;
}
.footer .border-top {
  border-top: 1px solid #fff;
  padding-top: 40px;
}
.footer .footer-social {
  display: flex;
}
.footer .footer-social .footer-social-item {
  margin-left: 20px;
}
.footer .footer-social .footer-social-item:first-child {
  margin-left: 0;
}
.footer .footer-menu .menu-item {
  margin-right: 20px;
}
@media only screen and (max-width: 800px) {
  .footer .footer-menu {
    flex-direction: column;
  }
  .footer .footer-menu .menu-item {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.footer .footer-excerpt a {
  color: inherit;
}

.iframe {
  margin-bottom: 80px;
}
@media only screen and (max-width: 500px) {
  .iframe {
    margin-bottom: 40px;
  }
}
.iframe h2 {
  font-size: 24px;
  line-height: 28px;
  text-decoration: underline;
  margin-bottom: 40px;
}
.iframe iframe {
  width: 100%;
  height: 500px; /* May be overridden by js event from iframe source */
}

.notfound {
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
  min-height: 720px;
  display: grid;
  align-items: center;
}
@media only screen and (max-width: 500px) {
  .notfound {
    min-height: auto;
    padding: 20px 0 40px 0;
  }
}
.notfound h1 {
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 20px;
}
.notfound h2 {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 10px;
}
.notfound ul {
  margin-bottom: 30px;
  list-style: none;
}
.notfound li {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 5px;
  position: relative;
  padding-left: 20px;
}
.notfound li:before {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  top: 11px;
  left: 0;
  background-color: #205F58;
  border-radius: 12px;
}
.password-protected {
  max-width: 700px;
  margin: 0 auto;
  padding: 80px 0;
}
.password-protected input[type=submit] {
  border: none;
  appearance: none;
  width: 100%;
  font-size: 16px;
  line-height: 26px;
  padding: 12px 0;
  text-align: center;
  background-color: #FFD600;
  color: black;
  outline: none;
  border-radius: 8px;
  transition: opacity 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  margin: 10px 0;
}
.password-protected input[type=submit] em {
  font-style: normal;
  transition: color 0.2s ease-in-out;
  line-height: 26px;
}
.password-protected input[type=submit] svg {
  /* this is the check icon */
  width: 64px;
  height: 64px;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  /* move the icon on the right - outside the button */
  transform: translateX(50%) translateY(-50%);
}
.password-protected input[type=submit].animate {
  pointer-events: none;
}
.password-protected input[type=submit].animate em {
  color: transparent;
  transform: translateX(-100%);
}
.password-protected input[type=submit].animate svg {
  transform: translateX(-50%) translateY(-50%);
  stroke-dashoffset: 0;
}
.password-protected input[type=submit]:hover {
  text-decoration: underline;
}
.password-protected input[type=password] {
  width: 100%;
  border: none;
  background-color: #F4F4F4;
  height: 44px;
  padding: 0 40px 0 20px;
  outline: none;
  border-bottom: 2px solid rgba(28, 32, 36, 0.2);
  transition: border-bottom 0.2s ease-in-out;
  margin-top: 10px;
}
.password-protected input[type=password]:focus {
  border-bottom: 2px solid rgb(28, 32, 36);
}
.password-protected p {
  margin-bottom: 5px;
}

.halloween {
  background-image: url("../img/blood.png");
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
  height: 242px;
  max-width: 1264px;
  width: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
@media only screen and (max-width: 500px) {
  .halloween {
    height: 140px;
  }
}

.grecaptcha-badge {
  display: none;
}

.rulemailer-email-check {
  display: none !important;
}

.rule-confirmation-box {
  color: white;
  margin-bottom: 40px;
}
.rule-confirmation-box input[type=checkbox] {
  display: inline-block;
  width: auto;
  margin-bottom: 0;
  cursor: pointer;
}
.rule-confirmation-box a {
  color: inherit;
}

.rulemailer-subscriber-form button[type=submit] {
  background: transparent;
  text-decoration: underline;
  outline: none;
  border: 0;
}

.press-images:hover h2 {
  opacity: 0.6;
}
.press-images:hover img {
  transform: scale(1.05);
}
.press-images h2 {
  font-size: 21px;
  line-height: 24px;
  color: #1C2024;
  margin-bottom: 10px;
  transition: opacity 0.2s ease-in-out;
}
@media only screen and (max-width: 500px) {
  .press-images h2 {
    font-size: 18px;
    line-height: 24px;
  }
}
.press-images p {
  font-size: 18px;
  line-height: 28px;
  transition: opacity 0.2s ease-in-out;
}
@media only screen and (max-width: 500px) {
  .press-images p {
    font-size: 14px;
    line-height: 24px;
  }
}
.press-images__image {
  position: relative;
  display: block;
  margin-bottom: 30px;
  overflow: hidden;
}
.press-images__image img {
  width: 100%;
  height: auto;
  transition: transform 1s ease-in-out;
}
.press-images__image-overlay {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  gap: 6px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 6px;
}
.press-images__image-overlay p {
  color: #fff;
}

body.compensate-for-scrollbar {
  overflow: hidden;
}

.fancybox-active {
  height: auto;
}

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden;
}

.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transform: translateZ(0);
  width: 100%;
  z-index: 99992;
}

.fancybox-container * {
  box-sizing: border-box;
}

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.fancybox-bg {
  background: rgb(30, 30, 30);
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
}

.fancybox-is-open .fancybox-bg {
  opacity: 0.9;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity 0.25s ease, visibility 0s ease 0.25s;
  visibility: hidden;
  z-index: 99997;
  display: flex;
  flex-direction: row;
}

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity 0.25s ease 0s, visibility 0s ease 0s;
  visibility: visible;
}

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fancybox-toolbar {
  right: 50%;
  bottom: 0;
  transform: translateX(50%);
}

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  transform: translateZ(0);
  z-index: 99994;
}

.fancybox-is-open .fancybox-stage {
  overflow: hidden;
}

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  /* Using without prefix would break IE11 */
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: transform, opacity;
  white-space: normal;
  width: 100%;
  z-index: 99994;
}

.fancybox-slide::before {
  content: "";
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block;
}

.fancybox-slide--image {
  overflow: hidden;
  padding: 44px 0;
}

.fancybox-slide--image::before {
  display: none;
}

.fancybox-slide--html {
  padding: 6px;
}

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle;
}

.fancybox-slide--image .fancybox-content {
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: transform, opacity;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995;
}

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in;
}

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab;
}

.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.fancybox-container [data-selectable=true] {
  cursor: text;
}

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.fancybox-spaceball {
  z-index: 1;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%;
}

.fancybox-slide--video .fancybox-content {
  background: #000;
}

.fancybox-slide--map .fancybox-content {
  background: #e5e3df;
}

.fancybox-slide--iframe .fancybox-content {
  background: #fff;
}

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

/* Fix iOS */
.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0;
}

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%;
}

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

/* Buttons */
.fancybox-button {
  background: rgb(255, 255, 255);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  margin: 0;
  position: relative;
  transition: color 0.2s;
  vertical-align: top;
  visibility: inherit;
}

.fancybox-button--close {
  width: 46px;
  height: 46px;
  padding: 10px;
}

.fancybox-button--download {
  padding: 10px;
}
.fancybox-button--download .fancybox-button__icon {
  width: 24px;
  height: 24px;
}

.fancybox-button__text {
  white-space: nowrap;
}

.fancybox-button--download {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #000;
}

.fancybox-button:hover {
  color: #9f9f9f;
}

.fancybox-button:focus {
  outline: none;
}

.fancybox-button.fancybox-focus {
  outline: 1px dotted;
}

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none;
}

/* Fix IE11 */
.fancybox-button div {
  height: 100%;
}

.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%;
}

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0;
}

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
  display: none;
}

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
  display: none;
}

.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -ms-transform-origin: 0;
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 99998;
}

/* Close button on the top right corner of html content */
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: 0.8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401;
}

.fancybox-close-small:hover {
  color: #fff;
  opacity: 1;
}

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0;
}

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden;
}

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none;
}

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 36px;
  width: 36px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  border-radius: 100%;
}

.fancybox-navigation .fancybox-button div {
  padding: 7px;
}

.fancybox-navigation .fancybox-button--arrow_left {
  left: 10px;
}

.fancybox-navigation .fancybox-button--arrow_right {
  right: 10px;
}

/* Caption */
.fancybox-caption {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, rgba(0, 0, 0, 0) 100%);
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 75px 44px 25px 44px;
  pointer-events: none;
  right: 0;
  text-align: center;
  z-index: 99996;
}

.fancybox-caption--separate {
  margin-top: -50px;
}

.fancybox-caption__body {
  max-height: 50vh;
  overflow: auto;
  pointer-events: all;
}

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none;
}

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline;
}

/* Loading indicator */
.fancybox-loading {
  animation: fancybox-rotate 1s linear infinite;
  background: transparent;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999;
}

@keyframes fancybox-rotate {
  100% {
    transform: rotate(360deg);
  }
}
/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1;
}

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(1.5, 1.5, 1.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg);
}

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
}

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
  transform: translate3d(0, 0, 0) scale(1);
}

/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px;
  }
  .fancybox-slide--image {
    padding: 6px 0;
  }
  .fancybox-close-small {
    right: -6px;
  }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px;
  }
  .fancybox-caption {
    padding-left: 12px;
    padding-right: 12px;
  }
  @supports (padding: max(0px)) {
    .fancybox-caption {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}
/* Share */
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center;
}

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0;
}

.fancybox-share p {
  margin: 0;
  padding: 0;
}

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff;
}

.fancybox-share__button:hover {
  text-decoration: none;
}

.fancybox-share__button--fb {
  background: #3b5998;
}

.fancybox-share__button--fb:hover {
  background: #344e86;
}

.fancybox-share__button--pt {
  background: #bd081d;
}

.fancybox-share__button--pt:hover {
  background: #aa0719;
}

.fancybox-share__button--tw {
  background: #1da1f2;
}

.fancybox-share__button--tw:hover {
  background: #0d95e8;
}

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px;
}

.fancybox-share__button svg path {
  fill: #fff;
}

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%;
}

/* Thumbs */
.fancybox-thumbs {
  background: #ddd;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  top: 0;
  width: 212px;
  z-index: 99995;
}

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.fancybox-show-thumbs .fancybox-thumbs {
  display: block;
}

.fancybox-show-thumbs .fancybox-inner {
  right: 212px;
}

.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px;
}

.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px;
}

.fancybox-thumbs__list a::before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991;
}

.fancybox-thumbs__list a:focus::before {
  opacity: 0.5;
}

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
  opacity: 1;
}

/* Styling for Small-Screen Devices */
@media all and (max-width: 576px) {
  .fancybox-thumbs {
    width: 110px;
  }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px;
  }
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px);
  }
}
.menu-bar.login-hidden .expanding-search-button {
  right: 129px;
}
@media only screen and (max-width: 500px) {
  .menu-bar.login-hidden .expanding-search-button {
    right: 84px;
  }
}

.expanding-search-button {
  width: 56px;
  height: 56px;
  position: absolute;
  right: 272px;
  top: 50%;
  transform: translateX(50%) translateY(-50%);
  appearance: none;
  border: none;
  background: none;
}
.expanding-search-button svg {
  width: 28px;
  height: 28px;
  color: #FFF;
}
.menu-bar--not-frontpage .expanding-search-button svg, .menu-bar--minimized .expanding-search-button svg {
  color: #000;
}
@media only screen and (max-width: 500px) {
  .expanding-search-button {
    right: 100px;
    width: 19px;
    height: 19px;
  }
  .expanding-search-button svg {
    width: 19px;
    height: 19px;
  }
}
.expanding-search-button--cart-visible {
  right: 258px;
}
@media only screen and (max-width: 500px) {
  .expanding-search-button--cart-visible {
    right: 144px;
  }
}

.expanding-search {
  width: 100%;
  max-width: 360px;
  position: absolute;
  right: 40px;
  top: 101px;
  display: none;
}
.expanding-search.show {
  display: block;
}
@media only screen and (max-width: 500px) {
  .expanding-search {
    top: 70px;
    width: 100%;
    max-width: 100%;
    right: 0;
  }
}
.expanding-search__label {
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.expanding-search__label svg {
  width: 24px;
  height: 24px;
}
.expanding-search__input {
  height: 56px;
  width: 100%;
  padding: 0 56px 0 16px;
  border: 1px solid #1c2024;
  outline: none;
}
.expanding-search__previous-searches {
  padding: 10px;
}
.expanding-search__previous-searches > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.expanding-search__previous-searches h4 {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  text-transform: uppercase;
}
.expanding-search__clear-button {
  background: none;
  border: none;
  color: #000;
  padding: 5px;
  cursor: pointer;
  font-size: 12px;
  text-align: left;
  text-transform: uppercase;
}
.expanding-search__clear-button:hover {
  background-color: #f8f8f8;
}
.expanding-search__previous-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.expanding-search__previous-item {
  padding: 10px 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.expanding-search__previous-item:before {
  content: "";
  background-image: url("data:image/svg+xml;base64,ICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICA8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEyIiBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgMjQgMjQiPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMTMuNSw4SDEyVjEzTDE2LjI4LDE1LjU0TDE3LDE0LjMzTDEzLjUsMTIuMjVWOE0xMywzQTksOSAwIDAsMCA0LDEySDFMNC45NiwxNi4wM0w5LDEySDZBNyw3IDAgMCwxIDEzLDVBNyw3IDAgMCwxIDIwLDEyQTcsNyAwIDAsMSAxMywxOUMxMS4wNywxOSA5LjMyLDE4LjIxIDguMDYsMTYuOTRMNi42NCwxOC4zNkM4LjI3LDIwIDEwLjUsMjEgMTMsMjFBOSw5IDAgMCwwIDIyLDEyQTksOSAwIDAsMCAxMywzIiAvPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgIDwvc3ZnPg==");
  display: inline-block;
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.expanding-search__previous-item:hover {
  background-color: #f0f0f0;
}
.expanding-search__suggestion-grouped {
  padding: 10px;
}
.expanding-search__suggestion-grouped h4 {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 24px;
}
.expanding-search__suggestion-grouped .expanding-search__suggestion {
  padding: 10px 0;
}
.expanding-search__suggestion-grouped .expanding-search__suggestion.article-icon:before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHRpdGxlPmZpbGUtZG9jdW1lbnQtb3V0bGluZTwvdGl0bGU+PHBhdGggZD0iTTYsMkEyLDIgMCAwLDAgNCw0VjIwQTIsMiAwIDAsMCA2LDIySDE4QTIsMiAwIDAsMCAyMCwyMFY4TDE0LDJINk02LDRIMTNWOUgxOFYyMEg2VjRNOCwxMlYxNEgxNlYxMkg4TTgsMTZWMThIMTNWMTZIOFoiIC8+PC9zdmc+");
}
.expanding-search__suggestion-grouped .expanding-search__suggestion.book-icon:before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHRpdGxlPmJvb2stb3Blbi12YXJpYW50LW91dGxpbmU8L3RpdGxlPjxwYXRoIGQ9Ik0xMiAyMS41QzEwLjY1IDIwLjY1IDguMiAyMCA2LjUgMjBDNC44NSAyMCAzLjE1IDIwLjMgMS43NSAyMS4wNUMxLjY1IDIxLjEgMS42IDIxLjEgMS41IDIxLjFDMS4yNSAyMS4xIDEgMjAuODUgMSAyMC42VjZDMS42IDUuNTUgMi4yNSA1LjI1IDMgNUM0LjExIDQuNjUgNS4zMyA0LjUgNi41IDQuNUM4LjQ1IDQuNSAxMC41NSA0LjkgMTIgNkMxMy40NSA0LjkgMTUuNTUgNC41IDE3LjUgNC41QzE4LjY3IDQuNSAxOS44OSA0LjY1IDIxIDVDMjEuNzUgNS4yNSAyMi40IDUuNTUgMjMgNlYyMC42QzIzIDIwLjg1IDIyLjc1IDIxLjEgMjIuNSAyMS4xQzIyLjQgMjEuMSAyMi4zNSAyMS4xIDIyLjI1IDIxLjA1QzIwLjg1IDIwLjMgMTkuMTUgMjAgMTcuNSAyMEMxNS44IDIwIDEzLjM1IDIwLjY1IDEyIDIxLjVNMTEgNy41QzkuNjQgNi45IDcuODQgNi41IDYuNSA2LjVDNS4zIDYuNSA0LjEgNi42NSAzIDdWMTguNUM0LjEgMTguMTUgNS4zIDE4IDYuNSAxOEM3Ljg0IDE4IDkuNjQgMTguNCAxMSAxOVY3LjVNMTMgMTlDMTQuMzYgMTguNCAxNi4xNiAxOCAxNy41IDE4QzE4LjcgMTggMTkuOSAxOC4xNSAyMSAxOC41VjdDMTkuOSA2LjY1IDE4LjcgNi41IDE3LjUgNi41QzE2LjE2IDYuNSAxNC4zNiA2LjkgMTMgNy41VjE5TTE0IDE2LjM1QzE0Ljk2IDE2IDE2LjEyIDE1LjgzIDE3LjUgMTUuODNDMTguNTQgMTUuODMgMTkuMzggMTUuOTEgMjAgMTYuMDdWMTQuNTdDMTkuMTMgMTQuNDEgMTguMjkgMTQuMzMgMTcuNSAxNC4zM0MxNi4xNiAxNC4zMyAxNSAxNC41IDE0IDE0Ljc2VjE2LjM1TTE0IDEzLjY5QzE0Ljk2IDEzLjM0IDE2LjEyIDEzLjE2IDE3LjUgMTMuMTZDMTguNTQgMTMuMTYgMTkuMzggMTMuMjQgMjAgMTMuNFYxMS45QzE5LjEzIDExLjc0IDE4LjI5IDExLjY3IDE3LjUgMTEuNjdDMTYuMjIgMTEuNjcgMTUuMDUgMTEuODIgMTQgMTIuMTJWMTMuNjlNMTQgMTFDMTQuOTYgMTAuNjcgMTYuMTIgMTAuNSAxNy41IDEwLjVDMTguNDEgMTAuNSAxOS4yNiAxMC41OSAyMCAxMC43OFY5LjIzQzE5LjEzIDkuMDggMTguMjkgOSAxNy41IDlDMTYuMTggOSAxNSA5LjE1IDE0IDkuNDZWMTFaIiAvPjwvc3ZnPg==");
}
.expanding-search__suggestions {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #1c2024;
  border-top: none;
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 300px;
  overflow-y: auto;
}
.expanding-search__suggestion {
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  scroll-margin-bottom: 37px;
}
.expanding-search__suggestion:before {
  content: "";
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHRpdGxlPm1hZ25pZnk8L3RpdGxlPjxwYXRoIGQ9Ik05LjUsM0E2LjUsNi41IDAgMCwxIDE2LDkuNUMxNiwxMS4xMSAxNS40MSwxMi41OSAxNC40NCwxMy43M0wxNC43MSwxNEgxNS41TDIwLjUsMTlMMTksMjAuNUwxNCwxNS41VjE0LjcxTDEzLjczLDE0LjQ0QzEyLjU5LDE1LjQxIDExLjExLDE2IDkuNSwxNkE2LjUsNi41IDAgMCwxIDMsOS41QTYuNSw2LjUgMCAwLDEgOS41LDNNOS41LDVDNyw1IDUsNyA1LDkuNUM1LDEyIDcsMTQgOS41LDE0QzEyLDE0IDE0LDEyIDE0LDkuNUMxNCw3IDEyLDUgOS41LDVaIiAvPjwvc3ZnPg==");
  display: inline-block;
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.expanding-search__suggestion:hover {
  background-color: #f0f0f0;
}
.expanding-search__suggestion.highlight {
  background-color: #d3d3d3;
  font-weight: bold;
}
.expanding-search__suggestion .highlighted {
  font-weight: bold;
}
.expanding-search__button {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
}
.expanding-search__button button {
  font-size: 12px;
  text-decoration: none;
  color: #000;
  padding: 10px;
  background-color: #fff;
  text-align: center;
  display: block;
  border-top: 1px solid #1c2024;
  border-left: none;
  border-bottom: none;
  border-right: none;
  width: 100%;
  outline: none;
  font-weight: bold;
}
.expanding-search__button button:hover {
  text-decoration: underline;
}

.all-search {
  padding: 24px;
  background-color: #e5e4df;
  box-sizing: border-box;
  max-width: 100%;
}
.all-search section {
  margin-bottom: 64px;
}
.all-search__section-title {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 24px;
  display: inline-block;
  text-decoration: none;
  color: #1c2024;
  position: relative;
}
.all-search__section-title:before {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #1c2024;
}
.all-search .all-search__grid > * {
  min-width: 0;
}
.all-search__grid {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 1.5rem;
  align-items: end;
}
@media only screen and (max-width: 1024px) {
  .all-search__grid {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
  }
}
@media only screen and (max-width: 500px) {
  .all-search__grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 320px) {
  .all-search__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
.all-search__grid--three-columns {
  align-items: start;
  grid-template-columns: repeat(3, 1fr);
}
@media only screen and (max-width: 800px) {
  .all-search__grid--three-columns {
    grid-template-columns: repeat(1, 1fr);
  }
}
.all-search__grid--columns {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}
.all-search__page {
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #1c2024;
  color: inherit;
  text-decoration: none;
}
.all-search__page:hover h2 {
  text-decoration: underline;
}
.all-search__page h2 {
  font-size: 22px;
  line-height: 28px;
  color: #202020;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
}
@media only screen and (max-width: 800px) {
  .all-search__page h2 {
    font-size: 24px;
    line-height: 32px;
  }
}
.all-search__book__link {
  color: inherit;
  text-decoration: none;
}
.all-search__book__link:hover .all-search__book__title {
  opacity: 0.6;
}
.all-search__book__cover {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.all-search__book__title {
  text-decoration: underline;
  font-size: 16px;
  line-height: 24px;
  transition: opacity 0.2s ease-in-out;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out;
}
.all-search__book__author-wrap {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.all-search__book__author {
  font-size: 16px;
  line-height: 24px;
  color: #a9a9a9;
  transition: opacity 0.2s ease-in-out;
  white-space: nowrap;
}
.all-search__book__author:hover {
  opacity: 0.6;
}
.all-search__book__author--no-link:hover {
  opacity: 1;
}
.all-search__author__link {
  color: inherit;
  text-decoration: none;
  position: relative;
}
.all-search__author__link:hover .all-search__author__title {
  opacity: 0.6;
}
.all-search__author__cover {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.all-search__author__initials-cover-wrap {
  margin-bottom: 20px;
  position: relative;
  height: 285px;
  overflow: hidden;
}
@media only screen and (max-width: 500px) {
  .all-search__author__initials-cover-wrap {
    height: 145px;
  }
}
@media only screen and (max-width: 320px) {
  .all-search__author__initials-cover-wrap {
    height: 200px;
  }
}
.all-search__author__initials {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 60px;
  line-height: 60px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
}
.all-search__author__title {
  text-decoration: underline;
  font-size: 16px;
  line-height: 24px;
  transition: opacity 0.2s ease-in-out;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out;
}
.all-search__see-more {
  text-align: right;
  margin-top: 24px;
}
.all-search__see-more a {
  font-size: 16px;
  line-height: 24px;
  color: #1c2024;
  text-decoration: underline;
  display: inline-block;
}
.all-search .news__item {
  margin-bottom: 0;
}

body {
  font-family: "ABC Monument Grotesk";
  font-weight: 400;
  font-style: normal;
  color: #1C2024;
}
body.api-work {
  padding-top: 140px;
}
@media only screen and (max-width: 500px) {
  body.api-work {
    padding-top: 90px;
  }
}
body.not-frontpage.page-template-page-search-results, body.not-frontpage.all-search-results {
  padding-top: 0;
}
body.not-frontpage {
  padding-top: 140px;
}
@media only screen and (max-width: 500px) {
  body.not-frontpage {
    padding-top: 90px;
  }
}
body.logged-in .menu-bar {
  margin-top: 32px;
}
body.logged-in .site-menu {
  margin-top: 32px;
}

.content {
  background-color: #f4f4f4;
  max-width: 1360px;
  width: 100%;
  margin: 0 auto 40px;
  padding: 40px;
}

.wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 40px;
  position: relative;
  z-index: 2;
}
@media only screen and (max-width: 800px) {
  .wrapper {
    padding: 0 20px;
  }
}

.img-with-cred {
  position: relative;
}
.img-with-cred img {
  max-width: 100%;
}
.img-with-cred .photo-cred {
  position: absolute;
  top: 0px;
  left: 0px;
  color: #fff;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 5px 10px;
}

.page-title {
  font-size: 60px;
  line-height: 42px;
  font-family: "ABC Monument Grotesk";
  font-weight: 700;
  font-style: normal;
  margin-bottom: 40px;
}
@media only screen and (max-width: 500px) {
  .page-title {
    font-size: 34px;
    line-height: 42px;
  }
}

.title-link {
  color: #202020;
  text-decoration: none;
  position: relative;
}
.title-link:hover:after {
  display: none;
}
.title-link:hover:before {
  width: 100%;
}
.title-link:after {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #202020;
}
.title-link:before {
  content: "";
  position: absolute;
  height: 3px;
  width: 0;
  bottom: 0;
  left: 0;
  background-color: #202020;
  transition: width 0.2s cubic-bezier(0.405, 0.07, 0.575, 0.995) 0.1s;
}

.footer-bazar {
  max-width: 1360px;
  width: 100%;
  padding: 40px;
  margin: 0 auto;
  background-color: #41566e;
}
@media only screen and (max-width: 500px) {
  .footer-bazar {
    padding: 40px 20px 8px 20px;
  }
}
.footer-bazar__container {
  display: flex;
  flex-direction: row;
  margin: 0 -20px;
}
@media only screen and (max-width: 800px) {
  .footer-bazar__container {
    flex-direction: column;
  }
}
.footer-bazar__col {
  width: 33.333%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 800px) {
  .footer-bazar__col {
    width: 100%;
    margin-bottom: 32px;
    text-align: center;
  }
}
@media only screen and (max-width: 500px) {
  .footer-bazar__col {
    text-align: left;
  }
}
.footer-bazar__social {
  display: flex;
  flex-direction: row;
  align-self: center;
}
@media only screen and (max-width: 500px) {
  .footer-bazar__social {
    align-self: flex-start;
    margin: 0 -10px;
  }
}
.footer-bazar__social-icon {
  width: 46px;
  height: 46px;
  margin: 0 10px;
  background-size: 46px 46px;
}
.footer-bazar__social-icon--fb {
  background-image: url("../img/facebook-beige.png");
}
.footer-bazar__social-icon--instagram {
  background-image: url("../img/instagram-beige.png");
}
.footer-bazar__social-icon--linkedin {
  background-image: url("../img/linkedin-beige.png");
}
.footer-bazar h2 {
  color: #f5efe8;
  font-size: 24px;
  line-height: 32px;
  font-family: "Gilroy";
  text-decoration: underline;
  margin-bottom: 12px;
  font-weight: 600;
  letter-spacing: 0.05rem;
}
.footer-bazar h2.center {
  text-align: center;
}
@media only screen and (max-width: 500px) {
  .footer-bazar h2.center {
    text-align: left;
  }
}
.footer-bazar p,
.footer-bazar a {
  font-size: 16px;
  line-height: 26px;
  color: #f5efe8;
}

body.login-modal-visible {
  overflow: hidden;
}

[v-cloak] {
  display: none;
}

.page-template-page-books-vue #search-top,
.page-template-page-authors-vue #search-top {
  margin-top: 20px;
}